import { useSelector } from "react-redux";
import CardCourse from "../../global/Cards/CardCourse/CardCourse";
import SkeletonCardMyLearning from "../../skeletons/SkeletonCardMyLearning";
import { useState } from "react";
import { Link } from "react-router-dom";

const SectionCourses = () => {
    const [limit, setLimit] = useState(12);
    const { courses, loadingBrowseCourse } = useSelector((state) => ({ ...state.browseCourse }));

    return (
        <section>
            <div className="row">
                {loadingBrowseCourse && [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                    return (
                        <div className="col-sm-6 col-md-4 col-xl-3 p-2" key={item}>
                            <SkeletonCardMyLearning />
                        </div>
                    );
                })}

                {!loadingBrowseCourse && (courses?.length < 1 || courses === undefined) ? (
                    <p className="text-center my-5">Course tidak ditemukan</p>
                ) : null}

                {!loadingBrowseCourse && courses?.length > 0 && courses.map((course, index) => {
                    return (
                        <div className="col-sm-6 col-md-4 col-xl-3 p-2" key={index}>
                            <Link className="link" to={`/learn/courses/detail/${course.slug}`}>
                                <CardCourse
                                    thumbnail={course.cover}
                                    author={course.author}
                                    title={course.title}
                                    level={course.level}
                                    totalStudents={course.total_student}
                                    totalModules={course.total_module}
                                    totalTimes={course.total_time}
                                    totalRating={course.total_rating}
                                    totalFeedback={course.total_feedback}
                                    normalBuyPrice={course.buy?.normal_price || course.normal_price}
                                    retailBuyPrice={course.buy?.retail_price || course.retail_price}
                                    normalRentPrice={course.rent?.normal_price}
                                    retailRentPrice={course.rent?.retail_price}
                                />
                            </Link>
                        </div>
                    );
                }).slice(0, limit)}
            </div>
            {!loadingBrowseCourse && courses?.length > limit && (
                <div className="text-center my-3">
                    <button onClick={() => setLimit(limit + 8)} type="button" className="btn btn-outline-secondary">Show more</button>
                </div>
            )}
        </section>
    );
};

export default SectionCourses;
