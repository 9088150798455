import { faArrowRotateRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import CardDiscussion from '../../../learn/discussion/CardDiscussion'
import SkeletonCardDiscussion from "../../../skeletons/SkeletonCardDiscussion/SkeletonCardDiscussion";
import { useSelector } from 'react-redux'

const SectionLatestDiscussion = () => {
    const { threads, loadingMentor } = useSelector(state => state.mentor);

    const [limit, setLimit] = useState(5)
    const [searchValue, setSearchValue] = useState("")
    return (
        <section>
            <div className="row align-items-center mb-3">
                <div className="col-12 col-lg-auto">
                    <h4 className="section-title">Diskusi Terbaru</h4>
                </div>
                <div className="col-12 col-lg-auto ms-lg-auto">
                    <div className="input-group mb-3 shadow-sm">
                        <input onChange={(e) => setSearchValue(e.target.value)} type="text" className="form-control border-0" placeholder="Keyword" />
                        <span className="input-group-text bg-primary text-white" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                    </div>
                </div>
            </div>

            {
                loadingMentor && [...Array(limit)].map((item, index) => {
                    return (
                        <SkeletonCardDiscussion key={index} />
                    )
                })
            }

            {
                !loadingMentor && threads !== null && threads.filter((value) => {
                    return value.subject.toLowerCase().includes(searchValue.toLowerCase());
                }).length > 0
                    ? threads.filter(value => {
                        return value.subject.toLowerCase().includes(searchValue.toLowerCase());
                    }).map((thread, index) => {
                        return (
                            <Link to={`/learn/discussions/thread/${thread.slug}`} key={index} className="link">
                                <CardDiscussion
                                    avatar={thread.avatar}
                                    name={thread.name}
                                    rank={thread?.rank?.rank_name}
                                    job={thread.jobs}
                                    role={thread.role_name}
                                    subject={thread.subject}
                                    mark={thread.mark}
                                    date={thread.date}
                                    courseTitle={thread.course_title}
                                    courseSlug={thread.course_slug}
                                    lessonTitle={thread.lesson_title}
                                    lessonId={thread.lesson_id}
                                    totalAnswer={thread.total_answer}
                                />
                            </Link>
                        )
                    }).slice(0, limit)
                    : (<h4 className="text-center text-muted">Hasil pencarian tidak ditemukan..</h4>)
            }

            {threads !== null && threads.filter((value) => {
                if (searchValue === "") {
                    return true;
                } else {
                    return value.subject.toLowerCase().includes(searchValue.toLowerCase());
                }
            }).length >= limit && (
                    <div className="text-center">
                        <button onClick={() => setLimit(limit + 5)} className="btn btn-sm btn-outline-secondary">
                            <FontAwesomeIcon className="me-1" icon={faArrowRotateRight} />
                            <span>Lihat Lainnya</span>
                        </button>
                    </div>
                )
            }
        </section>
    )
}

export default SectionLatestDiscussion