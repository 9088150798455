import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updateProfile } from "../../../features/user/userSlice";
import { formatDate } from "../../../utils/helper";
import Input from "../../global/Inputs/Input/Input";
import InputSelect from "../../global/Inputs/InputSelect/InputSelect";
import InputTextArea from "../../global/Inputs/InputTextArea/InputTextArea";
import Modal from "../../global/Modal/Modal";

const SectionBiodata = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadingUser && user) {
            reset({
                name: user?.name,
                gender: user?.gender,
                birthday: user?.birthday,
                phone: parseInt(user?.phone),
                jobs: user?.jobs,
                address: user?.address,
                username: user?.username,
                profile_picture: user?.avatar,
                instagram: user?.instagram,
                facebook: user?.facebook,
                linkedin: user?.linkedin,
                github: user?.github,
                web: user?.web,
                available_for_work: user?.available_for_work,
                short_description: user?.short_description
            });
        }
    }, [loadingUser, user, reset]);

    const onSubmit = (payload) => {
        dispatch(updateProfile(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">Biodata</h5>
                        {myUser?.username === user?.username ? (
                            <button className="btn text-muted" data-bs-toggle="modal" data-bs-target="#editBiodata">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        ) : null}
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item px-0 text-muted">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="m-auto fw-bolder">Name</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p className="m-auto">{loadingUser ? 'Loading...' : user?.name || '-'}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item px-0 text-muted">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="m-auto fw-bolder">Gender</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p className="m-auto text-capitalize">{loadingUser ? 'Loading...' : user?.gender || '-'}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item px-0 text-muted">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="m-auto fw-bolder">Date of birth</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p className="m-auto">{loadingUser ? 'Loading...' : formatDate(user?.birthday) || '-'}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item px-0 text-muted">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="m-auto fw-bolder">Phone</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p className="m-auto">{loadingUser ? 'Loading...' : user?.phone || '-'}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item px-0 text-muted">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="m-auto fw-bolder">Address</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p className="m-auto">{loadingUser ? 'Loading...' : user?.address || '-'}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Modal
                id="editBiodata"
                title="Edit Biodata"
                backdrop
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="name"
                            label="Fullname"
                            placeholder="Your fullname..."
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3" hidden>
                        <Input
                            type="text"
                            name="username"
                            label="Username"
                            placeholder="Your Username..."
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <InputSelect
                                name="gender"
                                label="Gender"
                                isRequired
                                register={register}
                                errors={errors}
                            >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </InputSelect>
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="date"
                                name="birthday"
                                label="Date of birth"
                                isRequired
                                register={register}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <Input
                            type="number"
                            name="phone"
                            label="Phone/Whatsapp"
                            placeholder="0822354xxx"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <InputTextArea
                            name="address"
                            label="Address"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingUser}>{loadingUser ? 'Please wait...' : 'Update'}</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionBiodata;