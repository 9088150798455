import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.scss';

const NavbarAuth = () => {
    const [icon, setIcon] = useState(false);

    return (
        <nav className={`${styles.main_navbar} navbar navbar-expand-md navbar-light bg-white border-bottom border-2 fixed-top`} id="navbar">
            <div className="container-fluid px-4 px-lg-5">
                <a className="navbar-brand" href="https://www.codepolitan.com">
                    <img height={45} width={'auto'} src="/assets/img/codepolitan-logo.png" alt="Codepolitan" />
                </a>
                <button onClick={() => setIcon((icon) => !icon)} className="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    {icon ? icon && <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                        <li className="d-grid mb-2 mb-md-0">
                            <a className="btn btn-light px-3 mx-1" href="https://www.codepolitan.com/login">
                                <strong>LOGIN</strong>
                            </a>
                        </li>
                        <li className="d-grid">
                            <Link className="btn btn-primary px-3 mx-1" to="/register">
                                <strong>REGISTER</strong>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavbarAuth