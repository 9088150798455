import { faPlusCircle, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Layout from "../../../components/global/Layout/Layout";
import Modal from '../../../components/global/Modal/Modal';
import ListRoadmap from '../../../components/mentor/Lists/ListRoadmap/ListRoadmap';
import { getMentorCourses } from '../../../features/mentor/mentorCourseSlice';
import { connectCoursesToRoadmap, getCoursesByRoadmap } from '../../../features/mentor/mentorRoadmapSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { capitalizeFirstLetter, removeHTMLTags } from '../../../utils/helper';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const EditRoadmapCurriculum = () => {

    const MySwal = withReactContent(Swal);
    const closeModal = document.querySelector(".btn-close");

    const { id } = useParams();
    const { mentorCourses, loadingMentorCourse } = useSelector((state) => ({ ...state.mentorCourse }));
    const { coursesByRoadmap } = useSelector((state) => ({ ...state.mentorRoadmap }));

    const [newCourses, setNewCourses] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMentorCourses());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCoursesByRoadmap(id));
    }, [dispatch, id]);

    // Add course item to selected list
    const onAddCourseItem = (id) => {
        const courseId = mentorCourses.findIndex((course) => course.id === id);

        setNewCourses([
            ...newCourses,
            mentorCourses[courseId]
        ]);
    };

    const onRemoveCourseItem = (index) => {
        const selectedCourses = [...newCourses];
        selectedCourses.splice(index, 1);
        setNewCourses(selectedCourses);
    };

    // Submit Selected Courses
    const onSubmitSelectedCourses = () => {
        let finalCourses = [];
        newCourses.forEach((course) => {
            finalCourses.push({
                id: course.id
            });
        });

        dispatch(connectCoursesToRoadmap({ id: id, finalCourses: finalCourses })).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModal.click(),
                setNewCourses([])
            );
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Roadmap</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <h3 className="section-title mb-5">Edit Roadmap</h3>

                                <ul className="nav nav-pills text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/roadmaps/edit/overview/${id}`}>Overview</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4 active" to={`/mentor/roadmaps/edit/curriculum/${id}`}>Curriculum</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/roadmaps/edit/pricing/${id}`}>Pricing</Link>
                                    </li>
                                </ul>

                                <div className="row">
                                    <div className="col-lg-8">
                                        <ListRoadmap data={coursesByRoadmap} />
                                        <div className="my-3 d-grid gap-2">
                                            <button className="btn btn-outline-primary rounded-0" style={{ borderStyle: 'dashed' }} data-bs-toggle="modal" data-bs-target="#roadmapForm">
                                                <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
                                                Add Course
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>

                <Modal id="roadmapForm" title={"Add Course"}>
                    <div className="row">
                        <div className="col-lg-7 bg-light mb-4 mb-lg-auto">
                            <ul className="p-2 overflow-auto" style={{ maxHeight: '25em' }}>
                                {!loadingMentorCourse && mentorCourses?.map((course, index) => {

                                    let disabled = false;
                                    const matchCourses = newCourses.filter(course => mentorCourses.includes(course));

                                    if (matchCourses.includes(course)) {
                                        disabled = true;
                                    };

                                    return (
                                        <li className={!disabled ? "card mb-2" : "card border-primary border-3 mb-2"} key={index}>
                                            <button onClick={() => onAddCourseItem(course.id)} className="card-body btn text-start p-2" disabled={disabled}>
                                                <div className="row">
                                                    <div className="col-4 my-auto">
                                                        <img style={{ height: '5em' }} className="w-100 objectfit-cover" src={course.thumbnail || "/assets/img/placeholder.jpg"} alt="Placeholder" />
                                                    </div>
                                                    <div className="col-8 my-auto">
                                                        <h6 className="text-secondary">{course.course_title}</h6>
                                                        <p className="text-muted mb-auto">
                                                            <small>
                                                                Published
                                                            </small>
                                                            <small className="ms-2">
                                                                <FontAwesomeIcon className="me-1" icon={faUsers} />
                                                                {course.total_student || 0} Siswa
                                                            </small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                    );
                                })}
                                {!loadingMentorCourse && mentorCourses.length < 1 && (
                                    <p className="text-muted text-center my-5">No active course</p>
                                )}
                            </ul>
                        </div>
                        <div className="col-lg-5">
                            <h6 className="text-muted"><strong>Kelas Terpilih</strong></h6>
                            <ul className="bg-light p-3 overflow-auto" style={{ height: '20em' }}>
                                {newCourses?.map((course, index) => {
                                    return (
                                        <li className="card mb-2" key={index}>
                                            <div className="card-body text-muted p-2">
                                                <div className="d-flex justify-content-between">
                                                    <small className="my-auto fw-bolder">
                                                        {course.course_title}
                                                    </small>
                                                    <button onClick={() => onRemoveCourseItem(index)} className="btn btn-sm text-danger shadow-none">
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="d-grid gap-2 my-3">
                                <button onClick={onSubmitSelectedCourses} className="btn btn-primary btn-rounded" disabled={newCourses.length < 1}>Tambah {newCourses?.length} Kelas</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Layout>
        </>
    );
};

export default EditRoadmapCurriculum;
