import { faCheckCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SidebarCollapseExercise.module.scss';

const SidebarCollapseExercise = ({ isOpen, onClickToggle, questions, currentQuestion, setCurrentQuestion, answer }) => {
    return (
        <div className={isOpen ? `${styles.sidebar_collapse} ${styles.open}` : styles.sidebar_collapse}>
            <div className="container mt-3 px-0">
                <a className="navbar-brand ms-3" href="https://www.codepolitan.com">
                    <img height={45} width={'auto'} src="/assets/img/codepolitan-logo.png" alt="Codepolitan" />
                </a>
                <div className="overflow-auto my-4" style={{ maxHeight: '85vh' }}>
                    <div className="bg-primary p-3">
                        <p className="text-white m-0">Completed {answer?.length} of {questions?.length} questions</p>
                    </div>
                    <div className="list-group list-group-flush">
                        {questions?.map((question, index) => {
                            const currentAnswer = [...answer],
                                indexQuestion = currentAnswer.findIndex(search => search.question_id === question.id);

                            return (
                                <button onClick={() => setCurrentQuestion(index)} type="button" className={`list-group-item list-group-item-action text-muted d-flex justify-content-between align-items-center p-3 ${currentQuestion === index ? 'bg-light' : null}`} key={question.id}>
                                    <span className={currentQuestion === index ? 'fw-bolder text-primary' : ''}>
                                        {question.title || `Soal ${index + 1}`}
                                    </span>
                                    {answer[indexQuestion]?.question_id === question.id ? (<FontAwesomeIcon className="text-primary" icon={faCheckCircle} />) : null}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
            <button onClick={onClickToggle} className={styles.sidebar_toggle}>
                <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
            </button>
        </div>
    );
};
export default SidebarCollapseExercise;
