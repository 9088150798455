import CardIncome from '../../Cards/CardIncome/CardIncome'
import { useSelector } from 'react-redux';

const SectionEarningsSummary = () => {
    const { statistic } = useSelector((state) => ({ ...state.mentor }));

    return (
        <section className="mb-5">
            <h4 className="section-title mb-3">Penghasilan</h4>
            <div className="row g-3">
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_order} title="Total Penjualan" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_transaction} price title="Total Transaksi" />
                </div>
                <div className="col-md-4">
                    <CardIncome total={statistic?.total_earning} price title="Total Penghasilan" />
                </div>
            </div>
        </section>
    );
};

export default SectionEarningsSummary;