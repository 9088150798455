import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const SectionMentors = () => {
    const { course, loadingCourse } = useSelector((state) => ({ ...state.courses }));

    return (
        <section className="card mb-3" hidden={course?.authors === null}>
            <div className="card-body text-muted">
                <h5><FontAwesomeIcon className="text-primary me-2" icon={faRobot} />Mentor</h5>

                {!loadingCourse && (
                    <ul className="list-group list-group-flush">
                        {course?.authors?.map((author, index) => {
                            return (
                                <li className="list-group-item px-0" key={index}>
                                    <div className="row mt-3">
                                        <div className="col-4 col-md-3 mb-3">
                                            <img className="img-fluid objectfit-cover rounded-2" src={author.avatar || "/assets/img/placeholder.jpg"} alt={author.name} />
                                        </div>
                                        <div className="col">
                                            <a className="link" href={`https://codepolitan.com/mentor/${author.username}`} target="_blank" rel="noopener noreferrer">
                                                <h5 className="fw-bolder">{author.name}</h5>
                                            </a>
                                            <hr className="text-primary fw-bolder border-3 opacity-100 my-2" style={{ width: '90px' }} />
                                            <p className="text-muted">{author.short_description}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </section>
    );
};

export default SectionMentors;
