import { faCamera, faDownload, faEdit, faEnvelope, faEnvelopeOpenText, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateJob, updateWorkStatus } from '../../../features/user/userSlice';
import Input from '../Inputs/Input/Input';
import Modal from '../Modal/Modal';

const Header = () => {
    const workStatus = localStorage.getItem('availableForWork');
    const [available, setAvailable] = useState(workStatus === "Yes" ? true : false);

    const { myUser, user } = useSelector((state) => ({ ...state.user }));

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.jobs) {
            reset({
                jobs: user?.jobs,
            });
        }
    }, [user?.jobs, reset]);

    // Handle update work status
    const handleUpdateWorkStatus = () => {
        setAvailable(!available);
        available && dispatch(updateWorkStatus("No"));
        !available && dispatch(updateWorkStatus("Yes"));
    };

    const MySwal = withReactContent(Swal);

    const onSubmit = (payload) => {
        dispatch(updateJob(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="bg-hero" style={{ height: '60px', marginBottom: '-3rem' }}>
                        {myUser?.username === user?.username ? (
                            <div className="d-none d-lg-flex justify-content-end">
                                <div className="d-flex mt-3 me-4">
                                    <Link className="btn text-muted p-0" to="/user/cv"><FontAwesomeIcon icon={faDownload} /> Download CV</Link>
                                    <span className="mx-2 my-auto">|</span>
                                    <div className="form-check form-switch my-auto">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={available}
                                            onChange={handleUpdateWorkStatus}
                                        />
                                        <label>Open to work</label>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-none d-lg-flex justify-content-end">
                                <div className="d-flex mt-3 me-4">
                                    <button type="button" className="btn text-primary p-0" data-bs-toggle="modal" data-bs-target="#contactModal"><FontAwesomeIcon icon={faEnvelopeOpenText} /> Contact Info</button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="d-flex flex-column flex-lg-row align-items-center text-muted">
                        {myUser?.username === user?.username ? (
                            <Link className="link position-relative" to="/user/settings/avatar">
                                <img width={185} height={185} className="rounded-circle objectfit-cover p-1" src={user?.avatar || "https://image.web.id/images/Avatar.jpg"} alt={user?.name} />
                                {user?.available_for_work === "Yes" ? (
                                    <img width={150} height={185} className="img-fluid position-absolute translate-bottom bottom-0" style={{ left: '17px' }} src="https://image.web.id/images/opentowork.png" alt="Open to work" />
                                ) : null}
                                <span className="position-absolute translate-bottom badge rounded-circle bg-light text-muted p-2 shadow-sm" style={{ fontSize: '.7rem', bottom: '25px', right: '15px' }}>
                                    <FontAwesomeIcon size="lg" icon={faCamera} />
                                </span>
                            </Link>
                        ) : (
                            <div className="position-relative">
                                <img width={185} height={185} className="rounded-circle objectfit-cover p-1" src={user?.avatar || "https://image.web.id/images/Avatar.jpg"} alt={user?.name} />
                                {user?.available_for_work === "Yes" ? (
                                    <img width={150} height={185} className="img-fluid position-absolute translate-bottom bottom-0" style={{ left: '17px' }} src="https://image.web.id/images/opentowork.png" alt="Open to work" />
                                ) : null}
                            </div>
                        )}
                        <div className="d-flex flex-column ms-0 ms-lg-4 gap-3 mt-lg-5">
                            <h2 className="mt-3 mt-lg-0 text-center text-lg-start mb-2">
                                {user?.name || (<Skeleton className="bg-white" width={300} />)}
                            </h2>
                            {user?.rank?.rank_name || user?.jobs ? (
                                <div className="text-center text-lg-start">
                                    <img width="25px" height="25px" className="mb-1 me-2" src={user?.rank?.rank_picture} alt={user?.rank?.rank_name} />
                                    <span className="my-auto">{user?.rank?.rank_name || <i>Belum ada rank</i>}</span>
                                    <span className="mx-1">|</span>
                                    <span>{user?.jobs || <i>Headline belum diisi</i>}</span>
                                    {myUser?.username === user?.username ? (
                                        <button className="btn text-muted" data-bs-toggle="modal" data-bs-target="#editHeadline">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    ) : null}
                                </div>
                            ) : (
                                <Skeleton width={400} className="bg-white" />
                            )}
                        </div>
                    </div>
                    {myUser?.username === user?.username ? (
                        <div className="d-flex justify-content-center d-lg-none mt-4">
                            <Link className="btn text-muted p-0" to="/user/cv"><FontAwesomeIcon icon={faDownload} /> Download CV</Link>
                            <span className="mx-2 my-auto">|</span>
                            <div className="form-check form-switch my-auto">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={available}
                                    onChange={handleUpdateWorkStatus}
                                />
                                <label>Open to work</label>
                            </div>
                        </div>
                    ) : (
                        <div className="d-grid d-lg-none my-3">
                            <button type="button" className="btn text-primary p-0" data-bs-toggle="modal" data-bs-target="#contactModal"><FontAwesomeIcon icon={faEnvelopeOpenText} /> Contact Info</button>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                id="editHeadline"
                title="Edit Headline"
                backdrop
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="jobs"
                            label="Headline"
                            placeholder="ex. JavaScript Enthusiast, Laravel Expert, etc."
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </form>
            </Modal>
            <Modal
                id="contactModal"
                title="Contact Info"
                backdrop
            >
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-1 col-md-auto">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{user?.name}'s Profile</h6>
                                <a className="link text-primary text-break" href={`https://dashboard.codepolitan.com/user/${user?.username}`} target="_blank" rel="noopener noreferrer">dashboard.codepolitan.com/user/{user?.username}</a>
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-1 col-md-auto">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className="col">
                                <h6 className="mb-0">Email</h6>
                                <a className="link text-primary text-break" href={`mailto:${user?.email}`} target="_blank" rel="noopener noreferrer">{user?.email}</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </Modal>
        </>
    );
};

export default Header;