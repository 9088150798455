import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom"

const DropdownMenu = () => {
    const location = useLocation();
    const [showList, setShowList] = useState(false);

    return (
        <div>
            <div className="d-grid">
                <button onClick={() => setShowList(!showList)} className="btn btn-outline-secondary shadow-none">
                    <div className="d-flex justify-content-between">
                        <span>{document.title}</span>
                        <FontAwesomeIcon className="my-auto" icon={showList ? faChevronUp : faChevronDown} />
                    </div>
                </button>
            </div>
            {showList && (
                <ul className="list-group bg-transparent mt-3" role="tablist">
                    <li className="list-group-item bg-transparent border-0">
                        <Link className={`link ${location.pathname === '/user/settings' && 'text-primary'}`} type="button" to="/user/settings">Account</Link>
                    </li>
                    <li className="list-group-item bg-transparent border-0">
                        <Link className={`link ${location.pathname === '/user/settings/avatar' && 'text-primary'}`} type="button" to="/user/settings/avatar">Avatar</Link>
                    </li>
                    <li className="list-group-item bg-transparent border-0">
                        <Link className={`link ${location.pathname === '/user/settings/change-password' && 'text-primary'}`} type="button" to="/user/settings/change-password">Change Password</Link>
                    </li>
                    <li className="list-group-item bg-transparent border-0">
                        <Link className={`link ${location.pathname === '/user/settings/interface' && 'text-primary'}`} type="button" to="/user/settings/interface">Interface</Link>
                    </li>
                </ul>
            )}
        </div>
    )
}

export default DropdownMenu