import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const SectionSearch = ({ setSearch }) => {
    const [value, setValue] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        setSearch(`~${value}~`)
    };

    return (
        <section className="mb-4">
            <div
                className="card border-0 rounded-2 text-white text-center"
                style={{
                    background: '#14a7a0 url(https://image.web.id/images/Group-4113.png)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="card-body p-5">
                    <h1 className="h3">Cari course yang ingin kamu pelajari disini</h1>
                    <p>Lebih dari 250+ course yang dapat kamu pelajari disini</p>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <form onSubmit={onSubmit}>
                                <div className="input-group">
                                    <input
                                        onChange={(e) => setValue(e.target.value)}
                                        value={value}
                                        type="search"
                                        className="form-control shadow-none text-muted"
                                        placeholder="Cari apa yang kamu butuhkan disini..."
                                        style={{ borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px' }}
                                    />
                                    <button
                                        type="submit"
                                        className="input-group-text bg-white text-muted"
                                        title="Cari"
                                        style={{ borderTopRightRadius: '25px', borderBottomRightRadius: '25px' }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="d-grid d-md-flex gap-2 justify-content-center mt-4">
                        <p className="my-auto">Trending Keywords:</p>
                        <button onClick={() => setValue('Laravel dasar')} type="button" className="btn btn-outline-light btn-sm rounded-pill px-3">Laravel dasar</button>
                        <button onClick={() => setValue('React')} type="button" className="btn btn-outline-light btn-sm rounded-pill px-3">React</button>
                        <button onClick={() => setValue('Javascript')} type="button" className="btn btn-outline-light btn-sm rounded-pill px-3">Javascript</button>
                        <button onClick={() => setValue('PHP')} type="button" className="btn btn-outline-light btn-sm rounded-pill px-3">PHP</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionSearch;
