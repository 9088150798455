import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getLogsCertificates } from "../../features/user/certificatesSlice"
import 'react-loading-skeleton/dist/skeleton.css'
import { getPortfolios } from '../../features/user/portfoliosSlice';
import { getOccupations } from '../../features/user/occupationSlice';
import { getEducations } from '../../features/user/educationsSlice';
import Header from '../../components/global/Header/Header';
import { getSkillOptions, getSkills } from '../../features/user/skillSlice';
import SectionAbout from '../../components/profile/SectionAbout/SectionAbout';
import SectionBiodata from '../../components/profile/SectionBiodata/SectionBiodata';
import SectionSkills from '../../components/profile/SectionSkills/SectionSkills';
import SectionSocialMedia from '../../components/profile/SectionSocialMedia/SectionSocialMedia';
import SectionCertificates from '../../components/profile/SectionCertificates/SectionCertificates';
import SectionOccupations from '../../components/profile/SectionOccupations/SectionOccupations';
import SectionPortfolios from '../../components/profile/SectionPortfolios/SectionPortfolios';
import SectionEducations from '../../components/profile/SectionEducations/SectionEducations';
import { getUserByUsername } from '../../features/user/userSlice';
import { useLocation } from 'react-router-dom';

const ProfileOverview = () => {
    const location = useLocation();
    const segments = location.pathname.split('/');
    const username = segments[segments.length - 1];

    // State from redux
    const dispatch = useDispatch()
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));

    useEffect(() => {
        if (username) {
            dispatch(getUserByUsername(username));
        }
        if (myUser?.username === user?.username) {
            dispatch(getLogsCertificates());
            dispatch(getPortfolios());
            dispatch(getOccupations());
            dispatch(getEducations());
            dispatch(getSkills());
            dispatch(getSkillOptions());
        }
    }, [dispatch, username, myUser?.username, user?.username]);

    if ((!loadingUser && !user) || user[0] === 'There is no data') {
        return (
            <>
                <Helmet>
                    <title>Profile not found</title>
                </Helmet>
                <Layout>
                    <section className="text-muted vh-100 d-flex flex-column justify-content-center align-items-center">
                        <div className="container-fluid text-center">
                            <img height="120" width="auto" className="mb-4" src="/assets/img/oops.png" alt="Oops!" />
                            <h5 className="fw-bolder">Profile Tidak Ditemukan</h5>
                            <p>Oops, halaman profile yang kamu cari tidak ditemukan</p>
                        </div>
                    </section>
                </Layout>
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>{!loadingUser ? user?.name : 'Please wait...' || 'Profile'}</title>
            </Helmet>
            <Layout>
                <section id="profileOverview" className="text-muted">
                    <div className="container-fluid mt-5">
                        <Header />
                        <div className="row mt-5">
                            <div className="col-lg-7">
                                <SectionAbout />
                                <SectionSkills />
                                <SectionCertificates />
                                <SectionOccupations />
                                <SectionPortfolios />
                                <SectionEducations />
                            </div>
                            <div className="col-lg-5 order-first order-lg-last">
                                {myUser?.username === user?.username ? (
                                    <SectionBiodata />
                                ) : null}
                                <SectionSocialMedia />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default ProfileOverview;
