import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from "remark-gfm";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faChevronLeft, faChevronRight, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

import { CodeBlock } from "../../global/CodeBlock";
import Timer from "../../exercises/Timer/Timer";
import { getQuizzesByGroup, postAnswers, postUnderstand } from "../../../features/quiz/quizSlice";

const Quiz = ({ data, type }) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answer, setAnswer] = useState([]);
    const [expired, setExpired] = useState(false);

    const [inputData, setInputData] = useState('');

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch()

    const { theme } = useSelector((state) => (state.theme));
    const { quizzesByGroup, loadingQuiz } = useSelector((state) => ({ ...state.quiz }));

    const courseId = data?.course_id;
    const lessonId = data?.id;

    useEffect(() => {
        if (data?.lesson_uri || data?.lesson_slug || data?.exam?.slug) {
            dispatch(getQuizzesByGroup(data?.lesson_uri || data?.lesson_slug || data?.exam?.slug));
        };
    }, [dispatch, data?.lesson_uri, data?.lesson_slug, data?.exam?.slug]);

    const quiz = quizzesByGroup;

    const onSelectOption = ({ questionId, optionId }) => {
        if (currentQuestion < quiz?.questions?.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        };

        setAnswer(state => {
            const currentAnswer = [...state],
                index = currentAnswer.findIndex(search => search.question_id === questionId);

            if (index > -1) {
                // ketemu questionId yang sama sebelumnya, contoh pengen ganti option_id, dari parameter
                currentAnswer[index].option_id = optionId;
            } else {
                // gak ketemu questionId dari parameter, contoh pengen tambah item array
                currentAnswer.push({
                    question_id: questionId,
                    option_id: optionId,
                });
            }

            return currentAnswer // ngeset nilai baru
        });
    };

    const onSubmitAnswer = () => {
        if (!expired) {
            MySwal.fire({
                icon: 'question',
                title: 'Selesaikan?',
                confirmButtonText: 'Selesai',
                showDenyButton: true,
                denyButtonText: 'Batal',
                confirmButtonColor: '#14a7a0',
                text: `Apakah kamu yakin untuk menyelesaikan ${type === 'quiz' ? 'quiz' : 'exam'} ini?`
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(postAnswers({
                        groupId: quiz?.detail?.id,
                        duration: quiz?.detail?.duration,
                        sessionId: '',
                        answer: answer
                    })).then((results) => {
                        MySwal.fire({
                            icon: results.payload.score >= quiz?.detail?.kkm ? 'success' : 'error',
                            title: results.payload.score >= quiz?.detail?.kkm ? 'Selamat! Kamu Lulus' : 'Maaf, Kamu Belum Lulus',
                            confirmButtonText: results.payload.score >= quiz?.detail?.kkm ? 'Tutup' : 'Coba Lagi',
                            confirmButtonColor: '#14a7a0',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            width: type === 'quiz' ? 800 : 600,
                            html:
                                <>
                                    <p>Score kamu <span className={results.payload.score >= quiz?.detail?.kkm ? "fw-bolder text-primary" : "fw-bolder text-danger"}>{results.payload.score}</span> dari KKM <span className="fw-bolder">{quiz?.detail?.kkm}</span></p>
                                    {results.payload.score >= quiz?.detail?.kkm && type === 'exam' ? <p>Silahkan klik Finish Course di Sidebar untuk menyelesaikan kelas ini</p> : null}
                                    {type === "quiz" && <h4 className="text-start my-4">Jawaban yang benar adalah :</h4>}
                                    {type === 'quiz' && !loadingQuiz && quiz?.questions?.map((question, index) => {
                                        return (
                                            <div className="text-start mb-3" key={index}>
                                                <div className="d-flex">
                                                    <p className="me-2">{index + 1}</p>
                                                    <p className="text-dark" dangerouslySetInnerHTML={{ __html: question?.content }} />
                                                </div>
                                                <div className="list-group rounded-0">
                                                    {question?.options?.map((option) => {
                                                        const currentAnswer = [...answer],
                                                            indexOption = currentAnswer.findIndex(search => search.option_id === option.id);

                                                        return (
                                                            <li
                                                                key={option.id}
                                                                className={`list-group-item list-group-item py-3 ${answer[indexOption]?.option_id === option.id && 'border border-3 border-primary'}`}
                                                            >
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <ReactMarkdown
                                                                            className="markdown"
                                                                            remarkPlugins={[remarkGfm]}
                                                                            rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                                            components={CodeBlock}
                                                                        >
                                                                            {option.content}
                                                                        </ReactMarkdown>

                                                                    </div>
                                                                    <div className="col-auto">
                                                                        {option?.is_right === 1 ? (
                                                                            <div className="ms-auto my-auto">
                                                                                <FontAwesomeIcon className="text-primary" icon={faCheckCircle} />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="ms-auto my-auto">
                                                                                <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>,
                        }).then((result => {
                            if (result.isConfirmed && results.payload.score < quiz?.detail?.kkm) {
                                window.location.reload();
                            } else {
                                if (type === 'quiz') {
                                    dispatch(postUnderstand({ courseId: courseId, lessonId: lessonId })).then(() => window.location.reload());
                                } else {
                                    window.location.reload();
                                };
                            };
                        }));
                    })
                }
            })
        } else {
            dispatch(postAnswers({
                groupId: quiz?.detail?.id,
                duration: quiz?.detail?.duration,
                sessionId: '',
                answer: answer
            })).then((results) => {
                MySwal.fire({
                    icon: results.payload.score >= quiz?.detail?.kkm ? 'success' : 'error',
                    title: results.payload.score >= quiz?.detail?.kkm ? 'Selamat! Kamu Lulus' : 'Maaf, Kamu Belum Lulus',
                    confirmButtonText: results.payload.score >= quiz?.detail?.kkm ? 'Tutup' : 'Coba Lagi',
                    confirmButtonColor: '#14a7a0',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    width: type === 'quiz' ? 800 : 600,
                    html:
                        <>
                            <p>Score kamu <span className={results.payload.score >= quiz?.detail?.kkm ? "fw-bolder text-primary" : "fw-bolder text-danger"}>{results.payload.score}</span> dari KKM <span className="fw-bolder">{quiz?.detail?.kkm}</span></p>
                            {results.payload.score >= quiz?.detail?.kkm && type === 'exam' ? <p>Silahkan klik Finish Course di Sidebar untuk menyelesaikan kelas ini</p> : null}
                            {type === "quiz" && <h4 className="text-start my-4">Jawaban yang benar adalah :</h4>}
                            {type === 'quiz' && !loadingQuiz && quiz?.questions?.map((question, index) => {
                                return (
                                    <div className="text-start mb-3" key={index}>
                                        <div className="d-flex">
                                            <p className="me-2">{index + 1}</p>
                                            <p className="text-dark" dangerouslySetInnerHTML={{ __html: question?.content }} />
                                        </div>
                                        <div className="list-group rounded-0">
                                            {question?.options?.map((option) => {
                                                const currentAnswer = [...answer],
                                                    indexOption = currentAnswer.findIndex(search => search.option_id === option.id);

                                                return (
                                                    <li
                                                        key={option.id}
                                                        className={`list-group-item list-group-item py-3 ${answer[indexOption]?.option_id === option.id && 'border border-3 border-primary'}`}
                                                    >
                                                        <div className="row">
                                                            <div className="col">
                                                                <ReactMarkdown
                                                                    className="markdown"
                                                                    remarkPlugins={[remarkGfm]}
                                                                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                                    components={CodeBlock}
                                                                >
                                                                    {option.content}
                                                                </ReactMarkdown>

                                                            </div>
                                                            <div className="col-auto">
                                                                {option?.is_right === 1 ? (
                                                                    <div className="ms-auto my-auto">
                                                                        <FontAwesomeIcon className="text-primary" icon={faCheckCircle} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="ms-auto my-auto">
                                                                        <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </>,
                }).then((result => {
                    if (result.isConfirmed && results.payload.score < quiz?.detail?.kkm) {
                        window.location.reload();
                    } else {
                        if (type === 'quiz') {
                            dispatch(postUnderstand({ courseId: courseId, lessonId: lessonId }));
                        } else {
                            window.location.reload();
                        };
                    };
                }));
            })
        }
    };

    const onKeyUp = (event) => {
        setInputData(event.target.value);
    };

    useEffect(() => {
        if (quiz?.questions && quiz?.questions[currentQuestion]?.options?.length === 1) {
            const myInputs = document.querySelector('#my-inputs');
            const input = myInputs?.querySelectorAll('input');
            if (input && input.length > 0) {
                input[0].addEventListener('keyup', onKeyUp);
            };

            if (inputData?.length > 0) {
                input[0].value = inputData;
            }
        }
    }, [currentQuestion, inputData, inputData?.length, quiz?.questions]);

    const checkAnswer = () => {
        let questionId = quiz?.questions[currentQuestion]?.id;
        let optionId = quiz?.questions[currentQuestion]?.options[0]?.id;

        if (inputData !== quiz?.questions[currentQuestion]?.options[0]?.content) {
            optionId = 0
        };

        onSelectOption({
            questionId,
            optionId
        });
    };

    // Force submit if the time is up
    useEffect(() => {
        if (expired) {
            onSubmitAnswer();
        };
    });

    return (
        <section className="main container-fluid">
            <div className="row justify-content-between">
                <div className="col-auto mb-4">
                    <h4 className={`section-title ${theme === 'dark' && 'text-white'}`}>{type === 'quiz' ? 'Soal ' : 'Exam: '} {currentQuestion + 1} / {quiz?.questions?.length || 1}</h4>
                </div>
                {type === 'quiz' && (
                    <div className="col-auto my-auto mb-4">
                        <Timer
                            duration={quiz?.detail?.duration || 0}
                            setExpired={setExpired}
                        />
                    </div>
                )}
            </div>
            {loadingQuiz ? (
                <div>
                    <Skeleton className="mb-4" width="75%" />
                    <ul className="list-group rounded-0">
                        {[1, 2, 3, 4, 5].map(item => (
                            <li className="list-group-item py-3" key={item}>
                                <Skeleton width="40%" />
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
            {!loadingQuiz && quiz?.questions && quiz?.questions[currentQuestion]?.options?.length > 1 ? (
                <>
                    <div className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                        <ReactMarkdown
                            className="markdown"
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw, rehypeSanitize]}
                            components={CodeBlock}
                        >
                            {quiz?.questions[currentQuestion]?.content}
                        </ReactMarkdown>
                    </div>
                    <div className="list-group rounded-0">
                        {quiz?.questions[currentQuestion]?.options?.map((option) => {
                            const currentAnswer = [...answer],
                                indexOption = currentAnswer.findIndex(search => search.option_id === option.id);

                            return (
                                <button
                                    key={option.id}
                                    onClick={() => onSelectOption({
                                        questionId: quiz?.questions[currentQuestion]?.id,
                                        optionId: option.id
                                    })}
                                    type="button"
                                    className={answer[indexOption]?.option_id === option.id ?
                                        "list-group-item list-group-item-action py-3 border border-3 border-primary bg-light"
                                        : `list-group-item list-group-item-action ${theme === 'dark' && 'bg-transparent border border-primary text-white'} py-3`
                                    }
                                >
                                    <ReactMarkdown
                                        className="markdown"
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                        components={CodeBlock}
                                    >
                                        {option.content}
                                    </ReactMarkdown>
                                </button>
                            );
                        })}
                    </div>
                    <div className="row justify-content-between my-4">
                        <div className="col-auto">
                            <button onClick={() => setCurrentQuestion(current => current - 1)} type="button" className="btn btn-light btn-lg border rounded-0" disabled={currentQuestion === 0}><FontAwesomeIcon icon={faChevronLeft} /> Prev</button>
                        </div>
                        <div className="col-auto">
                            {currentQuestion + 1 === quiz?.questions?.length ? (
                                <button onClick={onSubmitAnswer} type="button" className="btn btn-primary text-white btn-lg rounded-0" disabled={answer?.length < quiz?.questions?.length}>Selesai</button>
                            ) : (
                                <button onClick={() => setCurrentQuestion(current => current + 1)} type="button" className="btn btn-light btn-lg border rounded-0" disabled={currentQuestion >= quiz?.questions?.length}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                            )}
                        </div>
                    </div>
                </>
            ) : null}
            {!loadingQuiz && quiz?.questions && quiz?.questions[currentQuestion]?.options?.length === 1 ? (
                <>
                    <div className="bg-light text-muted w-100 p-3" style={{ minHeight: '300px' }}>
                        <div id="my-inputs" dangerouslySetInnerHTML={{ __html: quiz?.questions ? quiz?.questions[currentQuestion]?.content : null }} />
                    </div>
                    <div className="row justify-content-between my-3">
                        <div className="col-auto">
                            <button type="button" onClick={checkAnswer} className="btn btn-outline-primary btn-lg rounded-0" disabled={inputData?.length === 0}>Submit</button>
                        </div>
                        <div className="col-auto">
                            <button onClick={onSubmitAnswer} type="button" className="btn btn-primary btn-lg rounded-0" disabled={answer?.length < quiz?.questions?.length}>Selesaikan Quiz</button>
                        </div>
                    </div>
                </>
            ) : null}
        </section>
    );
};

export default Quiz;
