import { Chart, Filler, LineElement, PointElement, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

Chart.register(Filler, PointElement, LineElement, Tooltip);

const SectionStatistic = () => {
    const { lessonStats, loadingLesson } = useSelector((state) => ({ ...state.lesson }));

    let total = [];
    let labels = [];

    !loadingLesson && lessonStats?.map(item => total.push(item.total));
    !loadingLesson && lessonStats?.map(item => labels.push(item.date));

    const options = {
        responsive: true,
        tension: 0.3,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            }
        },
    };

    const getGradient = (ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, '#ff869a');
        gradient.addColorStop(1, '#ff869a25');
        gradient.addColorStop(0, '#ff869a00');
        return gradient;
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Lesson',
                data: total,
                borderColor: '#ff869a',
                backgroundColor: (context) => {
                    const { ctx, chartArea } = context.chart;
                    if (!chartArea) return;
                    return getGradient(ctx, chartArea);
                },
                pointBackgroundColor: '#ff869a',
                pointRadius: 5,
                pointHoverRadius: 5,
                fill: true
            }
        ],
    };

    return (
        <section className="section">
            <h4 className="section-title mb-4">Statistic</h4>
            <div className="card border-0 rounded-2">
                <div className="card-body">
                    {!loadingLesson && lessonStats?.length < 1 ?
                        (
                            <div className="alert alert-primary border-0" role="alert">
                                <p className="text-center mb-0">Kamu belum belajar apapun dalam seminggu terakhir. Ayo tingkatkan lagi progres belajarmu!</p>
                            </div>
                        ) : null}
                    <Line options={options} data={data} />
                </div>
            </div>
        </section>
    );
};

export default SectionStatistic;
