import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faChalkboardUser, faChevronDown, faChevronUp, faGem, faHeadset, faTicket, faTimes, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import { foryoumenus, learnmenus } from "./sidebarmenus";
import { userRole } from "../../../utils/config";

const SidebarMobile = () => {
    const [showDropdown, setShowDropdown] = useState(true);

    return (
        <>
            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel" style={{ maxWidth: '75%' }}>
                <div className="offcanvas-header">
                    <a className="navbar-brand position-relative my-auto" href={process.env.REACT_APP_URL}>
                        <img height={35} width={'auto'} src="/assets/img/codepolitan-logo.png" alt="Codepolitan" />
                    </a>
                    <a className="btn btn-white shadow-none px-0 d-xl-none my-auto" data-bs-toggle="offcanvas" href="#offcanvasSidebar" role="button" aria-controls="offcanvasSidebar">
                        <FontAwesomeIcon size="lg" className="text-muted" icon={faTimes} />
                    </a>
                </div>
                <div className="offcanvas-body">
                    <div className="overflow-auto">
                        <nav className="nav nav-pills list-group flex-column border-0">
                            <p className="text-uppercase text-muted fw-bolder"><small>Main Menu</small></p>
                            {learnmenus.map((item, index) => {
                                return (
                                    <SidebarItem
                                        key={index}
                                        title={item.title}
                                        link={item.link}
                                        icon={item.icon}
                                        isNew={item.isNew}
                                        mobile
                                    />
                                );
                            })}

                            {/* For You */}
                            <li onClick={() => setShowDropdown(!showDropdown)} className={`nav-link ${showDropdown ? 'bg-light' : 'mb-2'} text-muted d-flex`} role="button">
                                <FontAwesomeIcon className={`${showDropdown && 'text-primary'} me-2 my-auto`} icon={faGem} />
                                <span>For You</span>
                                <FontAwesomeIcon className="text-muted ms-auto my-auto" icon={showDropdown ? faChevronUp : faChevronDown} />
                            </li>
                            {showDropdown ? (
                                <ul className="nav nav-pills list-group flex-column bg-light mb-3">
                                    {foryoumenus.map((item, index) => {
                                        return (
                                            <div data-bs-dismiss="offcanvas" key={index}>
                                                <SidebarItem
                                                    title={item.title}
                                                    link={item.link}
                                                    icon={item.icon}
                                                    isNew={item.isNew}
                                                />
                                            </div>
                                        );
                                    })}
                                </ul>
                            ) : null}
                            {/* End of For You */}

                            <SidebarItem
                                title="For Mentor"
                                link={userRole === '1' || userRole === '10' ? '/mentor' : '/join-mentor'}
                                icon={faChalkboardUser}
                                mobile
                            />
                            {/* {userRole === '1' || userRole === '12' ? (
                                <SidebarItem
                                    title="For Affiliator"
                                    link="/affiliator"
                                    icon={faLink}
                                />
                            ) : null} */}
                        </nav>
                        <hr />

                        <div className="d-grid gap-2">
                            <Link className="btn btn-primary btn-sm text-white shadow-none" to="/user/redeem">
                                <div data-bs-dismiss="offcanvas">
                                    <FontAwesomeIcon className="me-2" icon={faTicket} />
                                    Redeem Voucher
                                </div>
                            </Link>
                            <a className="btn btn-outline-secondary btn-sm shadow-none" href="https://t.me/codepolitan/17139" target="_blank" rel="noopener noreferrer">
                                <div data-bs-dismiss="offcanvas">
                                    <FontAwesomeIcon className="me-2" icon={faTelegram} />
                                    Join Community
                                </div>
                            </a>
                            <Link className="btn btn-outline-secondary btn-sm shadow-none" to={`${process.env.REACT_APP_URL}/faq`}>
                                <div data-bs-dismiss="offcanvas">
                                    <FontAwesomeIcon className="me-2" icon={faHeadset} />
                                    Need Help?
                                </div>
                            </Link>
                            <button type="button" className="btn btn-outline-secondary btn-sm shadow-none" data-bs-toggle="modal" data-bs-target="#askMentor">
                                <FontAwesomeIcon className="me-2" icon={faUserGraduate} />
                                Tanya Mentor
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarMobile;
