import { faRedo, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { countRates, getPercentageFromFeedback } from "../../../../utils/helper";
import { useState } from "react";
import { Rating } from 'react-simple-star-rating';

const SectionTestimonials = () => {
    const [limitFeedback, setLimitFeedback] = useState(5);

    const { courseFeedbacks, loadingFeedback } = useSelector((state) => ({ ...state.feedbacks }));

    const ratingValue = countRates(courseFeedbacks);

    return (
        <section className="card mb-3">
            <div className="card-body text-muted">
                <h5 className="mb-3"><FontAwesomeIcon className="text-primary me-2" icon={faStar} />Testimoni</h5>

                <div className="row my-3">
                    <div className="col-lg-3 text-center">
                        <div className="display-1">{ratingValue ? ratingValue.toFixed(1) : 0}</div>
                        <div className="my-2">
                            <Rating initialValue={Math.floor(ratingValue) || 0} size={25} readonly />
                        </div>
                        <p className="fs-6">({courseFeedbacks?.length || 0} reviews)</p>
                    </div>
                    <div className="col-lg-9">
                        <div className="row my-3">
                            <div className="col-auto text-end">
                                <span className="me-2">5 Bintang</span>
                            </div>
                            <div className="col-7 p-0 pt-2">
                                <div className="progress" style={{ height: '10px' }}>
                                    <div style={{ width: `${getPercentageFromFeedback(courseFeedbacks, 5) || 0}%` }} className="progress-bar bg-warning rounded-pill" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </div>
                            <div className="col-auto">
                                <span>{getPercentageFromFeedback(courseFeedbacks, 5) || 0}%</span>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-auto text-end">
                                <span className="me-2">4 Bintang</span>
                            </div>
                            <div className="col-7 p-0 pt-2">
                                <div className="progress" style={{ height: '10px' }}>
                                    <div style={{ width: `${getPercentageFromFeedback(courseFeedbacks, 4) || 0}%` }} className="progress-bar bg-warning rounded-pill" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <span>{getPercentageFromFeedback(courseFeedbacks, 4) || 0}%</span>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-auto text-end">
                                <span className="me-2">3 Bintang</span>
                            </div>
                            <div className="col-7 p-0 pt-2">
                                <div className="progress" style={{ height: '10px' }}>
                                    <div style={{ width: `${getPercentageFromFeedback(courseFeedbacks, 3) || 0}%` }} className="progress-bar bg-warning rounded-pill" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <span>{getPercentageFromFeedback(courseFeedbacks, 3) || 0}%</span>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-auto text-end">
                                <span className="me-2">2 Bintang</span>
                            </div>
                            <div className="col-7 p-0 pt-2">
                                <div className="progress" style={{ height: '10px' }}>
                                    <div style={{ width: `${getPercentageFromFeedback(courseFeedbacks, 2) || 0}%` }} className="progress-bar bg-warning rounded-pill" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <span>{getPercentageFromFeedback(courseFeedbacks, 2) || 0}%</span>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-auto text-end">
                                <span className="me-2">1 Bintang</span>
                            </div>
                            <div className="col-7 p-0 pt-2">
                                <div className="progress" style={{ height: '10px' }}>
                                    <div style={{ width: `${getPercentageFromFeedback(courseFeedbacks, 1) || 0}%` }} className="progress-bar bg-warning rounded-pill" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <span>{getPercentageFromFeedback(courseFeedbacks, 1) || 0}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {!loadingFeedback && !courseFeedbacks.status && courseFeedbacks?.map((feedback, index) => {
                            return (
                                <div className="card mb-3" key={index}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <img width={50} height={50} className="rounded-circle objectfit-cover" src={feedback.avatar || "https://image.web.id/images/avatar4.jpg" || "/assets/img/placeholder-avatar.png"} alt="Avatar" />
                                            </div>
                                            <div className="col-9">
                                                <h6 className="mb-2"><strong>{feedback.name || 'Tidak ada nama'}</strong></h6>
                                                <p className="text-muted">{feedback.comment || (<i>Tidak ada komentar</i>)}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="float-end">
                                                    <Rating initialValue={feedback.rate || 0} size={25} readonly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }).slice(0, limitFeedback)}
                        {courseFeedbacks?.length > limitFeedback && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimitFeedback(limitFeedback + 10)} type="button" className="btn"><FontAwesomeIcon icon={faRedo} /> Muat lainnya</button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </section>
    );
};

export default SectionTestimonials;
