import { useState, useEffect } from 'react';
import { faEdit, faLightbulb, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../../../components/global/Layout/Layout";
import { getMentorCourse, updateMentorCourse } from '../../../features/mentor/mentorCourseSlice';
import { useForm } from "react-hook-form";
import { removeHTMLTags, uploadImage } from '../../../utils/helper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import slugify from 'react-slugify';
import { InputTags } from 'react-bootstrap-tagsinput';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const EditCourseOverview = () => {
    const { id } = useParams();

    const MySwal = withReactContent(Swal);

    const { mentorCourse, loadingMentorCourse } = useSelector((state) => ({ ...state.mentorCourse }));
    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();

    const watchCourseTitleValue = watch('courseTitle');

    const [thumbnail, setThumbnail] = useState("");
    const [thumbnailLoading, setThumbnailLoading] = useState(false);
    const [disabledThumbnail, setDisabledThumbnail] = useState(false);
    const [errorUploaderThumbnail, setErrorUploaderThumbnail] = useState(false)

    const [cover, setCover] = useState("");
    const [coverLoading, setCoverLoading] = useState(false);
    const [disabledCover, setDisabledCover] = useState(false);
    const [errorUploaderCover, setErrorUploaderCover] = useState(false);
    const [tags, setTags] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getMentorCourse(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!loadingMentorCourse && mentorCourse) {
            let course = mentorCourse?.result;

            reset({
                courseTitle: course?.course_title,
                courseSlug: course?.slug,
                label: course?.label,
                description: course?.description,
                longDescription: course?.long_description,
                level: course?.level,
                totalModule: course?.total_module,
                totalTime: course?.total_time,
                author: course?.author,
                authorContact: course?.author_contact,
                authorEmail: course?.author_email,
                sourcecodeUrl: course?.sourcecode_url,
                previewVideo: course?.preview_video,
                previewVideoCover: course?.preview_video_cover,
            });
            setThumbnail(course?.thumbnail);
            setCover(course?.cover);
            setTags(mentorCourse?.tags);
        };
    }, [loadingMentorCourse, mentorCourse, reset]);

    useEffect(() => {
        setValue('courseSlug', slugify(watchCourseTitleValue));
    }, [setValue, watchCourseTitleValue]);

    const onSubmitUpdateCourse = (data) => {

        // Check uploader image
        if (thumbnail === "") {
            setErrorUploaderThumbnail(true);
        }
        if (cover === "") {
            setErrorUploaderCover(true); return;
        }

        data.id = id;
        data.thumbnail = thumbnail;
        data.cover = cover;
        data.tags = tags;

        dispatch(updateMentorCourse(data)).then((response) => {
            if (response.payload.status === 'success') {
                // Alert Success
                MySwal.fire({
                    title: <strong>Success</strong>,
                    text: removeHTMLTags(response.payload.message),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                }).then(
                    navigate(`/mentor/courses/edit/curriculum/${id}`)
                );
            } else {
                // Alert Failed
                MySwal.fire({
                    title: <strong>Failed</strong>,
                    text: removeHTMLTags(response.payload.message),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Edit Course</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <h3 className="section-title mb-5">Edit Course</h3>

                                <ul className="nav nav-pills text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4 active" to={`/mentor/courses/edit/overview/${id}`}>Overview</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/courses/edit/curriculum/${id}`}>Curriculum</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/courses/edit/pricing/${id}`}>Pricing</Link>
                                    </li>
                                </ul>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="section-title my-auto"><FontAwesomeIcon icon={faEdit} /> Course Property</h5>
                                    </div>
                                </div>

                                <div className="row my-4">
                                    <div className="col-lg-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={handleSubmit(onSubmitUpdateCourse)}>
                                                    <div className="mb-3">
                                                        <label htmlFor="courseTitle" className="form-label fw-bolder">Course Title</label>
                                                        <input
                                                            name="courseTitle"
                                                            className="form-control"
                                                            placeholder="Course title"
                                                            {...register("courseTitle", { required: true, maxLength: 50 })}
                                                        />
                                                        {errors.courseTitle && errors.courseTitle.type === 'required' && <span className="text-danger">This field is required</span>}
                                                        {errors.courseTitle && errors.courseTitle.type === 'maxLength' && <span className="text-danger">Max length exceeded</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="courseSlug" className="form-label fw-bolder">Course Slug</label>
                                                        <input
                                                            readOnly
                                                            name="courseSlug"
                                                            className="form-control"
                                                            placeholder="course-slug"
                                                            {...register("courseSlug")}
                                                        />
                                                        {errors.courseSlug && errors.courseSlug.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="label" className="form-label fw-bolder">Label</label>
                                                        <input
                                                            name="label"
                                                            className="form-control"
                                                            placeholder="Label"
                                                            {...register("label", { required: true, maxLength: 50 })}
                                                        />
                                                        {errors.label && errors.label.type === 'required' && <span className="text-danger">This field is required</span>}
                                                        {errors.label && errors.label.type === 'maxLength' && <span className="text-danger">Max length exceeded</span>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="description" className="form-label fw-bolder">Description</label>
                                                        <textarea
                                                            name="description"
                                                            className="form-control"
                                                            rows="4"
                                                            placeholder="Masukkan deskripsi singkat..."
                                                            {...register("description", { required: true })}
                                                        />
                                                        {errors.description && errors.description.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="longDescription" className="form-label fw-bolder">Long Description</label>
                                                        <textarea
                                                            name="longDescription"
                                                            className="form-control"
                                                            rows="4"
                                                            placeholder="Masukkan detail deskripsi..."
                                                            {...register("longDescription", { required: true })}
                                                        />
                                                        {errors.longDescription && errors.longDescription.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-4 mb-3">
                                                            <label htmlFor="level" className="form-label fw-bolder">Level</label>
                                                            <select name="level" id="status" className="form-select" {...register("level", { required: true })}>
                                                                <option value="beginner">Beginner</option>
                                                                <option value="intermedate">Intermedate</option>
                                                                <option value="advance">Advance</option>
                                                            </select>
                                                            {errors.level && errors.level.type === 'required' && <span className="text-danger">This field is required</span>}
                                                        </div>
                                                        <div className="col-lg-4 mb-3">
                                                            <label htmlFor="totalModule" className="form-label fw-bolder">Total Module</label>
                                                            <input
                                                                name="totalModule"
                                                                className="form-control"
                                                                placeholder="Total Module"
                                                                {...register("totalModule", { required: true })}
                                                            />
                                                            {errors.totalModule && errors.totalModule.type === 'required' && <span className="text-danger">This field is required</span>}
                                                        </div>
                                                        <div className="col-lg-4 mb-3">
                                                            <label htmlFor="totalTime" className="form-label fw-bolder">Total Time</label>
                                                            <input
                                                                name="totalTime"
                                                                className="form-control"
                                                                placeholder="Total Time"
                                                                {...register("totalTime", { required: true })}
                                                            />
                                                            {errors.totalTime && errors.totalTime.type === 'required' && <span className="text-danger">This field is required</span>}
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="thumbnail" className="form-label fw-bolder">Thumbnail</label>
                                                        <input className="form-control" type="hidden" value={thumbnail} />
                                                        <input className="form-control" onChange={(e) => { uploadImage(e, setThumbnail, setThumbnailLoading); setDisabledThumbnail(true); setErrorUploaderThumbnail(false) }} type="file" id="thumbnail" hidden />
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => { setThumbnail(e.target.value); setErrorUploaderThumbnail(false) }}
                                                                disabled={disabledThumbnail}
                                                                className="form-control"
                                                                value={thumbnail}
                                                            />
                                                            <label className="btn btn-primary rounded" htmlFor="thumbnail"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                                        </div>
                                                        {errorUploaderThumbnail && <span className="text-danger">This field is required</span>}
                                                        {thumbnailLoading && (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                                <small className="text-muted">Please wait....</small>
                                                            </>
                                                        )}
                                                        {!thumbnailLoading && thumbnail && (
                                                            <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={thumbnail || "/assets/img/placeholder.jpg"} alt="Thumbnail" />
                                                        )}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="cover" className="form-label fw-bolder">Cover</label>
                                                        <input className="form-control" type="hidden" value={cover} />
                                                        <input className="form-control" onChange={(e) => { uploadImage(e, setCover, setCoverLoading); setDisabledCover(true); setErrorUploaderCover(false) }} type="file" id="cover" hidden />
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => { setCover(e.target.value); setErrorUploaderCover(false) }}
                                                                disabled={disabledCover}
                                                                className="form-control"
                                                                value={cover}
                                                            />
                                                            <label className="btn btn-primary rounded" htmlFor="cover"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                                        </div>
                                                        {errorUploaderCover && <span className="text-danger">This field is required</span>}
                                                        {coverLoading && (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                                <small className="text-muted">Please wait....</small>
                                                            </>
                                                        )}
                                                        {!coverLoading && cover && (
                                                            <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={cover || "/assets/img/placeholder.jpg"} alt="Cover" />
                                                        )}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="author" className="form-label fw-bolder">Author</label>
                                                        <input
                                                            name="author"
                                                            className="form-control"
                                                            placeholder="Author"
                                                            {...register("author", { required: true })}
                                                        />
                                                        {errors.author && errors.author.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="authorContact" className="form-label fw-bolder">Author Contact</label>
                                                        <input
                                                            name="authorContact"
                                                            className="form-control"
                                                            placeholder="Author Contact"
                                                            {...register("authorContact", { required: true })}
                                                        />
                                                        {errors.authorContact && errors.authorContact.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="authorEmail" className="form-label fw-bolder">Author Email</label>
                                                        <input
                                                            name="authorEmail"
                                                            className="form-control"
                                                            placeholder="Author Email"
                                                            {...register("authorEmail", { required: true, pattern: /^\S+@\S+$/i })}
                                                        />
                                                        {errors.authorEmail && errors.authorEmail.type === 'required' && <span className="text-danger">This field is required</span>}
                                                        {errors.authorEmail && errors.authorEmail.type === 'pattern' && <span className="text-danger">Please enter a correct email format</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="sourcecodeUrl" className="form-label fw-bolder">Sourcecode Url</label>
                                                        <input
                                                            name="sourcecodeUrl"
                                                            className="form-control"
                                                            placeholder="Sourcecode Url"
                                                            {...register("sourcecodeUrl", { required: true })}
                                                        />
                                                        {errors.sourcecodeUrl && errors.sourcecodeUrl.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="previewVideo" className="form-label fw-bolder">Preview Video Url</label>
                                                        <input
                                                            name="previewVideo"
                                                            className="form-control"
                                                            placeholder="Preview Video Url"
                                                            {...register("previewVideo", { required: true })}
                                                        />
                                                        {errors.previewVideo && errors.previewVideo.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="previewVideoCover" className="form-label fw-bolder">Preview Video Cover</label>
                                                        <input
                                                            name="previewVideoCover"
                                                            className="form-control"
                                                            placeholder="Preview Video Cover"
                                                            {...register("previewVideoCover", { required: true })}
                                                        />
                                                        {errors.previewVideoCover && errors.previewVideoCover.type === 'required' && <span className="text-danger">This field is required</span>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="tags" className="form-label fw-bolder mb-0">Tags</label>
                                                        <p className="small text-muted">Gunakan spasi atau koma untuk menambah tag</p>
                                                        <div className="input-group">
                                                            <InputTags
                                                                name="tags"
                                                                className="form-control shadow-none p-0"
                                                                placeholder="Add tags..."
                                                                values={tags}
                                                                onTags={(tag) => setTags(tag.values)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="float-end my-3">
                                                        <button type="reset" className="btn">Reset</button>
                                                        <button type="submit" className="btn btn-primary btn-rounded">Save & continue</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-none d-lg-block">
                                        <div className="sticky-top" style={{ top: '100px' }}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <FontAwesomeIcon className="text-primary me-3" size="3x" icon={faLightbulb} />
                                                        <h5 className="text-muted my-auto">Pro tip:</h5>
                                                    </div>
                                                    <p className="text-muted my-3">Isi semua field yang dibutuhkan dengan informasi yang lengkap agar course yang kamu buat dapat lebih mudah dicari</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default EditCourseOverview;
