import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserPoint, getLeaderboard } from '../../../features/user/leaderboardSlice';
import ItemLeaderboard from '../../../components/learn/leaderboard/ItemLeaderboard/ItemLeaderboard';
import SkeletonItemLeaderboard from '../../../components/skeletons/SkeletonItemLeaderboard/SkeletonItemLeaderboard';

const MyCourses = () => {
    const { leaderboard, loadingLeaderboard, currentUserPoint } = useSelector(state => state.leaderboards);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLeaderboard('week'));
        dispatch(getCurrentUserPoint('week'));
    }, [dispatch]);

    let position = leaderboard?.findIndex(item => item.name === currentUserPoint?.name);

    return (
        <>
            <Helmet>
                <title>Leaderboard</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h2 className="section-title h4">Leaderboard</h2>
                            </div>
                            <div className="col-auto">
                                <div className="bg-white rounded p-0">
                                    <ul className="nav nav-pills" id="navLeaderboard" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                onClick={() => {
                                                    dispatch(getLeaderboard('week'));
                                                    dispatch(getCurrentUserPoint('week'));
                                                }}
                                                className="nav-link active"
                                                id="pills-weekly-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-weekly"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-weekly"
                                                aria-selected="true"
                                            >
                                                weekly
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                onClick={() => {
                                                    dispatch(getLeaderboard('month'));
                                                    dispatch(getCurrentUserPoint('month'));
                                                }}
                                                className="nav-link"
                                                id="pills-monthly-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-monthly"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-monthly"
                                                aria-selected="false"
                                            >
                                                monthly
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                onClick={() => {
                                                    dispatch(getLeaderboard('overall'));
                                                    dispatch(getCurrentUserPoint('overall'));
                                                }}
                                                className="nav-link"
                                                id="pills-overall-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-overall"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-overall"
                                                aria-selected="false"
                                            >
                                                overall
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="card my-5">
                                <div className="card-body">
                                    <ul className="list-group list-group-flush overflow-auto" style={{ height: '500px' }}>
                                        {loadingLeaderboard && [1, 2, 3, 4, 5].map(item => (
                                            <SkeletonItemLeaderboard key={item} />
                                        ))}
                                        {!loadingLeaderboard && leaderboard?.map((leaderboard, index) => {
                                            return (
                                                <ItemLeaderboard
                                                    key={index}
                                                    position={index + 1}
                                                    avatar={leaderboard.avatar}
                                                    name={leaderboard.name}
                                                    username={leaderboard.username}
                                                    headline={leaderboard.jobs}
                                                    rank={leaderboard.rank}
                                                    rankPicture={leaderboard.rank_picture}
                                                    point={leaderboard.total_point}
                                                />
                                            );
                                        })}
                                    </ul>
                                    {!loadingLeaderboard && currentUserPoint && (
                                        <ul className="list-group list-group-flush overflow-auto">
                                            <ItemLeaderboard
                                                position={position + 1}
                                                avatar={currentUserPoint.avatar}
                                                name={currentUserPoint.name}
                                                username={currentUserPoint.username}
                                                headline={currentUserPoint.jobs}
                                                rank={currentUserPoint.rank}
                                                rankPicture={currentUserPoint.rank_picture}
                                                point={currentUserPoint.total_point}
                                                bgBlue
                                            />
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default MyCourses;
