import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteEducation, getEducation, postEducation, updateEducation } from "../../../features/user/educationsSlice";
import { selectYear } from "../../../utils/helper";
import Input from "../../global/Inputs/Input/Input";
import InputSelect from "../../global/Inputs/InputSelect/InputSelect";
import InputTextArea from "../../global/Inputs/InputTextArea/InputTextArea";
import Modal from "../../global/Modal/Modal";

const SectionEducations = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { educations, loadingEducation } = useSelector((state) => ({ ...state.education }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [isEdit, setIsEdit] = useState(false);
    const [limit, setLimit] = useState(3);

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEdit) {
            reset({
                id: '',
                category: '',
                graduated_at: '',
                description: '',
                level: '',
                major: '',
                institution: '',
                ipk: ''
            });
        }
    }, [isEdit, reset]);

    const onSubmit = (payload) => {
        dispatch(postEducation(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                reset();
            });
        });
    };

    const onEdit = (id) => {
        setIsEdit(true)
        dispatch(getEducation(id)).then((result) => {
            reset({
                id: id,
                category: result.payload.category,
                graduated_at: result.payload.graduated_at,
                description: result.payload.description,
                level: result.payload.level,
                major: result.payload.major,
                institution: result.payload.institution,
                ipk: result.payload.ipk
            });
        });
    };

    const onUpdate = (payload) => {
        dispatch(updateEducation(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    const onDelete = (id) => {
        MySwal.fire({
            title: <strong>Delete?</strong>,
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteEducation(id)).then((result) => {
                    MySwal.fire({
                        title: <strong>{result.payload.status}</strong>,
                        text: result.payload.message,
                        icon: result.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            }
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">Educations</h5>
                        {myUser?.username === user?.username ? (
                            <button onClick={() => reset()} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#educationModal">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        ) : null}
                    </div>
                    {myUser?.username === user?.username ? (
                        <div className="card-body">
                            {loadingEducation && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingEducation && !educations ? (<p>No education found. Please add your educations</p>) : null}
                            {!loadingEducation ? (
                                <ul className="list-group list-group-flush" id="educations">
                                    {educations?.map((education, index) => {
                                        return (
                                            <li className="list-group-item px-0 py-3 text-muted" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col my-auto">
                                                        <h5 className="h6">{education.major}</h5>
                                                        <p><small>{education.institution}, {education.graduated_at}</small></p>
                                                    </div>
                                                    <div className="col-auto my-auto">
                                                        <button onClick={() => onEdit(education.id)} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#educationModal">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button onClick={() => onDelete(education.id)} type="button" className="btn text-muted">
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingEducation && educations?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="card-body">
                            {loadingUser && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingUser && user?.educations?.length < 1 ? (<p>No education found.</p>) : null}
                            {!loadingUser ? (
                                <ul className="list-group list-group-flush" id="educations">
                                    {user?.educations?.map((education, index) => {
                                        return (
                                            <li className="list-group-item px-0 py-3 text-muted" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col my-auto">
                                                        <h5 className="h6">{education.major}</h5>
                                                        <p><small>{education.institution}, {education.graduated_at}</small></p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingUser && user?.educations?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
            <Modal
                id="educationModal"
                title={!isEdit ? 'Add education' : 'Edit education'}
                onCloseModal={() => setIsEdit(false)}
                backdrop="static"
            >
                <form onSubmit={handleSubmit(!isEdit ? onSubmit : onUpdate)}>
                    <div className="mb-3">
                        <InputSelect
                            name="level"
                            label="Level"
                            isRequired
                            register={register}
                            errors={errors}
                        >
                            {[
                                'Strata 3',
                                'Strata 2',
                                'Strata 1',
                                'Diploma 4',
                                'Diploma 3',
                                'Diploma 2',
                                'Diploma 1',
                                'SMA/SMK/Sederajat'

                            ].map((option, index) => {
                                return (
                                    <option value={option} key={index}>{option}</option>
                                );
                            })}
                        </InputSelect>
                    </div>
                    <div className="mb-3">
                        <InputSelect
                            name="category"
                            label="Category"
                            isRequired
                            register={register}
                            errors={errors}
                        >
                            {['Formal', 'Nonformal'].map((option, index) => {
                                return (
                                    <option value={option} key={index}>{option}</option>
                                );
                            })}
                        </InputSelect>
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="institution"
                            label="Institution"
                            placeholder="ex. Universitas Codepolitan"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <InputTextArea
                            name="description"
                            label="Description"
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="major"
                            label="Major"
                            placeholder="ex. Teknik Informatika"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <InputSelect
                                name="graduated_at"
                                label="Graduated at"
                                isRequired
                                register={register}
                                errors={errors}
                            >
                                {selectYear.map((option, index) => {
                                    return (
                                        <option value={option} key={index}>{option}</option>
                                    );
                                })}
                            </InputSelect>
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="text"
                                name="ipk"
                                label="IPK/Nilai Akhir (Opsional)"
                                placeholder="ex. 3.70"
                                register={register}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button onClick={() => setIsEdit(false)} type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingEducation}>
                            {loadingEducation && 'Please wait...'}
                            {!loadingEducation && isEdit && 'Update'}
                            {!loadingEducation && !isEdit && 'Submit'}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionEducations;