import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faChalkboardUser, faChevronDown, faChevronUp, faGem, faHeadset, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import SidebarItem from "./SidebarItem";
import { foryoumenus, learnmenus } from "./sidebarmenus";
import { userRole } from "../../../utils/config";

const Sidebar = () => {
    const [showDropdown, setShowDropdown] = useState(true);

    return (
        <div className="position-sticky" style={{ top: '25px' }}>
            <a className="navbar-brand position-relative" href={process.env.REACT_APP_URL}>
                <img height={35} src="/assets/img/codepolitan-logo.png" alt="Codepolitan" />
            </a>
            <div className="overflow-auto mt-3" style={{ maxHeight: '700px' }}>
                <nav className="nav nav-pills list-group flex-column border-0 my-3">
                    <p className="text-uppercase text-muted fw-bolder"><small>Main Menu</small></p>
                    {learnmenus.map((item, index) => {
                        return (
                            <SidebarItem
                                key={index}
                                title={item.title}
                                link={item.link}
                                icon={item.icon}
                                isNew={item.isNew}
                            />
                        );
                    })}

                    {/* For You */}
                    <li onClick={() => setShowDropdown(!showDropdown)} className={`nav-link ${showDropdown ? 'bg-light' : 'mb-2'} text-muted d-flex`} role="button">
                        <FontAwesomeIcon className={`${showDropdown && 'text-primary'} me-2 my-auto`} icon={faGem} />
                        <span>For You</span>
                        <FontAwesomeIcon className="text-muted ms-auto my-auto" icon={showDropdown ? faChevronUp : faChevronDown} />
                    </li>
                    {showDropdown ? (
                        <ul className="nav nav-pills list-group flex-column bg-light mb-3">
                            {foryoumenus.map((item, index) => {
                                return (
                                    <SidebarItem
                                        key={index}
                                        title={item.title}
                                        link={item.link}
                                        icon={item.icon}
                                        isNew={item.isNew}
                                    />
                                );
                            })}
                        </ul>
                    ) : null}
                    {/* End of For You */}

                    <SidebarItem
                        title="For Mentor"
                        link={userRole === '1' || userRole === '10' ? '/mentor' : '/join-mentor'}
                        icon={faChalkboardUser}
                    />
                    {/* {userRole === '1' || userRole === '12' ? (
                        <SidebarItem
                            title="For Affiliator"
                            link="/affiliator"
                            icon={faLink}
                        />
                    ) : null} */}
                </nav>
                <hr />

                <div className="d-grid gap-2">
                    <a className="btn btn-outline-secondary btn-sm shadow-none" href="https://t.me/codepolitan/17139" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="me-2" icon={faTelegram} />
                        Join Community
                    </a>
                    <a className="btn btn-outline-secondary btn-sm shadow-none" href="https://api.whatsapp.com/send?phone=6285174469460" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="me-2" icon={faHeadset} />
                        Hubungi CS
                    </a>
                    <button type="button" className="btn btn-outline-secondary btn-sm shadow-none" data-bs-toggle="modal" data-bs-target="#askMentor">
                        <FontAwesomeIcon className="me-2" icon={faUserGraduate} />
                        Tanya Mentor
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
