import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from "remark-gfm";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Timer from "../../../components/exercises/Timer/Timer";
import { CodeBlock } from "../../../components/global/CodeBlock";
import Footer from "../../../components/global/Footer/Footer";
import SidebarCollapseExercise from "../../../components/global/SidebarCollapseExercise/SidebarCollapseExercise";
import { getQuizzesByGroup, postAnswers } from "../../../features/quiz/quizSlice";

const Exercise = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answer, setAnswer] = useState([]);
    const [expired, setExpired] = useState(false);

    const [inputData, setInputData] = useState('');

    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const { slug } = useParams();
    const dispatch = useDispatch()
    const { quizzesByGroup, loadingQuiz } = useSelector((state) => ({ ...state.quiz }));

    useEffect(() => {
        dispatch(getQuizzesByGroup(slug));
    }, [dispatch, slug]);

    const quiz = quizzesByGroup;

    const onExitExercise = () => {
        MySwal.fire({
            icon: 'question',
            title: 'Keluar?',
            confirmButtonText: 'Ya, Keluar',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonColor: '#14a7a0',
            text: 'Apakah kamu yakin untuk keluar exercise ini?'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/exercises/overview');
            };
        })
    };

    const onSelectOption = ({ questionId, optionId }) => {
        if (currentQuestion < quiz?.questions?.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        };

        setAnswer(state => {
            const currentAnswer = [...state],
                index = currentAnswer.findIndex(search => search.question_id === questionId);

            if (index > -1) {
                // ketemu questionId yang sama sebelumnya, contoh pengen ganti option_id, dari parameter
                currentAnswer[index].option_id = optionId;
            } else {
                // gak ketemu questionId dari parameter, contoh pengen tambah item array
                currentAnswer.push({
                    question_id: questionId,
                    option_id: optionId,
                });
            }

            return currentAnswer // ngeset nilai baru
        });
    };

    const onSubmitAnswer = () => {
        if (!expired) {
            MySwal.fire({
                icon: 'question',
                title: 'Selesaikan?',
                confirmButtonText: 'Selesai',
                showDenyButton: true,
                denyButtonText: 'Batal',
                confirmButtonColor: '#14a7a0',
                text: 'Apakah kamu yakin untuk menyelesaikan exercise ini?'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(postAnswers({
                        groupId: quiz?.detail?.id,
                        duration: quiz?.detail?.duration,
                        sessionId: '',
                        answer: answer
                    })).then((results) => {
                        MySwal.fire({
                            icon: results.payload.score >= quiz?.detail?.kkm ? 'success' : 'error',
                            title: results.payload.score >= quiz?.detail?.kkm ? 'Selamat! Kamu Lulus' : 'Maaf, Kamu Belum Lulus',
                            confirmButtonText: results.payload.score >= quiz?.detail?.kkm ? 'Tutup' : 'Coba Lagi',
                            confirmButtonColor: '#14a7a0',
                            html: <p>Score kamu <span className={results.payload.score >= quiz?.detail?.kkm ? "fw-bolder text-primary" : "fw-bolder text-danger"}>{results.payload.score}</span> dari KKM <span className="fw-bolder">{quiz?.detail?.kkm}</span></p>,
                        }).then((result => {
                            if (result.isConfirmed) {
                                navigate(`/exercises/detail/${quiz?.detail?.slug}`);
                            }
                        }))
                    })
                }
            })
        } else {
            dispatch(postAnswers({
                groupId: quiz?.detail?.id,
                duration: quiz?.detail?.duration,
                sessionId: '',
                answer: answer
            })).then((results) => {
                MySwal.fire({
                    icon: results.payload.score >= quiz?.detail?.kkm ? 'success' : 'error',
                    title: results.payload.score >= quiz?.detail?.kkm ? 'Selamat! Kamu Lulus' : 'Maaf, Kamu Belum Lulus',
                    confirmButtonText: results.payload.score >= quiz?.detail?.kkm ? 'Tutup' : 'Coba Lagi',
                    confirmButtonColor: '#14a7a0',
                    html: <p>Score kamu <span className={results.payload.score >= quiz?.detail?.kkm ? "fw-bolder text-primary" : "fw-bolder text-danger"}>{results.payload.score}</span> dari KKM <span className="fw-bolder">{quiz?.detail?.kkm}</span></p>,
                }).then((result => {
                    if (result.isConfirmed) {
                        navigate(`/exercises/detail/${quiz?.detail?.slug}`);
                    }
                }))
            })
        }
    };

    const onKeyUp = (event) => {
        setInputData(event.target.value);
    };

    useEffect(() => {
        if (quiz?.questions && quiz?.questions[currentQuestion]?.options?.length === 1) {
            const myInputs = document.querySelector('#my-inputs');
            const input = myInputs?.querySelectorAll('input');
            if (input && input.length > 0) {
                input[0].addEventListener('keyup', onKeyUp);
            };

            if (inputData?.length > 0) {
                input[0].value = inputData;
            }
        }
    }, [currentQuestion, inputData, inputData?.length, quiz?.questions]);

    const checkAnswer = () => {
        let questionId = quiz?.questions[currentQuestion]?.id;
        let optionId = quiz?.questions[currentQuestion]?.options[0]?.id;

        if (inputData !== quiz?.questions[currentQuestion]?.options[0]?.content) {
            optionId = 0
        };

        onSelectOption({
            questionId,
            optionId
        });
    };

    // Force submit if the time is up
    useEffect(() => {
        if (expired) {
            onSubmitAnswer();
        };
    });

    return (
        <>
            <Helmet>
                <title>{quiz?.detail?.title}</title>
            </Helmet>
            <main id="main">
                <SidebarCollapseExercise
                    isOpen={sidebarOpen}
                    onClickToggle={() => setSidebarOpen(!sidebarOpen)}
                    questions={quiz?.questions}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    answer={answer}
                />

                <div className={sidebarOpen ? "content bg-light open overflow-auto" : "content bg-light overflow-auto"}>
                    <div className="container p-4">
                        <section className="my-3">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h1 className="section-title h3">{quiz?.detail?.title}</h1>
                                </div>
                                <div className="col-auto">
                                    <button onClick={onExitExercise} type="button" className="btn link text-dark shadow-none">
                                        <FontAwesomeIcon size="lg" icon={faTimes} />
                                    </button>
                                </div>
                            </div>
                        </section>
                        <section className="my-5">
                            <div className="card border-none">
                                <div className="card-body p-5">
                                    <div className="row justify-content-between">
                                        <div className="col-auto mb-4">
                                            <h4 className="section-title">Soal {currentQuestion + 1} / {quiz?.questions?.length || 1}</h4>
                                        </div>
                                        <div className="col-auto my-auto mb-4">
                                            <Timer
                                                duration={quiz?.detail?.duration}
                                                setExpired={setExpired}
                                            />
                                        </div>
                                    </div>
                                    {loadingQuiz ? (
                                        <div>
                                            <Skeleton className="mb-4" width="75%" />
                                            <ul className="list-group rounded-0">
                                                {[1, 2, 3, 4, 5].map(item => (
                                                    <li className="list-group-item py-3" key={item}>
                                                        <Skeleton width="40%" />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : null}
                                    {!loadingQuiz && quiz?.questions && quiz?.questions[currentQuestion]?.options?.length > 1 ? (
                                        <>
                                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: quiz?.questions[currentQuestion]?.content }} />
                                            <div className="list-group rounded-0">
                                                {quiz?.questions[currentQuestion]?.options?.map((option) => {
                                                    const currentAnswer = [...answer],
                                                        indexOption = currentAnswer.findIndex(search => search.option_id === option.id);

                                                    return (
                                                        <button
                                                            key={option.id}
                                                            onClick={() => onSelectOption({
                                                                questionId: quiz?.questions[currentQuestion]?.id,
                                                                optionId: option.id
                                                            })}
                                                            type="button"
                                                            className={answer[indexOption]?.option_id === option.id ?
                                                                "list-group-item list-group-item-action py-3 border border-3 border-primary bg-light-green"
                                                                : "list-group-item list-group-item-action py-3"
                                                            }
                                                        >
                                                            <ReactMarkdown
                                                                className="markdown"
                                                                remarkPlugins={[remarkGfm]}
                                                                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                                components={CodeBlock}
                                                            >
                                                                {option.content}
                                                            </ReactMarkdown>
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                            <div className="my-4 text-end">
                                                <button onClick={onSubmitAnswer} type="button" className="btn btn-primary btn-lg rounded-0" disabled={answer?.length < quiz?.questions?.length}>Selesaikan Exercise</button>
                                            </div>
                                        </>
                                    ) : null}
                                    {!loadingQuiz && quiz?.questions && quiz?.questions[currentQuestion]?.options?.length === 1 ? (
                                        <>
                                            <div className="bg-light text-muted w-100 p-3" style={{ minHeight: '300px' }}>
                                                <div id="my-inputs" dangerouslySetInnerHTML={{ __html: quiz?.questions ? quiz?.questions[currentQuestion]?.content : null }} />
                                            </div>
                                            <div className="row justify-content-between my-3">
                                                <div className="col-auto">
                                                    <button type="button" onClick={checkAnswer} className="btn btn-outline-primary btn-lg rounded-0" disabled={inputData?.length === 0}>Submit</button>
                                                </div>
                                                <div className="col-auto">
                                                    <button onClick={onSubmitAnswer} type="button" className="btn btn-primary btn-lg rounded-0" disabled={answer?.length < quiz?.questions?.length}>Selesaikan Exercise</button>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </main >
        </>
    );
};

export default Exercise;
