import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import CardSkeleton from '../../../global/Cards/CardSkeleton/CardSkeleton';
import { useSelector } from 'react-redux';
import CardEvent from '../../Card/CardEvent/CardEvent';

const SectionEvents = () => {
    const [limit, setLimit] = useState(12);

    const { events, loadingEvent } = useSelector((state) => ({ ...state.events }));

    return (
        <section className="section my-5">
            <h4 className="section-title">Events</h4>
            <p className="text-muted mb-5">Event yang bisa kamu ikutin buat nambah wawasan kamu</p>
            <div className="row">
                {
                    loadingEvent && [1, 2, 3, 4, 5, 6, 7, 8].map((index) => {
                        return (
                            <div className="col-sm-6 col-md-4 col-xl-3 p-2" key={index}>
                                <CardSkeleton />
                            </div>
                        )
                    })
                }
                {
                    !loadingEvent && events.length < 1
                        ? (<div className="text-center text-muted py-4 fw-bold bg-white">Nantikan event seru dan menarik dari Codepolitan!</div>)
                        : events.map((item, index) => {
                            return (
                                <div className="col-sm-6 col-md-4 col-xl-3 p-2" key={index}>
                                    <a className="link" href={`https://codepolitan.com/events/${item.slug}`} target="_blank noreferrer">
                                        <CardEvent
                                            type={item.webinar_type}
                                            thumbnail={item.featured_image}
                                            title={item.title}
                                            schedule={item.scheduled_at}
                                            location={item.location}
                                        />
                                    </a>
                                </div>
                            )
                        }).slice(0, limit)
                }
            </div>
            {events.length > limit && (
                <div className="text-center my-3">
                    <button onClick={() => setLimit(limit + 12)} className="btn btn-sm btn-outline-dark">
                        <FontAwesomeIcon icon={faArrowRotateRight} className="me-1" />
                        <span>Lihat Lainnya</span>
                    </button>
                </div>
            )}
        </section>
    );
};

export default SectionEvents;
