import { faMap, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import BannerHeader from '../../../components/global/Banner/BannerHeader';
import Layout from "../../../components/global/Layout/Layout";
import Modal from '../../../components/global/Modal/Modal';
import SectionRoadmaps from '../../../components/mentor/Sections/SectionRoadmaps';
import { getMentorCourses } from '../../../features/mentor/mentorCourseSlice';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, removeHTMLTags, uploadImage } from '../../../utils/helper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import slugify from 'react-slugify';
import { getMentorRoadmap, postMentorRoadmap } from '../../../features/mentor/mentorRoadmapSlice';
import { InputTags } from 'react-bootstrap-tagsinput';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const Roadmaps = () => {
    const MySwal = withReactContent(Swal);

    const { mentorRoadmaps, loadingMentorRoadmaps } = useSelector((state) => ({ ...state.mentorRoadmap }));

    const { register: registerRoadmap, watch: watchRoadmap, setValue: setValueRoadmap, handleSubmit: handleSubmitRoadmap, formState: { errors: errorsRoadmap } } = useForm();

    const watchRoadmapTitleValue = watchRoadmap('path_name');

    const [cover, setCover] = useState("");
    const [coverLoading, setCoverLoading] = useState(false);
    const [disabledCover, setDisabledCover] = useState(false);
    const [errorUploaderCover, setErrorUploaderCover] = useState(false)

    const [smallIcon, setSmallIcon] = useState("");
    const [smallIconLoading, setSmallIconLoading] = useState(false);
    const [disabledSmallIcon, setDisabledSmallIcon] = useState(false);
    const [errorUploaderSmallIcon, setErrorUploaderSmallIcon] = useState(false)

    const [tags, setTags] = useState(null);
    const [provideCertificate, setProvideCertificate] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getMentorCourses());
        dispatch(getMentorRoadmap());
    }, [dispatch]);

    useEffect(() => {
        setValueRoadmap('path_slug', slugify(watchRoadmapTitleValue));
    }, [setValueRoadmap, watchRoadmapTitleValue]);

    const onSubmitRoadmap = (data) => {

        // Check uploader image
        if (cover === "") {
            setErrorUploaderCover(true);
        }
        if (smallIcon === "") {
            setErrorUploaderSmallIcon(true); return;
        }

        // Inject data payload
        data.image_url = cover
        data.small_icon = smallIcon
        data.tags = tags.join(',')
        data.allow_certificate = provideCertificate ? 'Y' : 'N'
        data.is_public = 1

        // Post data payload
        dispatch(postMentorRoadmap(data)).then(response => {
            if (response.payload.status === 'success') {
                const resetButton = document.querySelector('.btn-reset');
                resetButton.click();
                navigate(`/mentor/roadmaps/edit/curriculum/${response.payload.data.id}`);
            } else {
                // Alert Failed
                MySwal.fire({
                    title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                    text: removeHTMLTags(response.payload.message),
                    icon: 'error'
                });
            };
        });
    }

    return (
        <>
            <Helmet>
                <title>Courses</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <div className="mb-5">
                                    <BannerHeader
                                        title={
                                            <>
                                                Experience kamu sangat berharga untuk
                                                <br className="d-none d-lg-block" />
                                                masa depan programmer di Indonesia
                                            </>
                                        }
                                        image="/assets/img/learn/banner-courses.png"
                                        dismissible={false}
                                    >
                                        <button className="btn btn-sm btn-primary py-2 px-4 btn-rounded" data-bs-toggle="modal" data-bs-target="#roadmapForm">
                                            <FontAwesomeIcon icon={faMap} /> Buat Roadmap
                                        </button>
                                    </BannerHeader>
                                </div>

                                <SectionRoadmaps
                                    loading={loadingMentorRoadmaps}
                                    data={mentorRoadmaps}
                                />
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>

                <Modal title="Create Roadmap" id="roadmapForm">
                    <form onSubmit={handleSubmitRoadmap(onSubmitRoadmap)}>
                        <div className="mb-3">
                            <label htmlFor="roadmap" className="form-label fw-bolder">Roadmap</label>
                            <input name="roadmap" className="form-control" placeholder="Roadmap" {...registerRoadmap("path_name", { required: true, maxLength: 50 })} />
                            {errorsRoadmap.path_name && errorsRoadmap.path_name.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="path_slug" className="form-label fw-bolder">Roadmap Slug</label>
                            <input name="path_slug" className="form-control" placeholder="roadmap-slug" {...registerRoadmap('path_slug')} readOnly />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="landingURL" className="form-label fw-bolder">Landing URL</label>
                            <input name="landingURL" className="form-control" placeholder="Landing URL" {...registerRoadmap("landing_url", { required: true, maxLength: 50 })} />
                            {errorsRoadmap.landing_url && errorsRoadmap.landing_url.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="cover" className="form-label fw-bolder">Cover</label>
                            <input className="form-control" type="hidden" value={cover} />
                            <input className="form-control" onChange={(e) => { uploadImage(e, setCover, setCoverLoading); setDisabledCover(true); setErrorUploaderCover(false) }} type="file" id="cover" hidden />
                            <div className="input-group">
                                <input
                                    type="text"
                                    onChange={(e) => { setCover(e.target.value); setErrorUploaderCover(false) }}
                                    disabled={disabledCover}
                                    className="form-control"
                                    value={cover}
                                />
                                <label className="btn btn-primary rounded" htmlFor="cover"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                            </div>
                            {errorUploaderCover && <span className="text-danger">This field is required</span>}
                            {coverLoading && (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                    <small className="text-muted">Please wait....</small>
                                </>
                            )}
                            {!coverLoading && cover && (
                                <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={cover || "/assets/img/placeholder.jpg"} alt="Cover" />
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="smallIcon" className="form-label fw-bolder">Small Icon</label>
                            <input className="form-control" type="hidden" value={smallIcon} />
                            <input className="form-control" onChange={(e) => { uploadImage(e, setSmallIcon, setSmallIconLoading); setDisabledSmallIcon(true); setErrorUploaderSmallIcon(false) }} type="file" id="smallIcon" hidden />
                            <div className="input-group">
                                <input
                                    type="text"
                                    onChange={(e) => { setSmallIcon(e.target.value); setErrorUploaderSmallIcon(false) }}
                                    disabled={disabledSmallIcon}
                                    className="form-control"
                                    value={smallIcon}
                                />
                                <label className="btn btn-primary rounded" htmlFor="smallIcon"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                            </div>
                            {errorUploaderSmallIcon && <span className="text-danger">This field is required</span>}
                            {smallIconLoading && (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                    <small className="text-muted">Please wait....</small>
                                </>
                            )}
                            {!smallIconLoading && smallIcon && (
                                <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={smallIcon || "/assets/img/placeholder.jpg"} alt="Cover" />
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label fw-bolder">Description</label>
                            <textarea name="description" id="description" rows="3" placeholder="description" className="form-control" {...registerRoadmap("description", { required: true })}></textarea>
                            {errorsRoadmap.description && errorsRoadmap.description.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="longDescription" className="form-label fw-bolder">Long Description</label>
                            <textarea name="longDescription" id="longDescription" rows="3" placeholder="Long description" className="form-control" {...registerRoadmap("long_description", { required: true })}></textarea>
                            {errorsRoadmap.long_description && errorsRoadmap.long_description.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="level" className="form-label fw-bolder">Level</label>
                            <select name="level" id="level" className="form-select" {...registerRoadmap("level", { required: true })}>
                                <option value="beginner">Beginner</option>
                                <option value="intermediate">intermediate</option>
                                <option value="advance">Advance</option>
                            </select>
                            {errorsRoadmap.level && errorsRoadmap.level.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="tags" className="form-label fw-bolder mb-0">Tags</label>
                            <p className="small text-muted">Gunakan spasi atau koma untuk menambah tag</p>
                            <div className="input-group">
                                <InputTags
                                    name="tags"
                                    className="form-control shadow-none p-0"
                                    placeholder="Add tags..."
                                    values={tags}
                                    onTags={(tag) => setTags(tag.values)}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="duration" className="form-label fw-bolder">Duration</label>
                            <div className="small text-muted">Total durasi jam dalam roadmap</div>
                            <input type="text" name="duration" className="form-control" placeholder="Ex : 10:00" {...registerRoadmap("duration", { required: true })} />
                            {errorsRoadmap.duration && errorsRoadmap.duration.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label fw-bolder">Status</label>
                            <select name="status" id="status" className="form-select" {...registerRoadmap("status", { required: true })}>
                                <option value="draft">Draft</option>
                            </select>
                            {errorsRoadmap.status && errorsRoadmap.status.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label fw-bolder">Provide Certificate</label>
                            <div className="form-check">
                                <input onChange={() => setProvideCertificate(false)} className="form-check-input" type="radio" name="provideCertificate" id="no" checked={!provideCertificate} />
                                <label className="form-check-label" htmlFor="no">
                                    No
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={() => setProvideCertificate(true)} className="form-check-input" type="radio" name="provideCertificate" id="yes" checked={provideCertificate} />
                                <label className="form-check-label" htmlFor="yes">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type="reset" className="btn text-muted shadow-none btn-reset" data-bs-dismiss="modal">Cancel</button>
                            {
                                loadingMentorRoadmaps ?
                                    (
                                        <button type="submit" className="btn btn-primary btn-rounded disabled">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    )
                                    : (<button type="submit" className="btn btn-primary btn-rounded">Submit</button>)
                            }
                        </div>
                    </form>
                </Modal>
            </Layout>
        </>
    );
};

export default Roadmaps;
