import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userId, userToken } from "../../utils/config";

// Get Discussion By Lesson
export const getDiscussionByLesson = createAsyncThunk('discussion/getDiscussionByLesson', async (id) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/forum/thread/lesson/${id}`);
            return response.data !== '' ? response.data : [];
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get Discussion By User ID
export const getMyDiscussions = createAsyncThunk('discussion/getMyDiscussions', async () => {
    const id = userId;

    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/forum/thread?id=${id}`);
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Get All Discussion
export const getAllDiscussion = createAsyncThunk('discussion/getAllDiscussion', async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/forum/thread`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }

});

// Get Detail Discussion
export const getDetailDiscussion = createAsyncThunk('discussion/getDetailDiscussion', async (slug) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/forum/thread/detail/${slug}`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }
});

// Post new discussion
export const postDiscussion = createAsyncThunk('discussion/postDiscussion', async ({ courseId, lessonId, threadSubject, threadContent }, { dispatch }) => {
    try {
        let data = {
            "course_id": courseId,
            "lesson_id": lessonId,
            "thread_subject": threadSubject,
            "thread_content": threadContent,
            "thread_mode": "md"
        };

        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/forum/thread/insert`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDiscussionByLesson(lessonId));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update discussion
export const updateDiscussion = createAsyncThunk('discussion/updateDiscussion', async ({ id, thread_subject, thread_content, slug }, { dispatch }) => {
    const data = { id, thread_subject, thread_content }
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/forum/thread/update`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Report discussion
export const reportDiscussion = createAsyncThunk('discussion/reportDiscussion', async ({ meta, issue, note, object_id, object_type, slug }, { dispatch }) => {
    const data = { meta, issue, note, object_id, object_type }
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/forum/flag/insert`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Reply discussion
export const replyDiscussion = createAsyncThunk('discussion/replyDiscussion', async ({ thread_id, reply_mode, reply_content, slug }, { dispatch }) => {
    const data = { thread_id, reply_mode, reply_content }
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/forum/reply/insert`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Reply discussion comment
export const commentDiscussion = createAsyncThunk('discussion/commentDiscussion', async ({ thread_id, comment_content, object_id, object_type, slug }, { dispatch }) => {
    const data = { thread_id, comment_content, object_id, object_type }
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/forum/comment/insert`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update discussion reply
export const updateReplyDiscussion = createAsyncThunk('discussion/updateReplyDiscussion', async ({ id, reply_content, slug }, { dispatch }) => {
    const data = { id, reply_content }
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/forum/reply/update`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Update discussion comment
export const updateCommentDiscussion = createAsyncThunk('discussion/updateCommentDiscussion', async ({ id, comment_content, slug }, { dispatch }) => {
    const data = { id, comment_content }
    try {
        if (userToken) {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/forum/comment/update`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Mark as best reply
export const markAsBestReply = createAsyncThunk('discussion/markAsBestReply', async ({ thread_id, reply_id, slug }, { dispatch }) => {
    const data = { thread_id, reply_id }
    try {
        if (userToken) {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/forum/reply/approve`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete discussion
export const deleteDiscussion = createAsyncThunk('discussion/deleteDiscussion', async ({ thread_id, slug }, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/forum/thread/remove/${thread_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete discussion reply
export const deleteDiscussionReply = createAsyncThunk('discussion/deleteDiscussionReply', async ({ thread_id, reply_id, slug }, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/forum/reply/remove/${thread_id}/${reply_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

// Delete discussion reply
export const deleteDiscussionComment = createAsyncThunk('discussion/deleteDiscussionComment', async ({ comment_id, slug }, { dispatch }) => {
    try {
        if (userToken) {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/forum/comment/remove/${comment_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken
                }
            });
            dispatch(getDetailDiscussion(slug));
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    discussions: [],
    discussion: [],
    myDiscussions: [],
    lessonDiscussions: [],
    postDiscussionResult: {},
    updateDiscussionResult: [],
    loadingDiscussion: false,
    loadingDelete: false,
    loadingMark: false
};

const discussionSlice = createSlice({
    name: 'discussion',
    initialState,
    reducers: {
        resetDiscussionState: () => initialState
    },
    extraReducers: {
        // Get Single Course
        [getDiscussionByLesson.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [getDiscussionByLesson.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
            state.lessonDiscussions = action.payload;
        },
        [getDiscussionByLesson.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Get All Discussion
        [getAllDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [getAllDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
            if (action.payload !== "") {
                state.discussions = action.payload;
            } else {
                state.discussions = [];
            };
        },
        [getAllDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Get My Discussion By User ID
        [getMyDiscussions.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [getMyDiscussions.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
            state.myDiscussions = action.payload;
        },
        [getMyDiscussions.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Get Detail Discussion
        [getDetailDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [getDetailDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
            state.discussion = action.payload;
        },
        [getDetailDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Post Discussion
        [postDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [postDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
            state.postDiscussionResult = action.payload;
        },
        [postDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Update Discussion
        [updateDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [updateDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
            state.updateDiscussionResult = action.payload;
        },
        [updateDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Delete Discussion
        [deleteDiscussion.pending]: (state, action) => {
            state.loadingDelete = true;
        },
        [deleteDiscussion.fulfilled]: (state, action) => {
            state.loadingDelete = false;
        },
        [deleteDiscussion.rejected]: (state, action) => {
            state.loadingDelete = false;
        },

        // Report Discussion
        [reportDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [reportDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
        },
        [reportDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Reply Discussion
        [replyDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [replyDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
        },
        [replyDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Reply Discussion Comment
        [commentDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [commentDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
        },
        [commentDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Update Discussion Reply
        [updateReplyDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [updateReplyDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
        },
        [updateReplyDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Update Discussion Comment
        [updateCommentDiscussion.pending]: (state, action) => {
            state.loadingDiscussion = true;
        },
        [updateCommentDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussion = false;
        },
        [updateCommentDiscussion.rejected]: (state, action) => {
            state.loadingDiscussion = false;
        },

        // Delete Discussion Reply
        [deleteDiscussionReply.pending]: (state, action) => {
            state.loadingDelete = true;
        },
        [deleteDiscussionReply.fulfilled]: (state, action) => {
            state.loadingDelete = false;
        },
        [deleteDiscussionReply.rejected]: (state, action) => {
            state.loadingDelete = false;
        },

        // Delete Discussion Comment
        [deleteDiscussionComment.pending]: (state, action) => {
            state.loadingDelete = true;
        },
        [deleteDiscussionComment.fulfilled]: (state, action) => {
            state.loadingDelete = false;
        },
        [deleteDiscussionComment.rejected]: (state, action) => {
            state.loadingDelete = false;
        },

        // Mark as best reply
        [markAsBestReply.pending]: (state, action) => {
            state.loadingMark = true;
        },
        [markAsBestReply.fulfilled]: (state, action) => {
            state.loadingMark = false;
        },
        [markAsBestReply.rejected]: (state, action) => {
            state.loadingMark = false;
        },
    }
});

export const { resetDiscussionState } = discussionSlice.actions;
export default discussionSlice.reducer;
