import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { userToken } from "../../utils/config";

export const getLeaderboard = createAsyncThunk('user/getLeaderboard', async (period) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/points/leaderboard?page=1&period=${period}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getCurrentUserPoint = createAsyncThunk('user/getCurrentUserPoint', async (period) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/points/current?period=${period}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

export const getUserPointHistory = createAsyncThunk('user/getUserPointHistory', async (page) => {
    try {
        if (userToken) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/points?page=${page}`, {
                headers: {
                    Authorization: userToken
                }
            });
            return response.data;
        }

    } catch (error) {
        throw new Error(error);
    }
});

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: {
        leaderboard: [],
        currentUserPoint: {},
        userPointHistory: [],
        loadingLeaderboard: false
    },
    extraReducers: {
        // Weekly
        [getLeaderboard.pending]: (state, action) => {
            state.loadingLeaderboard = true;
        },
        [getLeaderboard.fulfilled]: (state, action) => {
            state.loadingLeaderboard = false;
            state.leaderboard = !action.payload.error ? action.payload : [];
        },
        [getLeaderboard.rejected]: (state, action) => {
            state.loadingLeaderboard = true;
        },

        // Weekly
        [getCurrentUserPoint.pending]: (state, action) => {
            state.loadingLeaderboard = true;
        },
        [getCurrentUserPoint.fulfilled]: (state, action) => {
            state.loadingLeaderboard = false;
            state.currentUserPoint = !action.payload.error ? action.payload : [];
        },
        [getCurrentUserPoint.rejected]: (state, action) => {
            state.loadingLeaderboard = true;
        },

        // User Point History
        [getUserPointHistory.pending]: (state, action) => {
            state.loadingLeaderboard = true;
        },
        [getUserPointHistory.fulfilled]: (state, action) => {
            state.loadingLeaderboard = false;

            const currentPointHistory = [...state.userPointHistory];
            const index = currentPointHistory.findIndex(search => search.created_at === action.payload[0].created_at);

            if (index > -1) {
                currentPointHistory[index].created_at = action.payload[index].created_at;
            } else {
                currentPointHistory.push(...action.payload);
            };

            state.userPointHistory = currentPointHistory;
        },
        [getUserPointHistory.rejected]: (state, action) => {
            state.loadingLeaderboard = true;
        },
    }
});

export default leaderboardSlice.reducer;
