import { faCircleInfo, faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardNotification = ({ type, message, date, navbar }) => {
    return (
        <div className={`card ${navbar && 'bg-light border-0'}`} style={{ borderRadius: navbar ? '15px' : 'auto' }}>
            <div className="card-body">
                <div className="py-2">
                    {type === 'forum-reply' && (
                        <span className="badge border border-danger text-danger py-2">
                            <FontAwesomeIcon icon={faComment} /> Jawaban Forum
                        </span>
                    )}
                    {type === 'forum-comment' && (
                        <span className="badge border border-secondary text-secondary py-2">
                            <FontAwesomeIcon icon={faComment} /> Komentar Baru
                        </span>
                    )}
                    {type === 'information' && (
                        <span className="badge border border-info text-info py-2">
                            <FontAwesomeIcon icon={faCircleInfo} /> Informasi
                        </span>
                    )}
                    <span className="text-muted mx-2">|</span>
                    <small>
                        <span className="text-muted">{date}</span>
                    </small>
                </div>
                <div className="my-2">
                    <p className="text-muted" dangerouslySetInnerHTML={{ __html: message }} />
                </div>
            </div>
        </div>
    );
};

export default CardNotification;
