import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCourses = createAsyncThunk('browseCourse/getCourses', async ({ search, courseLabel, filter, sort }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/course?page=1&limit=36&search=${search}&course_label=${courseLabel}&filter=${filter}&sort=${sort}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const getCourseLabels = createAsyncThunk('browseCourse/getCourseLabels', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/course/labels`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const browseCourse = createSlice({
    name: 'browseCourse',
    initialState: {
        courses: [],
        courseLabels: [],
        loadingBrowseCourse: false
    },
    extraReducers: {
        [getCourses.pending]: (state, action) => {
            state.loadingBrowseCourse = true;
        },
        [getCourses.fulfilled]: (state, action) => {
            state.loadingBrowseCourse = false;
            if (action.payload.error !== 'No Content') state.courses = action.payload;
            else state.courses = [];
        },
        [getCourses.rejected]: (state, action) => {
            state.loadingBrowseCourse = false;
        },

        [getCourseLabels.pending]: (state, action) => {
            state.loadingBrowseCourse = true;
        },
        [getCourseLabels.fulfilled]: (state, action) => {
            state.loadingBrowseCourse = false;
            state.courseLabels = action.payload;
        },
        [getCourseLabels.rejected]: (state, action) => {
            state.loadingBrowseCourse = false;
        },
    }
});

export default browseCourse.reducer;
