import { faFileAlt, faFileVideo, faFolderPlus, faTimes, faUpload, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import Layout from "../../../components/global/Layout/Layout";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Modal from '../../../components/global/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLesson, deleteQuestion, deleteTopic, getLesson, getQuestion, getQuiz, getTopic, getTopicAndLessons, moveDownLesson, moveDownTopic, moveUpLesson, moveUpTopic, postLesson, postQuestion, postQuiz, postTopic, updateLesson, updateQuestion, updateQuiz, updateTopic } from '../../../features/mentor/mentorCourseSlice';
import { useForm } from "react-hook-form";
import { capitalizeFirstLetter, removeHTMLTags, uploadImage } from '../../../utils/helper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ListTopic from '../../../components/mentor/Lists/ListTopic/ListTopic';
import slugify from 'react-slugify';
import Sidebar from '../../../components/mentor/Sidebar/Sidebar';

const EditCourseCurriculum = () => {
    const { id } = useParams();

    const MySwal = withReactContent(Swal);
    const closeModalTopic = document.querySelector('.btn-reset-topic');
    const closeModalLesson = document.querySelector('.btn-reset-lesson');
    const closeModalQuiz = document.querySelector('.btn-reset-quiz');
    const closeModalQuestion = document.querySelector('.btn-reset-question');

    const [formLessonType, setFormLessonType] = useState("theory");
    const [isEdit, setIsEdit] = useState(false);

    const [topicId, setTopicId] = useState("");
    const [theory, setTheory] = useState("");

    const [quizId, setQuizId] = useState("");

    const [quizCover, setQuizCover] = useState('');
    const [quizCoverLoading, setQuizCoverLoading] = useState(false);

    const [options, setOptions] = useState([{
        content: '',
        is_right: "0"
    }]);

    const [editOptions, setEditOptions] = useState([{
        id: '',
        content: '',
        is_right: "0"
    }]);

    const { topicAndLessons } = useSelector((state) => ({ ...state.mentorCourse }));
    const { register: registerTopic, handleSubmit: handleSubmitTopic, reset: resetTopic, formState: { errors: errorTopic } } = useForm();
    const { register: registerLesson, handleSubmit: handleSubmitLesson, watch: watchLesson, setValue: setValueLesson, reset: resetLesson, formState: { errors: errorLesson } } = useForm();
    const { register: registerQuiz, handleSubmit: handleSubmitQuiz, watch: watchQuiz, setValue: setValueQuiz, reset: resetQuiz, formState: { errors: errorQuiz } } = useForm();
    const { register: registerQuestion, handleSubmit: handleSubmitQuestion, reset: resetQuestion, formState: { errors: errorQuestion } } = useForm();

    const watchLessonTitleValue = watchLesson('lessonTitle');
    const watchQuizTitleValue = watchQuiz('quizTitle');

    const dispatch = useDispatch();

    // Get topic state
    useEffect(() => {
        dispatch(getTopicAndLessons(id));
    }, [dispatch, id]);

    // Set value to lessonSlug field
    useEffect(() => {
        setValueLesson('lessonSlug', slugify(watchLessonTitleValue));
    }, [setValueLesson, watchLessonTitleValue]);

    // set value to quizSlug field
    useEffect(() => {
        setValueQuiz('quizSlug', slugify(watchQuizTitleValue));
    }, [setValueQuiz, watchQuizTitleValue]);

    // Post Topic
    const onSubmitTopic = (data) => {
        data.id = id;
        dispatch(postTopic(data)).then((response => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalTopic.click()
            );
        }));
    };

    // Edit Topic
    const onEditTopic = (id) => {
        setIsEdit(true);
        dispatch(getTopic(id)).then((response => {
            resetTopic({
                topicId: response.payload.id,
                topicTitle: response.payload.topic_title,
                topicOrder: response.payload.topic_order,
                free: response.payload.free === "1" ? true : false
            });
        }));
    };

    // Update Topic
    const onUpdateTopic = (data) => {
        data.id = id;
        dispatch(updateTopic(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalTopic.click()
            );
        });
    };

    // Delete Topic
    const onDeleteTopic = (topicId) => {
        MySwal.fire({
            title: 'Delete Topic?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result => {
            if (result.isConfirmed) {
                dispatch(deleteTopic(topicId)).then((response) => {
                    // Alert
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: response.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 2000
                    }).then(
                        dispatch(getTopicAndLessons(id))
                    );
                });
            }
        }));
    };

    // Move Up Topic
    const onMoveUpTopic = (topicId, position) => {
        dispatch(moveUpTopic({
            courseId: id,
            topicId: topicId,
            position: position
        }));
    };

    // Move Down Topic
    const onMoveDownTopic = (topicId, position) => {
        dispatch(moveDownTopic({
            courseId: id,
            topicId: topicId,
            position: position
        }));
    };

    // Post Lesson
    const onSubmitLesson = (data) => {
        data.courseId = id;
        data.topicId = topicId;
        data.type = formLessonType;
        data.theory = theory;

        dispatch(postLesson(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalLesson.click()
            );
        });
    };

    // Edit Lesson
    const onEditLesson = (id) => {
        setIsEdit(true);
        dispatch(getLesson(id)).then((response => {
            setFormLessonType(response.payload.type);
            resetLesson({
                free: response.payload.free === "1" ? true : false,
                topicId: response.payload.topic_id,
                lessonId: response.payload.id,
                lessonOrder: response.payload.lesson_order,
                lessonTitle: response.payload.lesson_title,
                lessonSlug: response.payload.lesson_slug,
                player: response.payload.player,
                duration: response.payload.duration,
                bunnystreamId: response.payload.bunnystream_id,
                vdocipherId: response.payload.vdocipher_id,
                youtubeId: response.payload.youtube_id
            });
            setTheory(response.payload.content.theory);
        }));
    };

    // Update Lesson
    const onUpdateLesson = (data) => {
        data.courseId = id;
        data.type = formLessonType;
        data.theory = theory;

        dispatch(updateLesson(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalLesson.click()
            );
        });
    };

    // Delete Lesson or Quiz
    const onDeleteLesson = (lessonId) => {
        MySwal.fire({
            title: 'Delete Lesson / Quiz ini?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result => {
            if (result.isConfirmed) {
                dispatch(deleteLesson(lessonId)).then((response) => {
                    // Alert
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: response.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 2000
                    }).then(
                        dispatch(getTopicAndLessons(id))
                    );
                });
            }
        }));
    };

    // Move Up Lesson
    const onMoveUpLesson = (topicId, lessonId, position) => {
        dispatch(moveUpLesson({
            courseId: id,
            topicId: topicId,
            lessonId: lessonId,
            position: position
        }));
    };

    // Move Down Lesson
    const onMoveDownLesson = (topicId, lessonId, position) => {
        dispatch(moveDownLesson({
            courseId: id,
            topicId: topicId,
            lessonId: lessonId,
            position: position
        }));
    };

    // Post Quiz
    const onSubmitQuiz = (data) => {
        data.courseId = id;
        data.topicId = topicId;
        data.quizCover = quizCover;

        dispatch(postQuiz(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalQuiz.click()
            );
        });
    };

    // Edit Quiz
    const onEditQuiz = (id) => {
        setIsEdit(true);
        dispatch(getQuiz(id)).then((response => {
            setFormLessonType("quiz");
            resetQuiz({
                quizId: response.payload.id,
                quizTitle: response.payload.title,
                quizSlug: response.payload.slug,
                quizSubtitle: response.payload.subtitle,
                quizDescription: response.payload.description,
                quizLevel: response.payload.level,
                quizCategory: response.payload.category,
                questionType: response.payload.type,
                quizLabel: response.payload.label,
                quizDuration: response.payload.duration,
                quizKKM: response.payload.kkm,
                quizTotalShow: response.payload.total_show,
                randomQuestion: response.payload.random_question,
                quizStatus: response.payload.status
            });
            setQuizCover(response.payload.cover);
        }));
    };

    // Update Quiz
    const onUpdateQuiz = (data) => {
        dispatch(updateQuiz(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalQuiz.click()
            );
        });
    };

    // Post Question
    const onSubmitQuestion = (data) => {
        data.quizId = quizId;
        data.options = options;

        dispatch(postQuestion(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalQuestion.click(),
                dispatch(getTopicAndLessons(id))
            );
        });
    };

    // Edit Question
    const onEditQuestion = (id) => {
        setIsEdit(true);
        dispatch(getQuestion(id)).then((response => {
            resetQuestion({
                questionId: response.payload.id,
                questionTitle: response.payload.question_title,
                questionContent: response.payload.question_content,
                questionHint: response.payload.hint
            });
            setEditOptions(response.payload.options);
        }));
    };

    // Update Question
    const onUpdateQuestion = (data) => {
        data.options = editOptions;

        dispatch(updateQuestion(data)).then((response) => {
            // Alert
            MySwal.fire({
                title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                text: removeHTMLTags(response.payload.message),
                icon: response.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 2000
            }).then(
                closeModalQuestion.click(),
                dispatch(getTopicAndLessons(id))
            );
        });
    };

    // Delete Question
    const onDeleteQuestion = (quizId, questionId) => {
        MySwal.fire({
            title: 'Delete Question?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result => {
            if (result.isConfirmed) {
                dispatch(deleteQuestion({
                    groupId: quizId,
                    questionId: questionId
                })).then((response) => {
                    // Alert
                    MySwal.fire({
                        title: <strong>{capitalizeFirstLetter(response.payload.status)}</strong>,
                        text: removeHTMLTags(response.payload.message),
                        icon: response.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 2000
                    }).then(
                        dispatch(getTopicAndLessons(id))
                    );
                });
            }
        }));
    };

    // Add Options for Question
    const onAddOption = () => {
        setOptions([
            ...options,
            {
                content: '',
                is_right: "0"
            }
        ]);
    };

    // Remove Option for Question
    const onRemoveOption = (index) => {
        const optionList = [...options];
        optionList.splice(index, 1);
        setOptions(optionList);
    };

    // onChange Option for Question
    const onOptionChanged = (e, index) => {
        const { name, value } = e.target;
        const optionList = [...options];
        optionList[index][name] = value;
        setOptions(optionList);
    };

    // onChange Option for Question
    const onEditOptionChanged = (e, index) => {
        const { name, value } = e.target;
        const optionList = [...editOptions];
        optionList[index][name] = value;
        setEditOptions(optionList);
    };

    // reset all state
    const resetAllState = () => {
        setIsEdit(false);
        // reset Topic
        resetTopic({
            topicId: '',
            topicTitle: '',
            topicOrder: '',
            free: false
        });

        // reset Lesson
        setFormLessonType("theory");
        resetLesson({
            free: false,
            lessonId: '',
            lessonOrder: '',
            lessonTitle: '',
            lessonSlug: '',
            player: 'bunnystream',
            duration: '',
            bunnystreamId: '',
            vdocipherId: '',
            youtubeId: ''
        });
        setTheory('');

        // reset Quiz
        setFormLessonType("theory");
        resetQuiz({
            quizId: '',
            quizTitle: '',
            quizSlug: '',
            quizSubtitle: '',
            quizDescription: '',
            quizLevel: 'beginner',
            quizCategory: 'quiz',
            questionType: 'mc',
            quizLabel: '',
            quizDuration: '',
            quizKKM: '',
            quizTotalShow: '',
            randomQuestion: '',
            quizStatus: 'publish'
        });
        setQuizCover('');

        // Reset Question
        resetQuestion({
            questionId: '',
            questionTitle: '',
            questionContent: '',
            questionHint: ''
        });
        setOptions([
            {
                content: '',
                is_right: "0"
            }
        ]);
    };

    return (
        <>
            <Helmet>
                <title>Edit Course</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <h3 className="section-title mb-5">Edit Course</h3>

                                <ul className="nav nav-pills text-nowrap overflow-auto flex-nowrap my-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/courses/edit/overview/${id}`}>Overview</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4 active" to={`/mentor/courses/edit/curriculum/${id}`}>Curriculum</Link>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <Link className="nav-link px-4" to={`/mentor/courses/edit/pricing/${id}`}>Pricing</Link>
                                    </li>
                                </ul>

                                <div className="row my-4">
                                    <div className="col-lg-8">
                                        <ListTopic
                                            data={topicAndLessons}
                                            onEditTopic={onEditTopic}
                                            onDeleteTopic={onDeleteTopic}
                                            onMoveUpTopic={onMoveUpTopic}
                                            onMoveDownTopic={onMoveDownTopic}
                                            setTopicId={setTopicId}
                                            onEditLesson={onEditLesson}
                                            onDeleteLesson={onDeleteLesson}
                                            onMoveUpLesson={onMoveUpLesson}
                                            onMoveDownLesson={onMoveDownLesson}
                                            setQuizId={setQuizId}
                                            onEditQuiz={onEditQuiz}
                                            onEditQuestion={onEditQuestion}
                                            onDeleteQuestion={onDeleteQuestion}
                                        />

                                        <button onClick={() => setIsEdit(false)} className="btn btn-primary btn-rounded my-3" data-bs-toggle="modal" data-bs-target="#topicForm">
                                            <FontAwesomeIcon className="me-2" icon={faFolderPlus} />
                                            Add Topic
                                        </button>

                                    </div>
                                </div>

                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>

                {/* Modal Form Topic */}
                <Modal onCloseModal={resetAllState} backdrop="static" id="topicForm" title={!isEdit ? "Add Topic" : "Edit Topic"}>
                    <form onSubmit={handleSubmitTopic(!isEdit ? onSubmitTopic : onUpdateTopic)}>
                        <div className="mb-3">
                            <div className="d-flex mt-3">
                                <label className="h5">Jadikan Topik Ini sebagai teaser ?</label>
                                <div className="form-check form-switch ms-3 me-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        {...registerTopic("free")}
                                    />
                                </div>
                            </div>
                            <p className="text-muted">Teaser adalah lesson permulaan untuk calon pembeli supaya mendapatkan pengalaman seperti apa belajar dikelas ini</p>
                        </div>
                        {isEdit && (
                            <div className="mb-3" hidden>
                                <label htmlFor="topicId" className="form-label fw-bolder">Topic Id</label>
                                <input
                                    name="topicId"
                                    className="form-control"
                                    placeholder="Topic Id"
                                    {...registerTopic("topicId", { required: true })}
                                />
                                {errorTopic.topicId && errorTopic.topicId.type === 'required' && <span className="text-danger">This field is required</span>}
                            </div>
                        )}
                        <div className="mb-3">
                            <label htmlFor="topicOrder" className="form-label fw-bolder">Topic Order</label>
                            <input
                                type="number"
                                name="topicOrder"
                                className="form-control"
                                placeholder="Topic Order"
                                {...registerTopic("topicOrder", { required: true, valueAsNumber: true })}
                            />
                            {errorTopic.topicOrder && errorTopic.topicOrder.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="topicTitle" className="form-label fw-bolder">Topic Title</label>
                            <input
                                name="topicTitle"
                                className="form-control"
                                placeholder="Topic title"
                                {...registerTopic("topicTitle", { required: true, maxLength: 50 })}
                            />
                            {errorTopic.topicTitle && errorTopic.topicTitle.type === 'required' && <span className="text-danger">This field is required</span>}
                            {errorTopic.topicTitle && errorTopic.topicTitle.type === 'maxLength' && <span className="text-danger">Max length exceeded</span>}
                        </div>
                        <div className="float-end">
                            <button onClick={resetAllState} type="reset" className="btn text-muted me-2 btn-reset-topic" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" className="btn btn-primary btn-rounded">Save</button>
                        </div>
                    </form>
                </Modal>
                {/* End of Modal Form Topic */}

                {/* Modal Form Lesson */}
                <Modal onCloseModal={resetAllState} backdrop="static" id="lessonForm" title={!isEdit ? "Add" : "Edit"}>
                    <div className="my-3">
                        {!isEdit && (
                            <div className="mb-3">
                                <div className="row justify-content-between">
                                    <div className="col-lg-6 my-3 my-lg-auto">
                                        <label className="text-muted">Silahkan pilih tipe untuk Lesson ini</label>
                                    </div>
                                    <div className="col-lg-auto d-flex">
                                        <button onClick={() => setFormLessonType("theory")} className={formLessonType === "theory" ? "btn d-flex text-primary" : "btn d-flex text-muted"}>
                                            <FontAwesomeIcon size="3x" icon={faFileVideo} />
                                            <span className="ms-3 my-auto">Theory</span>
                                        </button>
                                        <button onClick={() => setFormLessonType("quiz")} className={formLessonType === "quiz" ? "btn d-flex text-primary" : "btn d-flex text-muted"}>
                                            <FontAwesomeIcon size="3x" icon={faFileAlt} />
                                            <span className="ms-3 my-auto">Quiz</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {formLessonType === "theory" && (
                            <form onSubmit={handleSubmitLesson(!isEdit ? onSubmitLesson : onUpdateLesson)}>
                                <div className="mb-3">
                                    <div className="d-flex mt-3">
                                        <label className="h5">Jadikan Lesson Ini sebagai teaser?</label>
                                        <div className="form-check form-switch ms-3 me-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                {...registerLesson("free")}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-muted">Teaser adalah lesson permulaan untuk calon pembeli supaya mendapatkan pengalaman seperti apa belajar dikelas ini</p>
                                </div>
                                {isEdit && (
                                    <div className="mb-3" hidden>
                                        <label htmlFor="lessonId" className="form-label fw-bolder">Lesson Id</label>
                                        <input
                                            name="topicId"
                                            className="form-control"
                                            placeholder="Topic Id"
                                            {...registerLesson("lessonId", { required: true })}
                                        />
                                        {errorLesson.lessonId && errorLesson.lessonId.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="lessonOrder" className="form-label fw-bolder">Lesson Order</label>
                                    <input
                                        type="number"
                                        name="lessonOrder"
                                        className="form-control"
                                        placeholder="Lesson Order"
                                        {...registerLesson("lessonOrder", { required: true, valueAsNumber: true })}
                                    />
                                    {errorLesson.lessonOrder && errorLesson.lessonOrder.type === 'required' && <span className="text-danger">This field is required</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="lessonTitle" className="form-label fw-bolder">Lesson Title</label>
                                    <input
                                        name="lessonTitle"
                                        className="form-control"
                                        placeholder="Lesson Title"
                                        {...registerLesson("lessonTitle", { required: true, maxLength: 50 })}
                                    />
                                    {errorLesson.lessonTitle && errorLesson.lessonTitle.type === 'required' && <span className="text-danger">This field is required</span>}
                                    {errorLesson.lessonTitle && errorLesson.lessonTitle.type === 'maxLength' && <span className="text-danger">Max length exceeded</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="lessonSlug" className="form-label fw-bolder">Lesson Slug</label>
                                    <input
                                        readOnly
                                        name="lessonSlug"
                                        className="form-control"
                                        placeholder="Lesson Slug"
                                        {...registerLesson("lessonSlug", { required: true })}
                                    />
                                    {errorLesson.lessonSlug && errorLesson.lessonSlug.type === 'required' && <span className="text-danger">This field is required</span>}
                                </div>
                                <div className="row">
                                    <div className="col-8 mb-3">
                                        <label htmlFor="descriptionDetail" className="form-label fw-bolder">Video Player</label>
                                        <select
                                            className="form-select"
                                            {...registerLesson("player", { required: true })}
                                        >
                                            <option value="bunnystream">Bunny Stream</option>
                                            <option value="vdocipher">VDO Cipher</option>
                                            <option value="youtube">Youtube</option>
                                        </select>
                                        {errorLesson.player && errorLesson.player.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                    <div className="col-4 mb-3">
                                        <label htmlFor="duration" className="form-label fw-bolder">Duration</label>
                                        <input
                                            name="duration"
                                            className="form-control"
                                            placeholder="ex. 2:05"
                                            {...registerLesson("duration")}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="bunnystreamId" className="form-label fw-bolder">Bunny Stream ID</label>
                                        <input
                                            name="bunnystreamId"
                                            className="form-control"
                                            placeholder="Bunny Stream ID"
                                            {...registerLesson("bunnystreamId")}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="vdocipherId" className="form-label fw-bolder">VDO Cipher ID</label>
                                        <input
                                            name="vdocipherId"
                                            className="form-control"
                                            placeholder="VDO Cipher ID"
                                            {...registerLesson("vdocipherId")}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="youtubeId" className="form-label fw-bolder">Youtube ID</label>
                                        <input
                                            name="youtubeId"
                                            className="form-control"
                                            placeholder="Youtube ID"
                                            {...registerLesson("youtubeId")}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fw-bolder">Text Content</label>
                                    <SimpleMDE
                                        value={theory}
                                        onChange={(value) => setTheory(value)}
                                    // options={options}
                                    />
                                </div>
                                <div className="text-end">
                                    <button onClick={resetAllState} type="reset" className="btn text-muted me-2 btn-reset-lesson" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary btn-rounded">Save</button>
                                </div>
                            </form>
                        )}
                        {formLessonType === "quiz" && (
                            <form onSubmit={handleSubmitQuiz(!isEdit ? onSubmitQuiz : onUpdateQuiz)}>
                                {isEdit && (
                                    <div className="mb-3" hidden>
                                        <label htmlFor="quizId" className="form-label fw-bolder">Quiz ID</label>
                                        <input name="quizId" className="form-control" placeholder="Quiz Id" {...registerQuiz('quizId', { required: true })} />
                                        {errorQuiz.quizId && errorQuiz.quizId.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="quizTitle" className="form-label fw-bolder">Title</label>
                                    <input name="quizTitle" className="form-control" placeholder="Title" {...registerQuiz('quizTitle', { required: true })} />
                                    {errorQuiz.quizTitle && errorQuiz.quizTitle.type === 'required' && <span className="text-danger">This field is required</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="quizSlug" className="form-label fw-bolder">Slug</label>
                                    <input readOnly name="quizSlug" className="form-control" placeholder="slug" {...registerQuiz('quizSlug', { required: true })} />
                                    {errorQuiz.quizSlug && errorQuiz.quizSlug.type === 'required' && <span className="text-danger">This field is required</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="quizSubtitle" className="form-label fw-bolder">Subtitle</label>
                                    <input name="quizSubtitle" className="form-control" placeholder="Subtitle" {...registerQuiz('quizSubtitle')} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="quizDescription" className="form-label fw-bolder">Description</label>
                                    <textarea name="quizDescription" className="form-control" rows={4} placeholder="Description" {...registerQuiz('quizDescription')} />
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="quizLevel" className="form-label fw-bolder">Level</label>
                                        <select
                                            className="form-select"
                                            {...registerQuiz("quizLevel", { required: true })}
                                        >
                                            <option value="beginner">Beginner</option>
                                            <option value="intermediate">Intermediate</option>
                                            <option value="pro">Pro</option>
                                            <option value="expert">Expert</option>
                                        </select>
                                        {errorQuiz.quizLevel && errorQuiz.quizLevel.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="quizCategory" className="form-label fw-bolder">Category</label>
                                        <select
                                            className="form-select"
                                            {...registerQuiz("quizCategory", { required: true })}
                                        >
                                            <option value="quiz">Quiz</option>
                                            <option value="exam">Exam</option>
                                        </select>
                                        {errorQuiz.quizCategory && errorQuiz.quizCategory.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="questionType" className="form-label fw-bolder">Question Type</label>
                                        <select
                                            className="form-select"
                                            {...registerQuiz("questionType", { required: true })}
                                        >
                                            <option value="mc">Multiple Choice</option>
                                            <option value="essay">Essay</option>
                                            <option value="dragdrop">Drag and Drop</option>
                                        </select>
                                        {errorQuiz.questionType && errorQuiz.questionType.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 mb-3">
                                        <label htmlFor="quizCover" className="form-label fw-bolder">Cover</label>
                                        <input className="form-control" type="hidden" value={quizCover} />
                                        <input className="form-control" onChange={(e) => uploadImage(e, setQuizCover, setQuizCoverLoading)} type="file" id="quizCover" hidden />
                                        <div className="input-group">
                                            <label className="form-control bg-light">{quizCover && quizCover.slice(0, 25) + '...'}</label>
                                            <label className="btn btn-primary rounded" htmlFor="quizCover"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                        </div>
                                        {quizCoverLoading && (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                <small className="text-muted">Please wait....</small>
                                            </>
                                        )}
                                        {!quizCoverLoading && quizCover && (
                                            <img height={200} width={200} className="objectfit-cover d-block mx-auto my-3" src={quizCover || "/assets/img/placeholder.jpg"} alt="quizCover" />
                                        )}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="quizLabel" className="form-label fw-bolder">Label</label>
                                        <input name="quizLabel" className="form-control" placeholder="Label" {...registerQuiz('quizLabel')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="quizDuration" className="form-label fw-bolder">Duration</label>
                                        <input type="number" name="quizDuration" className="form-control" placeholder="ex. 10" {...registerQuiz('quizDuration', { required: true })} />
                                        <small className="text-muted">Masukan durasi dalam menit, misal : 10</small>
                                        {errorQuiz.quizDuration && errorQuiz.quizDuration.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="quizKKM" className="form-label fw-bolder">KKM</label>
                                        <input type="number" name="quizKKM" className="form-control" placeholder="ex. 70" {...registerQuiz('quizKKM', { required: true })} />
                                        <small className="text-muted">Masukan nilai minimum kelulusan, misal : 70</small>
                                        {errorQuiz.quizKKM && errorQuiz.quizKKM.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="quizTotalshow" className="form-label fw-bolder">Total Show</label>
                                        <input type="number" name="quizTotalShow" className="form-control" placeholder="ex. 5" {...registerQuiz('quizTotalShow', { required: true })} />
                                        <small className="text-muted">Masukan total soal yang akan ditampilkan, misal : 5</small>
                                        {errorQuiz.quizTotalShow && errorQuiz.quizTotalShow.type === 'required' && <span className="text-danger">This field is required</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor="randomize" className="form-label fw-bolder">Randomize</label>
                                    <div className="col-lg-4 mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" {...registerQuiz('randomQuestion')} />
                                            <label className="form-check-label" htmlFor="randomQuestion">
                                                Random Question
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" />
                                            <label className="form-check-label" htmlFor="randomAnswer">
                                                Random Answer
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="quizStatus" className="form-label fw-bolder">Status</label>
                                    <select
                                        className="form-select"
                                        {...registerQuiz("quizStatus", { required: true })}
                                    >
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                    {errorQuiz.quizStatus && errorQuiz.quizStatus.type === 'required' && <span className="text-danger">This field is required</span>}
                                </div>

                                <div className="text-end">
                                    <button onClick={resetAllState} type="reset" className="btn text-muted me-2 btn-reset-quiz" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary btn-rounded">Save</button>
                                </div>
                            </form>
                        )}
                    </div>
                </Modal>
                {/* End of Modal Form Lesson */}

                {/* Modal Form Question */}
                <Modal onCloseModal={resetAllState} backdrop="static" id="questionForm" title={!isEdit ? "Add Question" : "Edit Question"}>
                    <form onSubmit={handleSubmitQuestion(!isEdit ? onSubmitQuestion : onUpdateQuestion)}>
                        {isEdit && (
                            <div className="mb-3" hidden>
                                <label htmlFor="questionId" className="form-label fw-bolder">Question ID</label>
                                <input name="questionId" className="form-control" placeholder="Pertanyaan" {...registerQuestion('questionId')} />
                            </div>
                        )}
                        <div className="mb-3">
                            <label htmlFor="questionTitle" className="form-label fw-bolder">Title</label>
                            <input name="questionTitle" className="form-control" placeholder="Pertanyaan" {...registerQuestion('questionTitle', { required: true })} />
                            {errorQuestion.questionTitle && errorQuestion.questionTitle.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="questionContent" className="form-label fw-bolder">Content</label>
                            <textarea name="questionContent" className="form-control" placeholder="Content" rows={3} {...registerQuestion('questionContent', { required: true })} />
                            {errorQuestion.questionContent && errorQuestion.questionContent.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="questionHint" className="form-label fw-bolder">Hint</label>
                            <input name="questionHint" className="form-control" placeholder="Hint" {...registerQuestion('questionHint', { required: true })} />
                            {errorQuestion.questionHint && errorQuestion.questionHint.type === 'required' && <span className="text-danger">This field is required</span>}
                        </div>
                        <label htmlFor="question" className="form-label fw-bolder mt-4 mt-3">Opsi jawaban :</label>
                        {!isEdit && options?.map((option, index) => {
                            return (
                                <div className="" key={index}>
                                    <div className="row">
                                        <div className="col-lg-2 mb-2">
                                            <label className="mb-1">Status</label>
                                            <select name="is_right" onChange={(e) => onOptionChanged(e, index)} value={option.is_right} className="form-select">
                                                <option value={0}>Salah</option>
                                                <option value={1}>Benar</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-10 mb-2">
                                            <label className="mb-1">Isi</label>
                                            <textarea name="content" onChange={(e) => onOptionChanged(e, index)} value={option.content} className="form-control" placeholder="Masukkan jawaban" rows={3} />
                                        </div>
                                    </div>
                                    {options.length > 1 && (
                                        <div className="text-end">
                                            <button onClick={() => onRemoveOption(index)} type="button" className="btn text-danger mb-3"><FontAwesomeIcon icon={faTimes} /> Remove Option</button>
                                        </div>
                                    )}
                                    {options.length - 1 === index && (
                                        <div className="text-center">
                                            <button onClick={onAddOption} type="button" className="btn btn-outline-primary my-3">Add Option</button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        {isEdit && editOptions?.map((option, index) => {
                            return (
                                <div className="mb-3" key={index}>
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <input type="text" name="id" onChange={(e) => onEditOptionChanged(e, index)} value={option.id} className="form-control me-3" hidden />
                                            <label className="mb-1 fw-bold">Status</label>
                                            <select name="is_right" onChange={(e) => onEditOptionChanged(e, index)} value={option.is_right} className="form-select">
                                                <option value={0}>Salah</option>
                                                <option value={1}>Benar</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-10">
                                            <label className="mb-1 fw-bold">Isi</label>
                                            <textarea name="content" onChange={(e) => onEditOptionChanged(e, index)} value={option.content} className="form-control" placeholder="Masukkan jawaban" rows={3} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="text-end">
                            <button onClick={resetAllState} type="reset" className="btn text-muted me-2 btn-reset-question" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" className="btn btn-primary btn-rounded">Save</button>
                        </div>
                    </form>
                </Modal>
                {/* End of Modal Form Question */}
            </Layout>
        </>
    );
};

export default EditCourseCurriculum;
