import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteOccupation, getOccupation, postOccupation, updateOccupation } from "../../../features/user/occupationSlice";
import { formatDate } from "../../../utils/helper";
import Input from "../../global/Inputs/Input/Input";
import InputTextArea from "../../global/Inputs/InputTextArea/InputTextArea";
import Modal from "../../global/Modal/Modal";

const SectionOccupations = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { occupations, loadingOccupation } = useSelector((state) => ({ ...state.occupation }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [isEdit, setIsEdit] = useState(false);
    const [limit, setLimit] = useState(3);
    const [isStillWorking, setIsStillWorking] = useState(false);

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEdit) {
            reset({
                id: '',
                title: '',
                company: '',
                description: '',
                date_start: '',
                date_end: '',
                letter: ''
            });
            setIsStillWorking(false);
        }
    }, [isEdit, reset]);

    const onSubmit = (payload) => {
        dispatch(postOccupation(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                setIsStillWorking(false);
                reset();
            });
        });
    };

    const onEdit = (id) => {
        setIsEdit(true)
        dispatch(getOccupation(id)).then((result) => {
            reset({
                id: id,
                title: result.payload.title,
                company: result.payload.company,
                description: result.payload.description,
                date_start: result.payload.date_start,
                date_end: result.payload.date_end,
                letter: result.payload.letter
            });
            setIsStillWorking(result.payload.date_end === 'undefined' ? true : false)
        });
    };

    const onUpdate = (payload) => {
        let data = {};
        if (isStillWorking) {
            data = {
                ...payload,
                date_end: 'undefined'
            };
        } else {
            data = { ...payload };
        };

        dispatch(updateOccupation(data)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    const onDelete = (id) => {
        MySwal.fire({
            title: <strong>Delete?</strong>,
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteOccupation(id)).then((result) => {
                    MySwal.fire({
                        title: <strong>{result.payload.status}</strong>,
                        text: result.payload.message,
                        icon: result.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            }
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">Occupations</h5>
                        {myUser?.username === user?.username ? (
                            <button onClick={() => reset()} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#occupationModal">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        ) : null}
                    </div>
                    {myUser?.username === user?.username ? (
                        <div className="card-body">
                            {loadingOccupation && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingOccupation && !occupations ? (<p>No occupation found. Please add your occupations</p>) : null}
                            {!loadingOccupation ? (
                                <ul className="list-group list-group-flush" id="occupations">
                                    {occupations?.map((occupation, index) => {
                                        return (
                                            <li className="list-group-item px-0 py-3 text-muted" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col my-auto">
                                                        <h5 className="h6">{occupation.title}</h5>
                                                        <p className="m-auto"><small>{occupation.company} | {formatDate(occupation.date_start)} - {occupation.date_end !== "undefined" ? formatDate(occupation.date_end) : 'Sekarang'}</small></p>
                                                    </div>
                                                    <div className="col-auto my-auto">
                                                        <button onClick={() => onEdit(occupation.id)} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#occupationModal">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button onClick={() => onDelete(occupation.id)} type="button" className="btn text-muted">
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingOccupation && occupations?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="card-body">
                            {loadingUser && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingUser && user?.occupations?.length < 1 ? (<p>No occupation found.</p>) : null}
                            {!loadingUser ? (
                                <ul className="list-group list-group-flush" id="occupations">
                                    {user?.occupations?.map((occupation, index) => {
                                        return (
                                            <li className="list-group-item px-0 py-3 text-muted" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col my-auto">
                                                        <h5 className="h6">{occupation.title}</h5>
                                                        <p className="m-auto"><small>{occupation.company} | {formatDate(occupation.date_start)} - {occupation.date_end !== "undefined" ? formatDate(occupation.date_end) : 'Sekarang'}</small></p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingUser && user?.occupations?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
            <Modal
                id="occupationModal"
                title={!isEdit ? 'Add Occupation' : 'Edit Occupation'}
                onCloseModal={() => setIsEdit(false)}
                backdrop="static"
            >
                <form onSubmit={handleSubmit(!isEdit ? onSubmit : onUpdate)}>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="company"
                            label="Company"
                            placeholder="ex. Codepolitan"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="title"
                            label="Role"
                            placeholder="ex. Frontend Developer"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <InputTextArea
                            name="description"
                            label="Description"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <Input
                                type="date"
                                name="date_start"
                                label="Date Start"
                                isRequired
                                register={register}
                                errors={errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="date"
                                name="date_end"
                                label="Date End"
                                readOnly={isStillWorking}
                                register={register}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="mb-3 form-check">
                        <input onClick={() => setIsStillWorking(!isStillWorking)} type="checkbox" className="form-check-input" id="exampleCheck1" checked={isStillWorking} />
                        <label className="form-check-label" htmlFor="exampleCheck1">Still working here</label>
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="letter"
                            label="Letter"
                            placeholder="https://drive.google.com/yourfile"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button onClick={() => setIsEdit(false)} type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingOccupation}>
                            {loadingOccupation && 'Please wait...'}
                            {!loadingOccupation && isEdit && 'Update'}
                            {!loadingOccupation && !isEdit && 'Submit'}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionOccupations;