import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import { useDispatch, useSelector } from 'react-redux';
import { getInteractiveCodingCourses } from '../../../features/courses/interactiveCodingSlice';
import SectionInteractiveCoding from '../../../components/learn/Section/SectionInteractiveCoding/SectionInteractiveCoding';

const InteractiveCoding = () => {
    const dispatch = useDispatch()
    const { interactiveCodingCourses, loadingInteractiveCodingCourse } = useSelector((state) => ({ ...state.interactiveCodingCourse }))

    useEffect(() => {
        dispatch(getInteractiveCodingCourses());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Playground</title>
            </Helmet>
            <Layout>
                <section id="learnWebinar" className="section mt-5">
                    <div className="container-fluid">
                        <SectionInteractiveCoding
                            data={interactiveCodingCourses}
                            loading={loadingInteractiveCodingCourse}
                        />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default InteractiveCoding;
