import { faFacebook, faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEdit, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updateSocmed } from "../../../features/user/userSlice";
import Input from "../../global/Inputs/Input/Input";
import Modal from "../../global/Modal/Modal";

const SectionSocialMedia = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadingUser && user) {
            reset({
                web: user?.web,
                facebook: user?.facebook,
                linkedin: user?.linkedin,
                github: user?.github,
                instagram: user?.instagram
            });
        }
    }, [loadingUser, user, reset]);

    const onSubmit = (payload) => {
        dispatch(updateSocmed(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">Social Media</h5>
                        {myUser?.username === user?.username ? (
                            <button className="btn text-muted" data-bs-toggle="modal" data-bs-target="#editSocmed">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        ) : null}
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            {user?.web && (
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-start">
                                        <a className="link" href={user?.web} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon fixedWidth className="my-auto me-2" icon={faGlobe} />
                                            {loadingUser ? 'Loading' : user?.web || '-'}
                                        </a>
                                    </div>
                                </li>
                            )}
                            {user?.github && (
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-start">
                                        <a className="link" href={user?.github} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon fixedWidth className="my-auto me-2" icon={faGithub} />
                                            {loadingUser ? 'Loading' : user?.github || '-'}
                                        </a>
                                    </div>
                                </li>
                            )}
                            {user?.linkedin && (
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-start">
                                        <a className="link" href={user?.linkedin} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon fixedWidth className="my-auto me-2" icon={faLinkedin} />
                                            {loadingUser ? 'Loading' : user?.linkedin || '-'}
                                        </a>
                                    </div>
                                </li>
                            )}
                            {user?.facebook && (
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-start">
                                        <a className="link" href={user?.facebook} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon fixedWidth className="my-auto me-2" icon={faFacebook} />
                                            {loadingUser ? 'Loading' : user?.facebook || '-'}
                                        </a>
                                    </div>
                                </li>
                            )}
                            {user?.instagram && (
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-start">
                                        <a className="link" href={user?.instagram} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon fixedWidth className="my-auto me-2" icon={faInstagram} />
                                            {loadingUser ? 'Loading' : user?.instagram || '-'}
                                        </a>
                                    </div>
                                </li>
                            )}
                        </ul>
                        {!user?.web && !user?.github && !user?.linkedin && !user?.facebook && !user?.instagram ? (<p>No social media</p>) : null}
                    </div>
                </div>
            </section>
            <Modal
                id="editSocmed"
                title="Edit Social Media"
                backdrop
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="web"
                            label="Website"
                            placeholder="https://yourwebsite.com"
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="github"
                            label="Github"
                            placeholder="https://github.com/yourusername"
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="linkedin"
                            label="Linkedin"
                            placeholder="https://linkedin.com/in/yourusername"
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="facebook"
                            label="Facebook"
                            placeholder="https://facebook.com/yourusername"
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="instagram"
                            label="Instagram"
                            placeholder="https://instagram.com/yourusername"
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingUser}>{loadingUser ? 'Please wait...' : 'Update'}</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionSocialMedia;