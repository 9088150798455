import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteSkill, getSkill, postSkill, updateSkill } from "../../../features/user/skillSlice";
import InputSelect from "../../global/Inputs/InputSelect/InputSelect";
import Modal from "../../global/Modal/Modal";

const SectionSkills = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { skills, loadingSkill, skillOptions } = useSelector((state) => ({ ...state.skill }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [isEdit, setIsEdit] = useState(false);
    const [limit, setLimit] = useState(3);

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEdit) {
            reset({
                id: '',
                skill: '',
                level: ''
            });
        }
    }, [isEdit, reset]);

    const onSubmit = (payload) => {
        dispatch(postSkill(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                reset();
            });
        });
    };

    const onEdit = (id) => {
        setIsEdit(true)
        dispatch(getSkill(id)).then((result) => {
            reset({
                id: id,
                skill: result.payload.skill,
                level: result.payload.level
            });
        });
    };

    const onUpdate = (payload) => {
        dispatch(updateSkill(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    const onDelete = (id) => {
        MySwal.fire({
            title: <strong>Delete?</strong>,
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSkill(id)).then((result) => {
                    MySwal.fire({
                        title: <strong>{result.payload.status}</strong>,
                        text: result.payload.message,
                        icon: result.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            }
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">Skills</h5>
                        {myUser?.username === user?.username ? (
                            <button onClick={() => reset()} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#skillModal">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        ) : null}
                    </div>
                    {myUser?.username === user?.username ? (
                        <div className="card-body">
                            {loadingSkill && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingSkill && !skills ? (<p>No skill found.</p>) : null}
                            {!loadingSkill && skills ? (
                                <ul className="list-group list-group-flush" id="skills">
                                    {skills?.map((skill, index) => {
                                        return (
                                            <li className="list-group-item px-0 py-3" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col my-auto">
                                                        <h5 className="h6 text-muted">{skill.skill}</h5>
                                                    </div>
                                                    <div className="col-auto my-auto">
                                                        {skill.level.toLowerCase() === 'beginner' ? (
                                                            <div className="d-flex">
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-light d-block mx-1" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-light d-block" style={{ width: '40px', height: '5px' }} />
                                                            </div>
                                                        ) : null}
                                                        {skill.level.toLowerCase() === 'intermediate' ? (
                                                            <div className="d-flex">
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-primary d-block mx-1" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-light d-block" style={{ width: '40px', height: '5px' }} />
                                                            </div>
                                                        ) : null}
                                                        {skill.level.toLowerCase() === 'advance' ? (
                                                            <div className="d-flex">
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-primary d-block mx-1" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                            </div>
                                                        ) : null}
                                                        <p className="m-auto text-end text-capitalize"><small>{skill.level}</small></p>
                                                    </div>
                                                    <div className="col-auto my-auto">
                                                        <button onClick={() => onEdit(skill.id)} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#skillModal">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button onClick={() => onDelete(skill.id)} type="button" className="btn text-muted">
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingSkill && skills?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="card-body">
                            {loadingUser && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingUser && user?.skills?.length < 1 ? (<p>No skill found.</p>) : null}
                            {!loadingSkill ? (
                                <ul className="list-group list-group-flush" id="skills">
                                    {user?.skills?.map((skill, index) => {
                                        return (
                                            <li className="list-group-item px-0 py-3" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col my-auto">
                                                        <h5 className="h6 text-muted">{skill.skill}</h5>
                                                    </div>
                                                    <div className="col-auto my-auto">
                                                        {skill.level.toLowerCase() === 'beginner' ? (
                                                            <div className="d-flex">
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-light d-block mx-1" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-light d-block" style={{ width: '40px', height: '5px' }} />
                                                            </div>
                                                        ) : null}
                                                        {skill.level.toLowerCase() === 'intermediate' ? (
                                                            <div className="d-flex">
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-primary d-block mx-1" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-light d-block" style={{ width: '40px', height: '5px' }} />
                                                            </div>
                                                        ) : null}
                                                        {skill.level.toLowerCase() === 'advance' ? (
                                                            <div className="d-flex">
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-primary d-block mx-1" style={{ width: '40px', height: '5px' }} />
                                                                <span className="bg-primary d-block" style={{ width: '40px', height: '5px' }} />
                                                            </div>
                                                        ) : null}
                                                        <p className="m-auto text-end text-capitalize"><small>{skill.level}</small></p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingUser && user?.skills?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
            <Modal
                id="skillModal"
                title={!isEdit ? 'Add Skill' : 'Edit Skill'}
                onCloseModal={() => setIsEdit(false)}
                backdrop="static"
            >
                <form onSubmit={handleSubmit(!isEdit ? onSubmit : onUpdate)}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <InputSelect
                                name="skill"
                                label="Skill"
                                isRequired
                                register={register}
                                errors={errors}
                            >
                                {skillOptions?.map((option) => {
                                    return (
                                        <option value={option.name} key={option.id}>{option.name}</option>
                                    );
                                })}
                            </InputSelect>
                        </div>
                        <div className="col-md-6">
                            <InputSelect
                                name="level"
                                label="Level"
                                isRequired
                                register={register}
                                errors={errors}
                            >
                                {['Beginner', 'Intermediate', 'Advance'].map((item, index) => {
                                    return (
                                        <option value={item} key={index}>{item}</option>
                                    );
                                })}
                            </InputSelect>
                        </div>
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button onClick={() => setIsEdit(false)} type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingSkill}>
                            {loadingSkill && 'Please wait...'}
                            {!loadingSkill && isEdit && 'Update'}
                            {!loadingSkill && !isEdit && 'Submit'}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionSkills;