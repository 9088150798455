import { faEdit, faLink, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deletePortfolio, getPortfolio, postPortfolio, updatePortfolio } from "../../../features/user/portfoliosSlice";
import { selectYear, uploadImage } from "../../../utils/helper";
import Input from "../../global/Inputs/Input/Input";
import InputFile from "../../global/Inputs/InputFile/InputFile";
import InputSelect from "../../global/Inputs/InputSelect/InputSelect";
import InputTextArea from "../../global/Inputs/InputTextArea/InputTextArea";
import Modal from "../../global/Modal/Modal";

const SectionPortfolios = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { portfolios, loadingPortfolio } = useSelector((state) => ({ ...state.portfolio }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [isEdit, setIsEdit] = useState(false);
    const [limit, setLimit] = useState(3);

    const [screenshot, setScreenshot] = useState('');
    const [screenshotLoading, setScreenshotLoading] = useState(false);

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEdit) {
            reset({
                id: '',
                project_name: '',
                year: '',
                description: '',
                attachment: '',
                role_name: ''
            });
            setScreenshot('');
        }
    }, [isEdit, reset]);

    const onSubmit = (payload) => {
        dispatch(postPortfolio({ payload, screenshot })).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                reset();
                setScreenshot('');
            });
        });
    };

    const onEdit = (id) => {
        setIsEdit(true)
        dispatch(getPortfolio(id)).then((result) => {
            reset({
                id: id,
                project_name: result.payload.project_name,
                year: result.payload.year,
                description: result.payload.description,
                attachment: result.payload.attachment,
                role_name: result.payload.role_name
            });
            setScreenshot(result.payload.thumbnail);
        });
    };

    const onUpdate = (payload) => {
        dispatch(updatePortfolio({ payload, screenshot })).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    const onDelete = (id) => {
        MySwal.fire({
            title: <strong>Delete?</strong>,
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePortfolio(id)).then((result) => {
                    MySwal.fire({
                        title: <strong>{result.payload.status}</strong>,
                        text: result.payload.message,
                        icon: result.payload.status === 'success' ? 'success' : 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            }
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">Projects</h5>
                        {myUser?.username === user?.username ? (
                            <button onClick={() => { reset(); setScreenshot('') }} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#portfolioModal">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        ) : null}
                    </div>
                    {myUser?.username === user?.username ? (
                        <div className="card-body">
                            {loadingPortfolio && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingPortfolio && portfolios?.length < 1 ? (<p>No project found. Please add your projects</p>) : null}
                            {!loadingPortfolio ? (
                                <ul className="list-group list-group-flush" id="portfolios">
                                    {portfolios?.map((portfolio, index) => {
                                        return (
                                            <li className="list-group-item px-0 text-muted py-3" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col-md-5 mb-3 mb-lg-auto">
                                                        <img className="img-fluid" src={portfolio.thumbnail} alt={portfolio.project_name} />
                                                    </div>
                                                    <div className="col my-auto">
                                                        <h5>{portfolio.project_name}</h5>
                                                        <p>{portfolio.description}</p>
                                                        <p className="m-0">Role: {portfolio.role_name}</p>
                                                        <p>Year: {portfolio.year}</p>
                                                        <a className="link" href={portfolio.attachment} target="_blank" rel="noopener noreferrer">
                                                            <FontAwesomeIcon icon={faLink} /> See attachment
                                                        </a>
                                                        <div className="d-flex justify-content-end">
                                                            <button onClick={() => onEdit(portfolio.id)} type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#portfolioModal">
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            <button onClick={() => onDelete(portfolio.id)} type="button" className="btn text-muted">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).reverse().slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingPortfolio && portfolios?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="card-body">
                            {loadingUser && [1, 2, 3].map(item => (
                                <div className="row mb-3" key={item}>
                                    <div className="col">
                                        <Skeleton width="60%" />
                                        <Skeleton width="30%" />
                                    </div>
                                </div>
                            ))}
                            {!loadingUser && user?.portfolios?.length < 1 ? (<p>No project found.</p>) : null}
                            {!loadingUser ? (
                                <ul className="list-group list-group-flush" id="portfolios">
                                    {user?.portfolios?.map((portfolio, index) => {
                                        return (
                                            <li className="list-group-item px-0 text-muted py-3" key={index}>
                                                <div className="row justify-content-between">
                                                    <div className="col-md-5 mb-3 mb-lg-auto">
                                                        <img className="img-fluid" src={portfolio.thumbnail} alt={portfolio.project_name} />
                                                    </div>
                                                    <div className="col my-auto">
                                                        <h5>{portfolio.project_name}</h5>
                                                        <p>{portfolio.description}</p>
                                                        <p className="m-0">Role: {portfolio.role_name}</p>
                                                        <p>Year: {portfolio.year}</p>
                                                        <a className="link" href={portfolio.attachment} target="_blank" rel="noopener noreferrer">
                                                            <FontAwesomeIcon icon={faLink} /> See attachment
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }).reverse().slice(0, limit)}
                                </ul>
                            ) : null}
                            {!loadingUser && user?.portfolios?.length > limit && (
                                <div className="text-center">
                                    <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
            <Modal
                id="portfolioModal"
                title={!isEdit ? 'Add portfolio' : 'Edit portfolio'}
                onCloseModal={() => setIsEdit(false)}
                backdrop="static"
            >
                <form onSubmit={handleSubmit(!isEdit ? onSubmit : onUpdate)}>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="project_name"
                            label="Project Name"
                            placeholder="ex. Codepolitan V3"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="role_name"
                            label="Role"
                            placeholder="ex. Backend Developer"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <InputTextArea
                            name="description"
                            label="Description"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <InputSelect
                            name="year"
                            label="Year"
                            isRequired
                            register={register}
                            errors={errors}
                        >
                            {selectYear.map((option, index) => {
                                return (
                                    <option value={option} key={index}>{option}</option>
                                );
                            })}
                        </InputSelect>
                    </div>
                    <div className="mb-3">
                        <Input
                            type="text"
                            name="attachment"
                            label="Attachment Url"
                            placeholder="ex. https://codepolitan.com"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="mb-3">
                        <InputFile
                            type="image"
                            title="Screenshot"
                            loading={screenshotLoading}
                            value={screenshot}
                            setValue={setScreenshot}
                            onChangeValue={(e) => uploadImage(e, setScreenshot, setScreenshotLoading)}
                        />
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button onClick={() => setIsEdit(false)} type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingPortfolio}>
                            {loadingPortfolio && 'Please wait...'}
                            {!loadingPortfolio && isEdit && 'Update'}
                            {!loadingPortfolio && !isEdit && 'Submit'}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionPortfolios;