import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CardExercise from '../../../components/exercises/Cards/CardExercise/CardExercise';
import Layout from "../../../components/global/Layout/Layout";
import { getQuizzesByTag } from '../../../features/quiz/quizSlice';
import { capitalizeFirstLetter } from '../../../utils/helper';

const ExercisesByTag = () => {
    const location = useLocation();
    const lastSegment = location.pathname.split('/').pop();

    const dispatch = useDispatch();
    const { quizzesByTag, loadingQuiz } = useSelector((state) => ({ ...state.quiz }));

    useEffect(() => {

        if (lastSegment) {
            dispatch(getQuizzesByTag(lastSegment));
        }
    }, [dispatch, lastSegment]);

    return (
        <>
            <Helmet>
                <title>Exercise HTML</title>
            </Helmet>
            <Layout>
                <section className="section mt-5" id="learnHome">
                    <div className="container-fluid">
                        <h4 className="section-title">Exercise by tag {capitalizeFirstLetter(lastSegment)}</h4>

                        <div className="row my-4">
                            {!loadingQuiz && quizzesByTag?.length < 1 ? (
                                <div className="col-12 text-center my-5">
                                    <img height="250" width="250" className="img-fluid" src="/assets/img/icon-not-found.png" alt="Opps..." />
                                    <p className='my-3'>Tidak ada hasil</p>
                                </div>
                            ) : null}
                            {!loadingQuiz && quizzesByTag?.length > 0 && quizzesByTag?.map((quiz) => {
                                return (
                                    <div className="col-md-6 col-lg-4 col-xl-3 p-2 mb-3" key={quiz.id}>
                                        <Link className="link" to={`/exercises/detail/${quiz.slug}`}>
                                            <CardExercise
                                                thumbnail={quiz.cover}
                                                title={quiz.title}
                                                level={quiz.level}
                                                totalStudents={quiz.total_students}
                                                duration={quiz.duration}
                                                totalQuestions={quiz.total_questions}
                                                price={quiz.price}
                                            />
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default ExercisesByTag;
