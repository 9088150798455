import { faFireAlt, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Layout from "../../../components/global/Layout/Layout";
import { getUserPointHistory } from '../../../features/user/leaderboardSlice';
import { formatDate } from '../../../utils/helper';
import Skeleton from 'react-loading-skeleton';

const PointHistory = () => {
    const dispatch = useDispatch();
    const { loadingLeaderboard, userPointHistory } = useSelector(state => state.leaderboards);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(getUserPointHistory(currentPage));
    }, [dispatch, currentPage]);

    return (
        <>
            <Helmet>
                <title>Point History</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <h2 className="section-title h4">Point History</h2>
                        <hr />
                        {loadingLeaderboard && [1, 2, 3, 4, 5].map(item => (
                            <div className="card mb-3" key={item}>
                                <div className="card-body">
                                    <Skeleton width="50%" />
                                    <Skeleton width="30%" />
                                </div>
                            </div>
                        ))}
                        {!loadingLeaderboard && userPointHistory?.length < 1 ? (
                            <p className="text-center text-muted my-5">Belum ada history</p>
                        ) : null}
                        {userPointHistory?.map((point, index) => {
                            return (
                                <div className="card mb-3" key={index}>
                                    <div className="card-body">
                                        <div className="row justify-content-between">
                                            <div className="col">
                                                <h6 className="text-muted" dangerouslySetInnerHTML={{ __html: point.action }} />
                                                <p className="text-muted m-0">{formatDate(point.created_at)}</p>
                                            </div>
                                            <div className="col-auto my-auto">
                                                {point.point > 0 ? (
                                                    <p className="text-primary fw-bolder m-0"><FontAwesomeIcon icon={faFireAlt} /> +{point.point}</p>
                                                ) : (
                                                    <p className="text-danger fw-bolder m-0"><FontAwesomeIcon icon={faFireAlt} /> {point.point}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {userPointHistory?.length > 9 ? (
                            <div className="text-center my-3">
                                <button onClick={() => setCurrentPage((page => page + 1))} type="button" className="btn btn-outline-secondary" disabled={loadingLeaderboard}>
                                    {!loadingLeaderboard ? (
                                        <>
                                            <FontAwesomeIcon className="me-2" icon={faRedo} /> Show more
                                        </>
                                    ) : 'Please wait...'}
                                </button>
                            </div>
                        ) : null}
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default PointHistory;
