import { useState, useEffect } from 'react'
import { faFileLines, faSave, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getMentorProfile, updateMentorProfile } from '../../../features/mentor/mentorSlice'
import Layout from '../../../components/global/Layout/Layout'
import { uploadImage } from '../../../utils/helper'
import Sidebar from '../../../components/mentor/Sidebar/Sidebar'

const Settings = () => {
    const dispatch = useDispatch()
    const { loadingMentor } = useSelector((state) => ({ ...state.mentor }));
    const MySwal = withReactContent(Swal);

    const [brandLogo, setBrandLogo] = useState('');
    const [brandLogoLoading, setBrandLogoLoading] = useState(false);

    const [brandImage, setBrandImage] = useState('');
    const [brandImageLoading, setBrandImageLoading] = useState(false);

    // Use react hook form
    const { reset, register, formState: { errors }, handleSubmit } = useForm();
    const onSubmit = async (data) => {
        const payload = data
        payload.brand_logo = brandLogo
        payload.brand_image = brandImage
        dispatch(updateMentorProfile(payload))
            .then((res) => {
                if (res.payload.data.status === 'Success') {
                    // Alert Success
                    MySwal.fire({
                        title: <strong>Success!</strong>,
                        text: res.payload.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    // Alert Failed
                    MySwal.fire({
                        title: <strong>Oops!</strong>,
                        text: res.payload.data.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        dispatch(getMentorProfile()).then(({ payload }) => {
            // Prefill data input
            const mentor = payload
            const inputForm = {
                brand_name: mentor.brand_name,
                brand_headline: mentor.brand_headline,
                brand_video: mentor.brand_video,
                brand_description: mentor.brand_description,
                schedule: mentor.schedule,
                jobs: mentor.jobs,
                web: mentor.web,
                linkedin: mentor.linkedin,
                github: mentor.github,
            }
            setBrandLogo(mentor.brand_logo)
            setBrandImage(mentor.brand_image)
            reset({ ...inputForm })
        })
    }, [dispatch, reset])

    return (
        <>
            <Helmet>
                <title>Mentor</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            {/* Sidebar */}
                            <div className="col-lg-2">
                                <Sidebar />
                            </div>
                            {/* End of Sidebar */}

                            {/* Main Content */}
                            <div className="col-lg-10 text-muted px-4 px-lg-5">
                                <section>
                                    <h4 className="section-title mb-5">Setting</h4>
                                    <div className="card border-0 shadow-sm p-3">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center bg-light p-3 mb-4">
                                                <FontAwesomeIcon size="xl" icon={faFileLines} />
                                                <h4 className="ms-3 mb-0">Account Information</h4>
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="brandLogo" className="form-label">Logo Brand</label>
                                                            <input className="form-control" type="hidden" value={brandLogo} required />
                                                            <input className="form-control" onChange={(e) => uploadImage(e, setBrandLogo, setBrandLogoLoading)} type="file" id="brandLogo" hidden />
                                                            <div className="input-group">
                                                                <label className="form-control bg-light">{brandLogo && brandLogo.slice(0, 25) + '...'}</label>
                                                                <label className="btn btn-primary rounded" htmlFor="brandLogo"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                                            </div>
                                                            {brandLogoLoading ? (
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                                    <small className="text-muted">Please wait....</small>
                                                                </>
                                                            ) : (
                                                                <img width="100" height="100" className="rounded-pill objectfit-cover my-3" src={brandLogo || "/assets/img/placeholder.jpg"} alt="Brand Logo" />
                                                            )}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="brandImage" className="form-label">Brand Image</label>
                                                            <input className="form-control" type="hidden" value={brandImage} required />
                                                            <input className="form-control" onChange={(e) => uploadImage(e, setBrandImage, setBrandImageLoading)} type="file" id="brandImage" hidden />
                                                            <div className="input-group">
                                                                <label className="form-control bg-light">{brandImage && brandImage.slice(0, 25) + '...'}</label>
                                                                <label className="btn btn-primary rounded" htmlFor="brandImage"><FontAwesomeIcon icon={faUpload} /> Upload</label>
                                                            </div>
                                                            {brandImageLoading ? (
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm me-2 text-muted" role="status" aria-hidden="true" />
                                                                    <small className="text-muted">Please wait....</small>
                                                                </>
                                                            ) : (
                                                                <img width="100" height="100" className="rounded-pill objectfit-cover my-3" src={brandImage || "/assets/img/placeholder.jpg"} alt="Brand" />
                                                            )}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="brandName" className="form-label">Brand Name</label>
                                                            <input {...register("brand_name", { required: true })} type="text" id="brandName" className="form-control py-3" placeholder="Masukan brand name" />
                                                            {errors.brand_name?.type === 'required' && <small className="text-danger">Brand name is required</small>}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="brandHeadline" className="form-label">Brand Headline</label>
                                                            <input {...register("brand_headline", { required: true })} type="text" id="brandHeadline" className="form-control py-3" placeholder="Masukan brand headline" />
                                                            {errors.brand_headline?.type === 'required' && <small className="text-danger">Brand headline is required</small>}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="brandVideo" className="form-label">Brand Video</label>
                                                            <input {...register("brand_video", { required: true })} type="text" id="brandVideo" className="form-control py-3" placeholder="Masukan brand video" />
                                                            {errors.brand_video?.type === 'required' && <small className="text-danger">Brand video is required</small>}
                                                        </div>
                                                        <div className="form-group mb-4 mb-lg-0">
                                                            <label htmlFor="description" className="form-label">Brand Description</label>
                                                            <textarea {...register("brand_description", { required: true })} id="description" cols="30" rows="5" className="form-control" placeholder="Masukan Deskripsi Brand Disini"></textarea>
                                                            {errors.brand_description?.type === 'required' && <small className="text-danger">Brand description is required</small>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-2">
                                                            <label htmlFor="schedule" className="form-label">Schedule</label>
                                                            <input {...register("schedule", { required: true })} type="text" id="schedule" className="form-control py-3" placeholder="Masukan schedule disini" />
                                                            {errors.schedule?.type === 'required' && <small className="text-danger">Schedule is required</small>}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="jobs" className="form-label mt-3">Jobs</label>
                                                            <input {...register("jobs", { required: true })} type="text" id="jobs" className="form-control py-3" placeholder="Masukan nama jobs disini" />
                                                            {errors.jobs?.type === 'required' && <small className="text-danger">Jobs is required</small>}
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="web" className="form-label">Web</label>
                                                            <input {...register("web")} type="text" id="web" className="form-control py-3" placeholder="https://yourweb.com" />
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="linkedin" className="form-label">Linkedin</label>
                                                            <input {...register("linkedin")} type="text" id="linkedin" className="form-control py-3" placeholder="https://linkedin.com/in/yourusername" />
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="github" className="form-label">Github</label>
                                                            <input {...register("github")} type="text" id="github" className="form-control py-3" placeholder="https://github.com/yourusername" />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="d-grid">
                                                                {
                                                                    loadingMentor
                                                                        ? (
                                                                            <button type="submit" className="btn btn-primary py-3 disabled">
                                                                                <div className="spinner-border" role="status"></div>
                                                                            </button>
                                                                        )
                                                                        : (
                                                                            <button type="submit" className="btn btn-primary py-3">
                                                                                <FontAwesomeIcon icon={faSave} className="me-2" />
                                                                                <span className="fw-bold">Simpan</span>
                                                                            </button>
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* End of Main Content */}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Settings