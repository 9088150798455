import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updateShortDescription } from "../../../features/user/userSlice";
import InputTextArea from "../../global/Inputs/InputTextArea/InputTextArea";
import Modal from "../../global/Modal/Modal";

const SectionAbout = () => {
    const { myUser, user, loadingUser } = useSelector((state) => ({ ...state.user }));
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadingUser && user) {
            reset({
                short_description: user?.short_description
            });
        }
    }, [loadingUser, user, reset]);

    const onSubmit = (payload) => {
        dispatch(updateShortDescription(payload)).then((result) => {
            MySwal.fire({
                title: <strong>{result.payload.status}</strong>,
                text: result.payload.message,
                icon: result.payload.status === 'success' ? 'success' : 'error',
                showConfirmButton: false,
                timer: 1500
            });
        });
    };

    return (
        <>
            <section className="bg-white p-3 mb-5">
                <div className="card border-0">
                    <div className="card-header border-0 d-flex justify-content-between">
                        <h5 className="section-title my-2">About me</h5>
                        {myUser?.username === user?.username ? (
                            <button type="button" className="btn text-muted" data-bs-toggle="modal" data-bs-target="#editAbout">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        ) : null}
                    </div>
                    <div className="card-body">
                        <p>
                            {loadingUser && 'Loading...'}
                            {!loadingUser && user?.short_description}
                            {!loadingUser && user?.short_description === '' && 'No description'}
                        </p>
                    </div>
                </div>
            </section>
            <Modal
                id="editAbout"
                title="Edit"
                backdrop
            >
                <p>Paparkan tentang lama pengalaman, industri, atau keahlianmu. Orang juga menceritakan tentang pencapaian atau pengalaman kerja mereka sebelumnya</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <InputTextArea
                            name="short_description"
                            label="About Me"
                            isRequired
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                        <button type="button" className="btn btn-cancel shadow-none" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={loadingUser}>{loadingUser ? 'Please wait...' : 'Update'}</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SectionAbout