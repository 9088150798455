import { useState, useEffect } from 'react';
import axios from 'axios';
import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Footer from '../../../components/global/Footer/Footer';
import NavbarAuth from '../../../components/global/Navbar/NavbarAuth';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { userToken } from '../../../utils/config';

const ChangePassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();

    const MySwal = withReactContent(Swal);

    const [isLoggin, setIsLoggin] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Validation
        if (userToken) {
            setIsLoggin(true);
            navigate('/learn')
        };
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append('new_password', password);
        data.append('confirm_new_password', confirmPassword);
        data.append('token', token);

        let response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/api/user/auth/change_password_action`,
            headers: { "Content-Type": "multipart/form-data" },
            data: data,
        });

        if (response.data.status === "success") {
            setLoading(false);
            setPassword('');
            setConfirmPassword('');

            // Alert Success
            MySwal.fire({
                title: capitalizeFirstLetter(response.data.status),
                text: response.data.message,
                confirmButtonText: 'Login',
                confirmButtonColor: '#14a7a0',
                icon: 'success'
            }).then(() => {
                // navigate('/login');
                window.location.href = 'https://www.codepolitan.com/login';
            });

        } else {
            console.log(response.data);
            setLoading(false);

            // Alert Failed
            MySwal.fire({
                title: capitalizeFirstLetter(response.data.status),
                text: response.data.message,
                confirmButtonText: 'Close',
                confirmButtonColor: '#14a7a0',
                icon: 'error'
            });
        };
    };

    if (isLoggin) return (<Navigate to="/learn" replace />);

    return (
        <>
            <Helmet>
                <title>Change Password</title>
            </Helmet>
            <NavbarAuth />
            <main className="bg-light vh-100" style={{ paddingTop: '150px' }} id="main">
                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 my-auto">
                                <div className="card card-rounded border-0 shadow">
                                    <div className="card-body m-4 m-md-5 rounded-3">
                                        <div className="text-center mb-4">
                                            <h3 className="text-secondary">Change Password</h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faLock} /></span>
                                                <input name="password" onChange={(e) => setPassword(e.target.value)} value={password} type={!showPassword ? 'password' : 'text'} className={`form-control form-control-lg border-start-0 border-end-0`} placeholder="New Password" required />
                                                <span className="input-group-text bg-white border-start-0" onClick={() => setShowPassword(!showPassword)} role="button">
                                                    <FontAwesomeIcon className="text-muted" icon={!showPassword ? faEye : faEyeSlash} title={!showPassword ? 'Show Password' : 'Hide Password'} />
                                                </span>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text bg-white border-end-0"><FontAwesomeIcon className="text-muted" icon={faLock} /></span>
                                                <input name="confirmPassword" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} type={!showConfirmPassword ? 'password' : 'text'} className={`form-control form-control-lg border-start-0 border-end-0`} placeholder="Retype New Password" required />
                                                <span className="input-group-text bg-white border-start-0" onClick={() => setShowConfirmPassword(!showConfirmPassword)} role="button">
                                                    <FontAwesomeIcon className="text-muted" icon={!showConfirmPassword ? faEye : faEyeSlash} title={!showConfirmPassword ? 'Show Password' : 'Hide Password'} />
                                                </span>
                                            </div>
                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-primary border-0 btn-lg" disabled={loading}>
                                                    {loading && (
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                                                    )}
                                                    {loading ? 'Please wait...' : 'Update'}
                                                </button>
                                            </div>
                                        </form>
                                        <div className="text-center mt-4">
                                            <a className="link" href="https://www.codepolitan.com/login">
                                                Already have an account? Login
                                            </a>
                                        </div>
                                        <div className="text-center mt-2">
                                            <Link className="link" to="/register">
                                                Not a member yet? Register
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default ChangePassword;
