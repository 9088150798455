import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/helper";

const SectionCertificates = () => {
    const { myUser, user } = useSelector((state) => ({ ...state.user }));
    const { certificates } = useSelector((state) => ({ ...state.certificates }));

    const [limit, setLimit] = useState(2);

    return (
        <section className="bg-white p-3 mb-5">
            <div className="card border-0">
                <div className="card-header border-0">
                    <div className="row">
                        <div className="col">
                            <h5 className="section-title my-2">Certificates</h5>
                        </div>
                        {myUser?.username === user?.username ? (
                            <div className="col-auto my-auto">
                                <Link className="btn btn-outline-primary btn-sm rounded-2" to="/for-you">
                                    Ikuti Kelas {certificates?.length > 0 ? 'Lainnya' : null}
                                </Link>
                            </div>
                        ) : null}
                    </div>
                </div>
                {myUser?.username === user?.username ? (
                    <div className="card-body">
                        {certificates?.length < 1 ? 'No certificate found.' : null}
                        <ul className="list-group list-group-flush">
                            {certificates?.map((certificate, index) => {
                                return (
                                    <li className="list-group-item px-0 text-muted py-3" key={index}>
                                        <a className="link" href={`https://codepolitan.com/c/${certificate.code}`} target="_blank" rel="noopener noreferrer">
                                            <div className="row">
                                                <div className="col-3">
                                                    <img className="img-fluid" src={certificate.cert_thumbnail || "/assets/img/placeholder.jpg"} alt="Placeholder" />
                                                </div>
                                                <div className="col-9 my-auto">
                                                    <h5 className="h6">{certificate.cert_title}</h5>
                                                    <p>
                                                        <small>
                                                            <FontAwesomeIcon icon={faCalendar} /> Lulus pada {formatDate(certificate.created_at)}
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                );
                            }).slice(0, limit)}
                        </ul>
                        {certificates?.length > limit && (
                            <div className="text-center">
                                <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="card-body">
                        {user?.certificates?.length < 1 ? 'No certificate found.' : null}
                        <ul className="list-group list-group-flush">
                            {user?.certificates?.map((certificate, index) => {
                                return (
                                    <li className="list-group-item px-0 text-muted py-3" key={index}>
                                        <a className="link" href={`https://codepolitan.com/c/${certificate.code}`} target="_blank" rel="noopener noreferrer">
                                            <div className="row">
                                                <div className="col-3">
                                                    <img className="img-fluid" src={certificate.cert_thumbnail || "/assets/img/placeholder.jpg"} alt="Placeholder" />
                                                </div>
                                                <div className="col-9 my-auto">
                                                    <h5 className="h6">{certificate.cert_title}</h5>
                                                    <p>
                                                        <small>
                                                            <FontAwesomeIcon icon={faCalendar} /> Lulus pada {formatDate(certificate.created_at)}
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                );
                            }).slice(0, limit)}
                        </ul>
                        {user?.certificates?.length > limit && (
                            <div className="text-center">
                                <button onClick={() => setLimit(limit + 5)} className="btn btn-outline-secondary btn-sm">Show more</button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    )
}

export default SectionCertificates;