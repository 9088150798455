import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp, faArrowUpRightFromSquare, faCheckCircle, faCheckDouble, faCircleExclamation, faComments, faEdit, faHome, faPaperPlane, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { customToolbar, decodeHtml, formatDateDiscussion, formatDay } from "../../../utils/helper"

import { useDispatch, useSelector } from 'react-redux';
import { commentDiscussion, getDetailDiscussion, replyDiscussion, reportDiscussion, updateReplyDiscussion, updateDiscussion, deleteDiscussionReply, updateCommentDiscussion, deleteDiscussionComment, deleteDiscussion, markAsBestReply } from '../../../features/discussion/discussionSlice';
import Modal from '../../../components/global/Modal/Modal';
import Swal from 'sweetalert2';

import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

import { CodeBlock } from '../../../components/global/CodeBlock';
import ReactDOMServer from "react-dom/server";

// Mixpanel
import mixpanel from "mixpanel-browser";
import { userId, userRole } from '../../../utils/config';
mixpanel.init("608746391f30c018d759b8c2c1ecb097");

const DiscussionDetail = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slug } = useParams()
    const { discussion, loadingDiscussion, loadingDelete, loadingMark } = useSelector(state => state.discussion)

    const editorRef = useRef();

    const [readFirst, setReadFirst] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    // See comment
    const [comment, setComment] = useState("")
    const [showComment, setShowComment] = useState(null)
    const [commentID, setCommentID] = useState(null)

    // Edit reply
    const [inputEditReply, setInputEditReply] = useState("")
    const [editReplyID, setEditReplyID] = useState(null)
    const onChangeEditReply = (value) => {
        setInputEditReply(value)
    }
    const handleEditReply = (content, id) => {
        setTimeout(() => {
            setInputEditReply(decodeHtml(content));
            setEditReplyID(id)
        }, 300)
    }


    // Edit comment
    const [inputEditComment, setInputEditComment] = useState("")
    const [editCommentID, setEditCommentID] = useState(null)
    const onChangeComment = (value) => {
        setComment(value);
    };

    const onChangeEditComment = useCallback((value) => {
        setInputEditComment(value);
    }, []);

    const handleEditComment = (content, id) => {
        setTimeout(() => {
            setInputEditComment(decodeHtml(content));
            setEditCommentID(id);
        }, 300)
    }

    // Comment thread
    const [replyValue, setReplyValue] = useState("")
    const [replyID, setReplyID] = useState(null)
    const onChangeReply = (value) => {
        setReplyValue(value)
    }

    // New reply
    const [inputThreadSubject, setInputThreadSubject] = useState("")
    const [inputThreadContent, setInputThreadContent] = useState("")
    const onChangeInputThreadContent = (value) => {
        setInputThreadContent(value);
    };

    // Report thread input
    const [inputTypeReport, setInputTypeReport] = useState("spam")
    const [inputNoteReport, setInputNoteReport] = useState("")

    useEffect(() => {
        dispatch(getDetailDiscussion(slug))
    }, [dispatch, slug])

    // Delete Thread
    const deleteThread = (thread_id) => {
        Swal.fire({
            title: 'Konfirmasi',
            text: "Pertanyaan tidak dapat dikembalikan seperti semula, lanjutkan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDiscussion({
                    thread_id,
                    slug: discussion.detail.slug
                })).then(res => {
                    if (res.payload.status === 'success') {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Berhasil',
                            text: 'Pertanyaan berhasil dihapus',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            navigate('/learn/discussions')
                        })
                    }
                })
            }
        })
    }

    // Delete Reply
    const deleteReply = (thread_id, reply_id) => {
        setEditReplyID(reply_id)

        Swal.fire({
            title: 'Konfirmasi',
            text: "Balasan tidak dapat dikembalikan seperti semula, lanjutkan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDiscussionReply({
                    thread_id,
                    reply_id,
                    slug: discussion.detail.slug
                })).then(res => {
                    if (res.payload.status === 'success') {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Berhasil',
                            text: 'Balasan berhasil dihapus',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })
            }
        })
    }

    // Delete Comment
    const deleteComment = (comment_id) => {
        setEditCommentID(comment_id)

        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak akan dapat mengembalikan ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDiscussionComment({
                    comment_id,
                    slug: discussion.detail.slug
                })).then(res => {
                    if (res.payload.status === 'success') {
                        Swal.fire({
                            position: 'top-center',
                            icon: 'success',
                            title: 'Berhasil',
                            text: 'Komentar berhasil dihapus',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })
            }
        })
    }

    const onSubmitComment = (e) => {
        e.preventDefault()
        dispatch(commentDiscussion({
            thread_id: discussion.detail.id,
            comment_content: comment,
            object_id: commentID,
            object_type: "reply",
            slug: discussion.detail.slug
        })).then(res => {
            setComment("")
        })
    }

    const onSubmitEditComment = (e) => {
        e.preventDefault()
        dispatch(updateCommentDiscussion({
            id: editCommentID,
            comment_content: inputEditComment,
            slug: discussion.detail?.slug
        })).then(res => {
            if (res.payload.status === 'success') {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Komentar anda berhasil diupdate',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const onSubmitEditReply = (e) => {
        e.preventDefault()

        dispatch(updateReplyDiscussion({
            id: editReplyID,
            reply_content: inputEditReply,
            slug: discussion.detail?.slug
        })).then(res => {
            if (res.payload.status === 'success') {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Balasan anda berhasil diupdate',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const onSubmitReport = (e) => {
        e.preventDefault()
        dispatch(reportDiscussion({
            meta: "Sample",
            issue: inputTypeReport,
            note: inputNoteReport,
            object_id: discussion.detail.id,
            object_type: "forum_thread",
            slug: discussion.detail.slug
        })).then(res => {
            if (res.payload.status === 'success') {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Laporan berhasil terkirim',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }


    const onSubmitReply = (e) => {
        e.preventDefault()

        dispatch(replyDiscussion({
            thread_id: discussion.detail.id,
            reply_mode: 'md',
            reply_content: replyValue,
            slug: discussion.detail.slug
        })).then(res => {
            if (res.payload.status === 'success') {
                window.location.href = "#reply";
                // mixpanel.track(`Jawab : ${discussion.detail.id}`, { 'source': 'Dashboard V2' });
            };
            setReplyValue("");
        });
    };

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(updateDiscussion({
            id: discussion.detail?.id,
            thread_subject: inputThreadSubject,
            thread_content: inputThreadContent,
            slug: discussion.detail?.slug
        })).then((response) => {
            Swal.fire({
                position: 'top-center',
                icon: response.payload.status === 'success' ? 'success' : 'error',
                title: response.payload.status === 'success' ? 'Berhasil' : 'Gagal',
                text: response.payload.status === 'success' ? 'Pertanyaan berhasil diupdate' : response.payload.message,
            })
        })
    }

    const markBestReply = (thread_id, reply_id) => {
        setReplyID(reply_id)
        dispatch(markAsBestReply({
            thread_id,
            reply_id,
            slug: discussion.detail.slug
        }))
    };

    const options = useMemo(() => {
        return {
            autofocus: false,
            spellChecker: false,
            showIcons: ['code'],
            hideIcons: ['fullscreen', 'side-by-side'],
            toolbar: customToolbar,
            previewRender: (text) => {
                return ReactDOMServer.renderToString(
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw, rehypeSanitize]}
                        children={text}
                    />
                )
            }
        };
    }, []);

    if (loadingDiscussion) {
        return (
            <>
                <Helmet>
                    <title>Please wait...</title>
                </Helmet>
                <Layout>
                    <section className="text-muted vh-100 d-flex flex-column justify-content-center align-items-center">
                        <div className="container-fluid text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p>Please wait...</p>
                        </div>
                    </section>
                </Layout>
            </>
        );
    } else if (!loadingDiscussion && discussion?.length === 0) {
        navigate('/404-not-found', { replace: true })
    };

    return (
        <>
            <Helmet>
                <title>{discussion.detail?.subject}</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">


                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/learn" className="link text-primary"><FontAwesomeIcon icon={faHome} /></Link></li>
                                <li className="breadcrumb-item"><Link to="/learn/discussions" className="link text-primary">Questions</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{discussion.detail?.subject}</li>
                            </ol>
                        </nav>

                        <h4 className="mt-5">{discussion.detail?.subject}</h4>
                        <div className="d-flex flex-column flex-lg-row mb-4">

                            <small>Ditanyakan pada:
                                <a className="link ms-2" href={`/learn/courses/${discussion.detail?.course_slug}/lessons/${discussion.detail?.lesson_id}`} target="_blank" rel="noopener noreferrer">
                                    <u>{discussion.detail?.lesson_title}</u>
                                </a>
                            </small>

                            <div className="ms-lg-auto mt-3 mb-2 mt-lg-0 mb-lg-0">

                                <Link to={`/learn/courses/detail/${discussion.detail?.course_slug}`} className="link ms-auto">
                                    <small className="text-decoration-underline me-1">See course</small>
                                    <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} />
                                </Link>

                                {
                                    userId !== discussion.detail?.user_id && (
                                        <>
                                            <span className="link mx-3" data-bs-toggle="modal" data-bs-target="#reportModal">
                                                <small className="text-decoration-underline me-1">Report</small>
                                            </span>
                                            <span onClick={() => setReadFirst(!readFirst)} className={`link ${readFirst && 'text-primary'}`}>
                                                <small className="text-decoration-underline me-1">Read this first</small>
                                            </span>
                                        </>
                                    )
                                }
                            </div>
                        </div>

                        {
                            userId !== discussion.detail?.user_id && readFirst && (
                                <div className="card border-0 shadow-sm px-2 my-4">
                                    <div className="card-body">
                                        <h5 className="bg-primary p-2 text-white rounded">Simak panduan berikut ini, PENTING!</h5>
                                        <div className="px-2">
                                            <p>Panduan ini ditujukan agar pertanyaan kamu mudah untuk dimengerti oleh member/tim Codepolitan yang ingin membantu menjawab permasalahan belajar kamu.</p>
                                            <ul>
                                                <li>Gunakan bahasa yang baik dan benar</li>
                                                <li>Beri judul yang sesuai dan deskriptif</li>
                                                <li>Jelaskan masalah dengan detail</li>
                                                <li>Sediakan link screenshot error/link source code agar memudahkan orang lain dalam menjawab pertanyaanmu</li>
                                            </ul>
                                            <ul style={{ listStyle: 'circle inside none' }}>
                                                <li>Link untuk upload gambar: <a className="link text-primary" href="https://postimages.org" target="_blank" rel="noopener noreferrer">postimages.org</a></li>
                                                <li>Link untuk upload source code: <a className="link text-primary" href="https://pastebin.com" target="_blank" rel="noopener noreferrer">Pastebin</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            )
                        }

                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                {
                                    alertSuccess && (
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" size="lg" />
                                            <span>{alertMessage}</span>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                    )
                                }

                                <div className="bg-light p-3">
                                    <div className="row justify-content-center justify-content-md-start">
                                        <div className="col-md-auto pe-0">
                                            <img src={discussion.detail?.avatar || "https://image.web.id/images/avatar4.jpg" || '/assets/img/placeholder-avatar.png'} width={50} height={50} className="d-block mx-auto rounded-circle objectfit-cover" alt="Avatar" />
                                        </div>
                                        <div className="col-auto text-center text-md-start">
                                            <div className="ms-2 my-auto">
                                                <h5 className="m-0">{discussion.detail?.name}</h5>
                                                <p className="mb-0 small text-truncate" style={{ maxWidth: '300px' }}>{discussion.detail?.jobs}</p>
                                                <span className="badge bg-secondary rounded-pill me-1">{discussion.detail?.role_name}</span>
                                                <span className="badge bg-primary rounded-pill">{discussion.detail?.rank?.rank_name}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-auto my-3 my-md-auto text-center text-md-start ms-md-auto">
                                            {discussion.detail?.mark === 'solved' && (
                                                <span className="badge bg-primary"><FontAwesomeIcon icon={faCheckDouble} /> Solved</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3" id="customMarkdown">
                                    <ReactMarkdown
                                        className="markdown"
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                        components={CodeBlock}
                                    >
                                        {discussion.detail?.thread_content}
                                    </ReactMarkdown>
                                </div>
                                <div className="row px-3 justify-content-between">
                                    <div className="col-auto">
                                        <small>{formatDay(discussion.detail?.date)}</small>
                                    </div>
                                    <div className="col-auto">
                                        {userId === discussion.detail?.user_id || userRole === '1' || userRole === '7' ? (
                                            <div className="text-end">
                                                <button onClick={() => deleteThread(discussion.detail?.id)} className="btn btn-sm text-danger me-2" disabled={discussion.detail?.mark === 'solved'}>
                                                    {alertMessage}<FontAwesomeIcon icon={faTrash} className="me-1" />
                                                    <span>Delete</span>
                                                </button>
                                                <button onClick={() => { setInputThreadSubject(discussion.detail.subject); setInputThreadContent(discussion.detail.thread_content) }} className="btn btn-sm text-primary" data-bs-toggle="modal" data-bs-target="#editDiscussionModal" disabled={discussion.detail?.mark === 'solved'}>
                                                    <FontAwesomeIcon icon={faEdit} className="me-1" />
                                                    <span>Edit</span>
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mt-5 mb-3">
                            <h4>{discussion.replies?.length} Jawaban</h4>
                            {
                                userId !== discussion.detail?.user_id && discussion.detail?.mark !== 'solved' && (
                                    <a href="#reply" className="btn btn-primary btn-rounded ms-auto px-4">
                                        <FontAwesomeIcon icon={faComments} size="sm" className="me-2" />
                                        <span>Bantu Jawab</span>
                                    </a>
                                )
                            }
                        </div>

                        <div className="card border-0 shadow-sm mb-5">
                            <div className="card-body">
                                {discussion.replies?.length < 1 && (<p className="text-center text-muted my-auto">Belum ada jawaban</p>)}
                                {
                                    discussion.replies?.map((reply, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="bg-light p-3">
                                                    <div className="row justify-content-center justify-content-md-start">
                                                        <div className="col-md-auto pe-0">
                                                            <img src={reply.avatar || "https://image.web.id/images/avatar4.jpg" || '/assets/img/placeholder-avatar.png'} width={50} height={50} className="d-block mx-auto rounded-circle objectfit-cover" alt="Avatar" />
                                                        </div>
                                                        <div className="col-auto text-center text-md-start my-auto">
                                                            <div className="ms-2 my-auto">
                                                                <h5 className="m-0">{reply.name}</h5>
                                                                <p className="mb-0 small text-truncate" style={{ maxWidth: '300px' }}>{reply.jobs}</p>
                                                                {reply.badge !== 'none' && (<span className="badge bg-secondary rounded-pill me-1">{reply.badge}</span>)}
                                                                <span className="badge bg-primary rounded-pill">{reply.rank?.rank_name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-auto my-3 my-md-auto text-center text-md-start ms-md-auto">
                                                            {reply.mark === 'choosen' && (
                                                                <span className="badge rounded-pill border border-primary text-primary">
                                                                    <img src="/assets/img/learn/like.png" className="me-1" alt="like" />
                                                                    <small>Jawaban Terbaik</small>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-3">
                                                    <div id="customMarkdown">
                                                        <ReactMarkdown
                                                            className="markdown"
                                                            remarkPlugins={[remarkGfm]}
                                                            rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                            components={CodeBlock}
                                                        >
                                                            {decodeHtml(reply.content)}
                                                        </ReactMarkdown>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <small>{formatDateDiscussion(reply.date)}</small>
                                                        <small className="mx-2">|</small>
                                                        {
                                                            showComment === index
                                                                ? (<small className="link text-primary" onClick={() => { setShowComment(null); setCommentID(null) }}>Tutup Komentar <FontAwesomeIcon icon={faAngleDoubleUp} size="xs" /></small>)
                                                                : (<small className="link" onClick={() => { setShowComment(index); setCommentID(reply.id) }}>Lihat Komentar <FontAwesomeIcon icon={faAngleDoubleDown} size="xs" /></small>)
                                                        }
                                                        {
                                                            loadingMark && reply.id === replyID && (
                                                                <small className="ms-2">| tunggu sebentar..</small>
                                                            )
                                                        }
                                                        {
                                                            userId === discussion.detail?.user_id && reply.mark !== 'choosen' && !loadingMark && (
                                                                <>
                                                                    <small className="mx-2">|</small>
                                                                    <small onClick={() => markBestReply(discussion.detail.id, reply.id)} className="link">
                                                                        <span>Tandai Jawaban Terbaik</span>
                                                                    </small>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            userId === reply.user_id || userRole === '1' || userRole === '7' ? (
                                                                <>
                                                                    <small className="mx-2">|</small>
                                                                    <small onClick={() => { handleEditReply(reply.content, reply.id) }} className="link" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                        <FontAwesomeIcon size="sm" icon={faEdit} className="me-1" />
                                                                        <span>Edit</span>
                                                                    </small>
                                                                    <small className="mx-2">|</small>
                                                                    <small onClick={() => { deleteReply(discussion.detail.id, reply.id) }} className="link">
                                                                        <FontAwesomeIcon size="sm" icon={faTrash} className="me-1" />
                                                                        {
                                                                            loadingDelete && reply.id === editReplyID
                                                                                ? (<span>Deleting..</span>)
                                                                                : (<span>Delete</span>)
                                                                        }

                                                                    </small>
                                                                </>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <hr />
                                                    {
                                                        showComment === index && (
                                                            <div className="ps-md-5">
                                                                {
                                                                    reply.comments.length < 1
                                                                        ? (<small className="d-block text-center my-3 text-muted">Belum ada komentar..</small>)
                                                                        : reply.comments.map((comment, index) => {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <div className="bg-light p-3">
                                                                                        <div className="row justify-content-center justify-content-md-start">
                                                                                            <div className="col-md-auto pe-0">
                                                                                                <img src={comment.avatar || "https://image.web.id/images/avatar4.jpg"} width={50} height={50} className="d-block mx-auto rounded-circle objectfit-cover" alt="Avatar" />
                                                                                            </div>
                                                                                            <div className="col-auto text-center text-md-start my-auto">
                                                                                                <div className="ms-2 my-auto">
                                                                                                    <h5 className="m-0">{comment.name}</h5>
                                                                                                    <p className="mb-0 small">{comment.jobs}</p>
                                                                                                    {comment.badge !== 'none' && (
                                                                                                        <span className="badge bg-secondary rounded-pill me-1">{comment.badge}</span>
                                                                                                    )}
                                                                                                    <span className="badge bg-primary rounded-pill">{comment.rank?.rank_name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="p-3">
                                                                                        <div id="customMarkdown">
                                                                                            <ReactMarkdown
                                                                                                className="markdown"
                                                                                                remarkPlugins={[remarkGfm]}
                                                                                                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                                                                components={CodeBlock}
                                                                                            >
                                                                                                {decodeHtml(comment.comment_content)}
                                                                                            </ReactMarkdown>
                                                                                        </div>
                                                                                        <small>{formatDateDiscussion(comment.date)}</small>
                                                                                        {
                                                                                            userId === comment.user_id || userRole === '1' || userRole === '7' ? (
                                                                                                <>
                                                                                                    <small onClick={() => { handleEditComment(comment?.comment_content, comment?.id) }} className="link mx-3" data-bs-toggle="modal" data-bs-target="#editCommentModal"><FontAwesomeIcon icon={faEdit} size="sm" /> Edit</small>
                                                                                                    <small onClick={() => { deleteComment(comment.id) }} className="link">
                                                                                                        <FontAwesomeIcon size="sm" icon={faTrash} className="me-1" />
                                                                                                        {
                                                                                                            loadingDelete && comment.id === editCommentID
                                                                                                                ? (<span>Deleting..</span>)
                                                                                                                : (<span>Delete</span>)
                                                                                                        }

                                                                                                    </small>
                                                                                                </>
                                                                                            ) : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                }

                                                                {
                                                                    discussion.detail?.mark !== 'solved' && (
                                                                        <form onSubmit={onSubmitComment}>
                                                                            <div className="mb-3">
                                                                                <SimpleMDE
                                                                                    options={options}
                                                                                    value={comment}
                                                                                    onChange={onChangeComment}
                                                                                />
                                                                            </div>
                                                                            <div className="text-end">
                                                                                {loadingDiscussion
                                                                                    ? (
                                                                                        <button type="button" className="btn btn-primary disabled">
                                                                                            <div className="spinner-border" role="status"></div>
                                                                                        </button>
                                                                                    )
                                                                                    : (
                                                                                        <button type="submit" className="btn btn-primary text-white">
                                                                                            <FontAwesomeIcon icon={faPaperPlane} className="me-1" />
                                                                                            <span>Kirim</span>
                                                                                        </button>
                                                                                    )}
                                                                            </div>
                                                                        </form>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                        {
                            userId !== discussion.detail?.user_id && discussion.detail?.mark !== 'solved' && (
                                <div id="reply">
                                    <div className="card border-0 shadow-sm p-3">
                                        <div className="card-body">
                                            <div className="d-flex flex-column flex-lg-row gap-3">
                                                <img src="/assets/img/placeholder-avatar.png" className="d-none d-lg-block" width={50} height={50} alt="" />
                                                <div className="d-flex align-items-center d-lg-none">
                                                    <img src="/assets/img/placeholder-avatar.png" width={50} height={50} alt="" />
                                                    <span className="pt-2 pb-3 ms-2">Jawaban Saya</span>
                                                </div>
                                                <div className="d-flex flex-column w-100">
                                                    <span className="pt-2 pb-3 d-none d-lg-inline">Jawaban Saya</span>
                                                    <form onSubmit={onSubmitReply}>
                                                        <SimpleMDE
                                                            value={replyValue}
                                                            onChange={onChangeReply}
                                                            options={options}
                                                        />
                                                        <div className="my-3 text-end">
                                                            {
                                                                loadingDiscussion
                                                                    ? (
                                                                        <button type="button" className="btn btn-primary btn-rounded disabled">
                                                                            <div className="spinner-border" role="status"></div>
                                                                        </button>
                                                                    )
                                                                    : (
                                                                        <button type="submit" className="btn btn-primary btn-rounded" disabled={!replyValue}>
                                                                            <FontAwesomeIcon icon={faComments} className="me-2" />
                                                                            <span>Jawab</span>
                                                                        </button>
                                                                    )
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="modal fade" id="reportModal" tabIndex="-1" aria-labelledby="reportModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content card-rounded text-muted px-2">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="reportModalLabel">Report This Thread</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <form onSubmit={onSubmitReport}>
                                    <div className="modal-body">
                                        <div className="form-group mb-3">
                                            <label htmlFor="type" className="form-label">Type of Report</label>
                                            <select id="type" onChange={(e) => setInputTypeReport(e.target.value)} className="form-select text-muted">
                                                <option value="spam">Spam, Post ini mengandung konten spam</option>
                                                <option value="rude">Kasar, Post ini mengandung konten yang kasar atau tidak membangun</option>
                                                <option value="duplicate">Rangkap, Post serupa kemungkinan sudah pernah ditanyakan pada thread lain</option>
                                                <option value="offtopic">Diluar Topik, Post ini menyimpang dari pembahasan materi</option>
                                                <option value="unclear">Tidak Jelas, Post ini tidak jelas apa yang ditanyakan</option>
                                                <option value="toobroad">Terlalu Luas, Post ini menanyakan hal yang terlalu luas dari pembahasan</option>
                                                <option value="notcompleted">Post ini tidak mencantumkan kode sumber dan screenshot sulit menganalisa solusinya.</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="note" className="form-label">Note</label>
                                            <textarea onChange={(e) => setInputNoteReport(e.target.value)} id="note" cols="30" rows="4" className="form-control" placeholder="Reason why you report it" required></textarea>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                        {
                                            loadingDiscussion
                                                ? (
                                                    <button type="button" className="btn btn-soft-danger btn-rounded disabled">
                                                        <div className="spinner-border" role="status"></div>
                                                    </button>
                                                )
                                                : (
                                                    <button type="submit" className="btn btn-soft-danger btn-rounded" data-bs-dismiss="modal" aria-label="Close">
                                                        <FontAwesomeIcon icon={faCircleExclamation} className="me-1" />
                                                        <span>Report</span>
                                                    </button>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* End of Main Content */}

                    <Modal
                        id="editDiscussionModal"
                        title="Edit Diskusi"
                    >
                        <div className="alert alert-light bg-light" role="alert">
                            <h5 className="alert-heading"><span className="text-danger">Penting:</span> Simak Panduan Berikut ini!</h5>
                            <p>Panduan ini ditujukan agar pertanyaan kamu mudah untuk dimengerti oleh member/tim Codepolitan yang ingin membantu menjawab permasalahan belajar kamu.</p>
                            <ol type="1">
                                <li>Gunakan bahasa yang baik dan benar</li>
                                <li>Beri judul yang sesuai dan deskriptif</li>
                                <li>Jelaskan masalah dengan detail</li>
                                <li>Sediakan link screenshot error / link source code agar memudahkan orang lain dalam menjawab pertanyaanmu. Berikut beberapa sumber daya yang bisa kamu gunakan:</li>
                                <ul>
                                    <li>Link untuk upload gambar: <a className="link text-primary" href="https://postimages.org" target="_blank" rel="noopener noreferrer">postimages.org</a></li>
                                    <li>Link untuk upload source code: <a className="link text-primary" href="https://pastebin.com" target="_blank" rel="noopener noreferrer">Pastebin</a></li>
                                </ul>
                            </ol>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label htmlFor="questionTitle" className="form-label fw-bold">Judul Pertanyaan</label>
                                <input type="text" onChange={(e) => setInputThreadSubject(e.target.value)} className="form-control" id="questionTitle" value={inputThreadSubject} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="questionDescription" className="form-label fw-bold">Detail Pertanyaan</label>
                                <br />
                                <label className="text-muted me-2">Silahkan unggah gambar kamu di link berikut ini</label>
                                <button type="button" onClick={() => window.open('https://postimages.org', '_blank', 'location=yes,height=570,width=700,scrollbars=yes,status=yes')} className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faUpload} /> Upload</button>
                                <br />
                                <label className="form-label text-muted">Setelah mengunggah gambar, salin link gambar ke pertanyaan-mu dibawah ini</label>
                                <SimpleMDE
                                    value={inputThreadContent}
                                    options={options}
                                    onChange={onChangeInputThreadContent}
                                />
                            </div>
                            <div className="mb-3 float-end">
                                {
                                    loadingDiscussion
                                        ? (
                                            <button type="button" className="btn btn-primary btn-lg btn-rounded disabled">
                                                <div className="spinner-border" role="status"></div>
                                            </button>
                                        )
                                        : (<button type="submit" className="btn btn-primary btn-lg btn-rounded" data-bs-dismiss="modal" aria-label="Close">Update Thread</button>)
                                }
                            </div>
                        </form>
                    </Modal>

                    {/* Reply edit modal */}
                    <div className="modal fade mt-5" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Edit Reply</h5>
                                    <button onClick={() => { setAlertMessage(''); setAlertSuccess(false); setInputEditReply("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={onSubmitEditReply}>
                                        <SimpleMDE
                                            options={options}
                                            value={inputEditReply}
                                            onChange={onChangeEditReply}
                                        />
                                        <div className="text-end my-2">
                                            {
                                                loadingDiscussion
                                                    ? (
                                                        <button type="button" className="btn btn-primary btn-rounded disabled">
                                                            <div className="spinner-border" role="status"></div>
                                                        </button>
                                                    )
                                                    : (
                                                        <button className="btn btn-primary btn-rounded" data-bs-dismiss="modal" aria-label="Close">
                                                            <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                                                            <span>Update</span>
                                                        </button>
                                                    )
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Comment edit modal */}
                    <div className="modal fade" id="editCommentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="editCommentModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editCommentModalLabel">Edit Comment</h5>
                                    <button onClick={() => { setAlertMessage(''); setAlertSuccess(false) }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={onSubmitEditComment}>
                                        <div className="mb-3">
                                            <SimpleMDE
                                                ref={editorRef}
                                                options={options}
                                                value={inputEditComment}
                                                onChange={onChangeEditComment}
                                            />
                                        </div>
                                        <div className="text-end">
                                            {loadingDiscussion
                                                ? (
                                                    <button type="button" className="btn btn-primary disabled">
                                                        <div className="spinner-border" role="status"></div>
                                                    </button>
                                                )
                                                : (
                                                    <button type="submit" className="btn btn-primary text-white" data-bs-dismiss="modal" aria-label="Close">
                                                        <FontAwesomeIcon icon={faPaperPlane} className="me-1" />
                                                        <span>Update</span>
                                                    </button>
                                                )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </Layout>
        </>
    );
};

export default DiscussionDetail;
