import { faBook, faCalendar, faCode, faComments, faGears, faGraduationCap, faHome, faHomeAlt, faMap, faMoneyBill, faPenToSquare, faSwatchbook, faTrophy } from "@fortawesome/free-solid-svg-icons";

// Learn
const learnmenus = [
    {
        title: 'Dashboard',
        link: '/learn',
        icon: faHome
    },
    {
        title: 'My Learning',
        link: '/learn/courses',
        icon: faSwatchbook
    },
    {
        title: 'Browse Course',
        link: '/browse-course',
        icon: faBook
    },
    {
        title: 'Questions',
        link: '/learn/discussions',
        icon: faComments
    },
    {
        title: 'Leaderboard',
        link: '/learn/leaderboard',
        icon: faTrophy
    }
];

const foryoumenus = [
    {
        title: 'Events',
        link: '/learn/events',
        icon: faCalendar
    },
    {
        title: 'Thread',
        link: `${process.env.REACT_APP_URL}/komunitas`,
        icon: faPenToSquare,
        isNew: true
    },
    {
        title: 'Playground',
        link: '/learn/interactive-coding',
        icon: faCode
    },
];

// Mentor
const mentormenus = [
    {
        title: 'Overview',
        link: '/mentor',
        icon: faHomeAlt
    },
    {
        title: 'Earnings',
        link: '/mentor/earnings',
        icon: faMoneyBill
    },
    {
        title: 'Courses',
        link: '/mentor/courses',
        icon: faBook
    },
    {
        title: 'Roadmaps',
        link: '/mentor/roadmaps',
        icon: faMap
    },
    {
        title: 'Settings',
        link: '/mentor/settings',
        icon: faGears
    },
];

// Exercise
const exercisemenus = [
    {
        title: 'Overview',
        link: '/exercises/overview',
        icon: faHomeAlt
    },
    {
        title: 'Exercises',
        link: '/exercises',
        icon: faGraduationCap
    }
];

export { foryoumenus, learnmenus, mentormenus, exercisemenus };
