import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionQuickAccess = () => {
    return (
        <section className="section mb-5">
            <h4 className="section-title mb-4">Quick Access</h4>

            <div className="row">
                <div className="col-md-6 col-lg-4 p-2">
                    <a className="link" href="/learn/roadmaps/menjadi-fullstack-web-developer" target="_blank" rel="noopener noreferrer">
                        <div className="card rounded-2 h-100">
                            <div className="card-body">
                                <div className="d-flex mb-2">
                                    <img width={28} className="my-auto" src="/assets/img/fullstack-logo.png" alt="Kelas Fullstack" />
                                    <h6 className="ms-2 my-auto">KelasFullstack</h6>
                                </div>
                                <p className="text-muted small mb-0">Buka materi KelasFullstack dengan cepat disini</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-4 p-2">
                    <a className="link" href="/learn/courses/detail/panduan-member-kelasfullstackid" target="_blank" rel="noopener noreferrer">
                        <div className="card rounded-2 h-100">
                            <div className="card-body">
                                <div className="d-flex mb-2">
                                    <FontAwesomeIcon className="text-primary" size="xl" icon={faTelegram} />
                                    <h6 className="ms-2 my-auto">Grup Telegram</h6>
                                </div>
                                <p className="text-muted small mb-0">Join grup telegram eksklusif Codepolitan disini</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-4 p-2">
                    <a className="link" href="/user/invoice" target="_blank" rel="noopener noreferrer">
                        <div className="card rounded-2 h-100">
                            <div className="card-body">
                                <div className="d-flex mb-2">
                                    <FontAwesomeIcon className="text-primary" size="xl" icon={faFileInvoiceDollar} />
                                    <h6 className="ms-2 my-auto">Riwayat Pembelian</h6>
                                </div>
                                <p className="text-muted small mb-0">Akses Riwayat Pembelian dengan cepat disini</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default SectionQuickAccess;
