import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import SkeletonCardNote from '../../../components/skeletons/SkeletonCardNote';
import CardNote from '../../../components/learn/Card/CardNote/CardNote';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllNotes } from '../../../features/lessons/noteSlice';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const MyNotes = () => {
    const [query, setQuery] = useState('');
    const [limit, setLimit] = useState(10);

    const { allNotes, loadingNotes } = useSelector((state) => ({ ...state.note }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllNotes());
    }, [dispatch]);

    const search = (data) => data?.filter((note) => note.title
        .toLowerCase().includes(query.toLowerCase()) || note.description
            .toLowerCase().includes(query.toLowerCase()));

    return (
        <>
            <Helmet>
                <title>My Notes</title>
            </Helmet>
            <Layout>
                <section className="section mt-5 min-vh-100">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h2 className="section-title h4 mb-5">My Notes</h2>
                            </div>
                            <div className="col-6 col-md-5">
                                <div className="input-group">
                                    <span className="input-group-text bg-white border-end-0 text-muted" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                                    <input onChange={(e) => setQuery(e.target.value)} value={query} type="search" className="form-control border-start-0" placeholder="Cari catatan..." aria-label="search" />
                                </div>
                            </div>
                        </div>

                        {loadingNotes && [1, 2, 3].map(item => {
                            return (
                                <div className="row mb-3" key={item}>
                                    <SkeletonCardNote />
                                </div>
                            )
                        })}
                        {!loadingNotes && search(allNotes)?.length < 1 ? (
                            <p className="text-center text-muted my-5">Tidak ada catatan</p>
                        ) : null}
                        {search(allNotes)?.map((note, index) => {
                            return (
                                <div className="row mb-3" key={index}>
                                    <CardNote
                                        index={index}
                                        courseTitle={note.course_title}
                                        lessonTitle={note.lesson_title}
                                        noteMinute={note.minute}
                                        noteTitle={note.title}
                                        noteDescription={note.description}
                                        courseSlug={note.course_slug}
                                        lessonId={note.lesson_id}
                                    />
                                </div>
                            );
                        }).slice(0, limit)}

                        {!loadingNotes && search(allNotes)?.length > limit && (
                            <div className="text-center my-3">
                                <button onClick={() => setLimit(limit + 10)} type="button" className="btn btn-outline-secondary btn-sm">Load more</button>
                            </div>
                        )}
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default MyNotes;
